import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import LaborRuleBaseConfirmationModal, {
  ConfirmationFormValues,
} from '../LaborRuleBaseConfirmationModal/LaborRuleBaseConfirmationModal';
import { RuleFormValues } from '../../models/LaborRuleConfiguration';

export interface SaveRulesModalProps {
  handleSave: (confirmData: ConfirmationFormValues) => Promise<void>;
  handleClose: () => void;
  loading: boolean;
  nextWorkWeekDate: string;
}

export default function SaveRulesModal(props: SaveRulesModalProps) {
  const { handleSave, handleClose, loading, nextWorkWeekDate } = props;
  const {
    formState: { errors, isSubmitting },
  } = useFormContext<RuleFormValues>();
  const { t } = useTranslation();

  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length > 0) {
      // An error occurred while trying to submit
      handleClose();
    }
  }, [errors, isSubmitting, handleClose]);

  return (
    <LaborRuleBaseConfirmationModal
      dataTestId='save-rules-confirmation-modal'
      title={t('laborRules.saveRuleModal.title')}
      ctaText={t('buttonText.save')}
      loading={loading}
      subHeaderText={t('laborRules.saveRuleModal.subHeaderText')}
      handleClose={handleClose}
      ctaAction={handleSave}
      dateEffectiveDateDefault={nextWorkWeekDate}
    />
  );
}
