import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import uniqueBy from 'lodash/uniqBy';
import { TFunction } from 'react-i18next';

import { ActualBreakResponseDto } from '@cbo/shared-library/response/scheduling.response';
import { JobStatus, Scheduling as SchedulingNamespace } from '@cbo/shared-library/types';
import { blue } from '@mui/material/colors';

import { fromPairs, range } from 'lodash';
import { formatCurrency, formatTime } from '../../utils';
import {
  ActualShiftResponseDto,
  AddEditShiftType,
  BreakPeriod,
  BreakType,
  PlannedShiftResponseDto,
  ScheduleChangeResponse,
} from '../Scheduling/types';
import { Employee, EmploymentHistory, EmploymentStatus, Job, JobProfile, SelfJobProfile, LaborWarning } from '../types';
import DateUtilities from './dateUtilities';
import LaborUtilities from './laborUtilities';
import SchedulingA11yUtilities from './schedulingA11yUtilities';

dayjs.extend(isBetweenPlugin);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration);

export const daysData = [
  { key: 0, label: 'Su', day: 'SUNDAY' },
  { key: 1, label: 'M', day: 'MONDAY' },
  { key: 2, label: 'Tu', day: 'TUESDAY' },
  { key: 3, label: 'W', day: 'WEDNESDAY' },
  { key: 4, label: 'Th', day: 'THURSDAY' },
  { key: 5, label: 'F', day: 'FRIDAY' },
  { key: 6, label: 'Sa', day: 'SATURDAY' },
];

function isValidISOString(date: Date) {
  try {
    return !!date.toISOString();
  } catch (error) {
    return false;
  }
}

const formatHours = (hours: number | undefined): string => {
  const floatNumber = hours?.toFixed(2);
  return `${floatNumber} hr`;
};

const calculateDateRangeValues = (currentDate: Dayjs, startDay: Dayjs, endDay: Dayjs) => {
  const isDayWithinWeek = currentDate.isBetween(startDay, endDay, null, '[]');
  const isFirstDay = currentDate.isSame(startDay, 'day');
  const isLastDay = currentDate.isSame(endDay, 'day');

  return { isDayWithinWeek, isFirstDay, isLastDay };
};

const calculateBreakEndTime = (breakStart: string | null, breakPeriod: string | null): string => {
  if (!breakStart || !breakPeriod) return '';

  const breakStartDayJS = dayjs(breakStart, 'HH:mm');
  const breakEndDayJS = breakStartDayJS.add(Number(breakPeriod), 'minutes');

  return breakEndDayJS.format('HH:mm');
};

const clockInTimeInBusinessHours = (
  clockinTime: string | undefined,
  clockoutTime: string | undefined,
  dayStartTime: string
): boolean => {
  let startDateTime = dayjs(clockinTime, 'HH:mm');
  const businessStartTime = dayjs(dayStartTime, 'HH:mm');
  const businessEndTime = businessStartTime.add(24, 'hour');
  let endDateTime = dayjs(clockoutTime, 'HH:mm');

  if (startDateTime.isBefore(businessStartTime)) {
    startDateTime = startDateTime.add(1, 'day');
  }

  if (endDateTime.isSameOrBefore(businessStartTime)) {
    endDateTime = endDateTime.add(1, 'day');
  }

  if (
    !(
      startDateTime.isSameOrAfter(businessStartTime) &&
      startDateTime.isSameOrBefore(businessEndTime) &&
      endDateTime.isSameOrAfter(businessStartTime) &&
      endDateTime.isSameOrBefore(businessEndTime)
    ) ||
    endDateTime.isBefore(startDateTime)
  ) {
    return false;
  }
  return true;
};

const startTimeOccursBeforeEndTime = (
  startTime: string | undefined,
  endTime: string | null | undefined,
  dayStartTime: string,
  businessDay?: Date | undefined
): boolean => {
  let start = dayjs(startTime, 'HH:mm');
  const businessDayStartTime = dayjs(dayStartTime, 'HH:mm');
  let end;
  const endDateTime = dayjs(endTime, 'HH:mm');
  if (endDateTime.isBefore(start)) {
    // If endTime is before startTime, add one day to endTime
    end = endDateTime.add(1, 'day');
  } else end = endDateTime;
  // if end day is changed and endTime is before startTime then start and end time falls under next calender day
  // in this senario we need to validate the startTime and endTime such that endTime should not be before startTime.
  if (businessDay && startTime && start.isBefore(businessDayStartTime) && !end.isSame(endDateTime)) {
    start = dayjs(businessDay).add(1, 'day').add(start.hour(), 'hour').add(start.minute(), 'minute');
  }
  return end.isAfter(start);
};

const isTimeDuringShift = (
  time: string | null | undefined,
  shiftStart: string | undefined,
  shiftEnd: string | undefined,
  considerBoundaries = false
): boolean => {
  // return true, so error isn't thrown in form
  if (!time || !shiftStart || !shiftEnd) return true;

  const timeDayJS = dayjs(time, 'HH:mm');
  const shiftStartDayJS = dayjs(shiftStart, 'HH:mm');
  const shiftEndDayJS = dayjs(shiftEnd, 'HH:mm');

  let result = timeDayJS.isBetween(shiftStartDayJS, shiftEndDayJS);
  if (considerBoundaries) {
    result = result || timeDayJS.isSame(shiftStartDayJS) || timeDayJS.isSame(shiftEndDayJS);
  }

  return result;
};

const isTimeOutsideShiftRanges = (shiftArray: AddEditShiftType[], time: string | null | undefined) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const shift of shiftArray) {
    const isAllDataAvailable = Boolean(shift.startTime && shift.endTime && time);
    const timesOverlapWithAnotherShift = isTimeDuringShift(time, shift.startTime, shift.endTime);

    if (isAllDataAvailable && timesOverlapWithAnotherShift) {
      // shift overlaps with another shift
      return false;
    }
  }
  return true;
};

const is15MinuteInterval = (time: string | undefined) => {
  const timeDayJs = dayjs(time, 'HH:mm');
  const minutes = timeDayJs.minute();

  // if divisible by 15 return true, else return false
  return minutes % 15 === 0;
};

const convertTime12to24 = (time12h: string) => dayjs(time12h, 'hh:mm A').format('HH:mm');

const formatYearOfService = (startDate: Date | null) => {
  if (startDate) {
    const serviceInYear = dayjs().diff(startDate, 'year');
    const serviceInMonth = dayjs().diff(startDate, 'month') % 12;

    let yearString = '';
    switch (serviceInYear) {
      case 0:
        yearString = '';
        break;
      case 1:
        yearString = '1 year';
        break;
      default:
        yearString = `${serviceInYear} years`;
        break;
    }

    let monthString = '';
    switch (serviceInMonth) {
      case 0:
        if (serviceInYear === 0) {
          monthString = '0 months';
        }
        break;
      case 1:
        monthString = '1 month';
        break;
      default:
        monthString = `${serviceInMonth} months`;
        break;
    }
    return dayjs(startDate).isAfter(dayjs()) ? '0 years' : `${yearString} ${monthString}`.trim();
  }
  return '-';
};

const jobFormatter = (job: Job[] | undefined) =>
  job
    ?.map((job_: Job) => job_.name as string)
    .sort((a, b) => a.localeCompare(b))
    .map((jobName: string | undefined, index: number) => {
      if (job.length - 1 === index) return jobName;
      return `${jobName}, `;
    });

const jobProfilePayRateFormatter = (jobProfiles: JobProfile[] | undefined, jobs: Job[]) =>
  // Create an object with payRate and jobCodeName, gathered by first getting the jobs and pay rates from the jobProfiles
  jobProfiles
    ?.map((jobProfile) => {
      const job = jobs.find((job_) => job_.jobId === jobProfile.jobId);
      return { payRate: jobProfile.payRate, jobCodeName: job?.name };
    })
    .filter((payRate) => payRate.payRate !== null)
    .sort((a, b) => a.jobCodeName?.localeCompare(b.jobCodeName ?? '') ?? 0)
    .map((payRate, index) => {
      if (payRate.payRate) {
        if (jobProfiles.length - 1 === index) {
          return `${formatCurrency(Number(payRate.payRate))}`;
        }
        return `${formatCurrency(Number(payRate.payRate))}, `;
      }
      return '-';
    });

/**
 * Global counter to navigate vertically on Shifts table, incrementing/decrementing as needed, adjusting the selector very simply.
 * Note: Not used as row identifier for moving focus between tables, though,
 * as I'm not sure we can guarantee the consistency betweeen renders, so continuing to use resourceId for that.
 */
let addShiftRowIndex = 0;

const insertHoverElementIntoCalendarCell = (
  el: HTMLElement,
  calendarWeekStartDate: Dayjs,
  text: string,
  btnDisabled: boolean,
  clickCallback: (element: HTMLElement) => void
) => {
  if (!el.dataset.resourceId) {
    // Element forwarded without resourceId! Returning out of function...'
    return;
  }
  // the function was sometimes called twice by the FullCalendar lib,
  // so duplicate elements were generated, one above the other.
  // I make sure they are not generated twice.
  if (el.classList.contains('insertHoverElementIntoCalendarCell')) {
    // Duplicated element registered! Returning out of function...
    return;
  }
  el.classList.add('insertHoverElementIntoCalendarCell');

  // This is a temporary hack to insert divs into FullCalendar.
  // Follow https://github.com/fullcalendar/fullcalendar/issues/4816 and update when fix is implemented
  const cellHoverElementsContainer = document.createElement('div');
  cellHoverElementsContainer.className = 'cell-hover-elements-container';

  const lengthRangeDate = 7;
  const todayIndex = dayjs().diff(calendarWeekStartDate, 'days');
  for (let i = 0; i < lengthRangeDate; i += 1) {
    const isPastDate = i < todayIndex;
    const addShiftButton = document.createElement('button');
    addShiftButton.innerHTML = isPastDate ? '' : text;

    addShiftButton.classList.add(`calendar-custom-add-shift`);
    addShiftButton.classList.add(`calendar-custom-add-shift-${i}`);

    addShiftButton.setAttribute('aria-describedby', `employee-cell-${el.dataset.resourceId}`);
    addShiftButton.setAttribute('aria-disabled', `${btnDisabled || isPastDate}`);
    addShiftButton.dataset.index = i.toString();
    addShiftButton.dataset.rowIndex = addShiftRowIndex.toString();
    addShiftButton.dataset.resourceId = el.dataset.resourceId;
    addShiftButton.dataset.testid = `add-shift-cell_row-${addShiftRowIndex}_col-${i}_resId-${el.dataset.resourceId}`;

    SchedulingA11yUtilities.setAddShiftListeners(addShiftButton, clickCallback);

    if (isPastDate) {
      addShiftButton.addEventListener('mouseover', () => {
        addShiftButton.style.cursor = 'auto';
      });
    }

    cellHoverElementsContainer.appendChild(addShiftButton);

    if (i === lengthRangeDate - 1) {
      addShiftRowIndex += 1;
    }
  }

  el.querySelector('.fc-timeline-lane-frame')?.appendChild(cellHoverElementsContainer);
};

const dateOccursInFuture = (date: Date | undefined) => {
  const today = dayjs();
  const dayIsInFuture = today.isBefore(date, 'day');
  const dayIsToday = today.isSame(date, 'day');

  return dayIsInFuture || dayIsToday;
};

const calculateLeftForTimelineBgHarnessElement = (
  selectedDate: Date | undefined,
  weekStartDayOffset: number | undefined
) => {
  const diff = dayjs(selectedDate).day() - (weekStartDayOffset ?? 3);
  const isDiffNegative = Math.sign(diff) === -1;
  const position = isDiffNegative ? 7 + diff : diff;
  return `calc(100% / 7 * ${position})`;
};

const insertTimelineBgHarnessElement = (selectedDate: Date | undefined, weekStartDayOffset: number | undefined) => {
  const timelineBgs = document.querySelectorAll('.fc-timeline-bg');
  const leftValue = calculateLeftForTimelineBgHarnessElement(selectedDate, weekStartDayOffset);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < timelineBgs.length; i++) {
    const timelineBg = timelineBgs[i];
    const timelineBgHarness = timelineBg.querySelector('.fc-timeline-bg-harness');
    // Fullcalendar creates timelineBgHarness when an event is selected
    if (timelineBgHarness) {
      const harnessEl = document.createElement('div');
      harnessEl.className = 'fc-timeline-bg-harness';
      harnessEl.style.cssText = `left:${leftValue};width:calc(100% / 7)`;

      const highlightEl = document.createElement('div');
      highlightEl.className = 'fc-highlight';
      highlightEl.style.cssText = `background:${blue[700]}14;`;

      harnessEl.appendChild(highlightEl);
      timelineBg?.appendChild(harnessEl);
    }
  }
};

const removeTimelineBgHarnessElement = () => {
  const timelineBgHarness = document.querySelector('.fc-timeline-bg-harness');
  timelineBgHarness?.remove();
};

const configureApplyToStartDay = (startDay: number) => {
  const sortedDaysData = [...daysData.slice(startDay), ...daysData.slice(0, startDay)];
  return sortedDaysData;
};

// Filter employees by jobs
// This function will take in : A list of employees, a list of job names, a list of job profiles, and a list of jobs
// It will then filter the employees by the job names and return the filtered employees
const filterEmployeesByJobs = (employees: Employee[], jobNames: string[], jobProfiles: JobProfile[], jobs: Job[]) => {
  // First, for each job profile, filter out the job profiles that do not have an employeeId that matches an employeeId in the employees list
  const matchedJobProfiles = jobProfiles
    .filter((jobProfile) => employees.some((employee) => employee.employeeId === jobProfile.employeeId))
    // Also, filter out the job profiles that do not have a jobId that matches a jobId in the jobs list
    .filter((jobProfile) => jobs.some((job) => job.jobId === jobProfile.jobId))
    // Now, filter out the job profiles that do not have a job name that matches a job name in the jobNames list
    .filter((jobProfile) =>
      jobNames.length > 0
        ? jobNames.includes(jobs.find((job) => job.jobId === jobProfile.jobId)?.name ?? '')
        : jobProfile
    );

  // Finally, return the employees that have an employeeId that matches an employeeId in the matchedJobProfiles list
  return employees.filter((employee) =>
    matchedJobProfiles.some((jobProfile) => jobProfile.employeeId === employee.employeeId)
  );
};

const isShiftInChangelog = (
  shiftId: string,
  changelog: ScheduleChangeResponse[] | ScheduleChangeResponse[] | undefined
): boolean => changelog?.some((changedShift) => changedShift.shiftId === shiftId) ?? false;

const calculateLaborWarning = (jobStatus: JobStatus | null | undefined, totalHours: number): LaborWarning | null => {
  // TODO: removed/update once we have access to LRE
  switch (jobStatus) {
    case JobStatus.FULL_TIME:
      if (totalHours >= 30 && totalHours < 40) return LaborWarning.OVERTIME_WARNING;
      if (totalHours >= 40) return LaborWarning.OVERTIME_CRITICAL;
      break;
    case JobStatus.PART_TIME:
      if (totalHours >= 30) return LaborWarning.PART_TIME_CRITICAL;
      break;
    default:
      return null;
  }
  return null;
};

const formatJobType = (jobStatus: JobStatus | null, t: TFunction) => {
  switch (jobStatus) {
    case JobStatus.FULL_TIME:
      return t('labor.fullTime');
    case JobStatus.PART_TIME:
      return t('labor.partTime');
    case JobStatus.HOURLY:
      return t('labor.hourly');
    case JobStatus.SALARY_EXEMPT:
      return t('labor.salaryExempt');
    case JobStatus.SALARY_NON_EXEMPT:
      return t('labor.salaryNonExempt');
    default:
      return '-';
  }
};

const isTimeRangeOverlapping = (start1: Dayjs, end1: Dayjs, start2: Dayjs, end2: Dayjs) => {
  const startIsBeforeExistingEnd = start1.isBefore(end2);
  const endIsAfterExistingStart = start2.isBefore(end1);
  return startIsBeforeExistingEnd && endIsAfterExistingStart;
};

export const findConflictingShifts = (
  shiftArray: PlannedShiftResponseDto[],
  start: string | undefined,
  end: string | undefined,
  businessDay: string,
  shiftIdToSkip?: string
) =>
  shiftArray.filter((existingShift) => {
    if (shiftIdToSkip && shiftIdToSkip === existingShift.id) return false;
    const isShiftOnAnotherDay = existingShift.businessDay !== dayjs(businessDay).format('YYYY-MM-DD');
    if (isShiftOnAnotherDay || !start || !end) return false;
    const startTime = dayjs(start, 'HH:mm');
    const endTime = dayjs(end, 'HH:mm');
    const existingStartTime = dayjs(
      convertTime12to24(formatTime(DateUtilities.removeTimezone(existingShift.punchInTime))),
      'HH:mm'
    );
    const existingEndTime = dayjs(
      convertTime12to24(formatTime(DateUtilities.removeTimezone(existingShift.punchOutTime))),
      'HH:mm'
    );

    return isTimeRangeOverlapping(startTime, endTime, existingStartTime, existingEndTime);
  });

const findConflictingShiftsNew = (
  shiftArray: PlannedShiftResponseDto[],
  start: string | undefined,
  end: string | undefined,
  businessDay: string,
  shiftIdToSkip?: string
) =>
  shiftArray.filter((existingShift) => {
    if (shiftIdToSkip && shiftIdToSkip === existingShift.id) return false;
    const isShiftOnAnotherDay = existingShift.businessDay !== dayjs(businessDay).format('YYYY-MM-DD');
    if (isShiftOnAnotherDay || !start || !end) return false;
    const startTime = dayjs(start, 'HH:mm');
    const endTime = dayjs(end, 'HH:mm');
    const existingStartTime = dayjs(
      convertTime12to24(formatTime(DateUtilities.removeTimezone(existingShift.punchInTime))),
      'HH:mm'
    );
    const existingEndTime = dayjs(
      convertTime12to24(formatTime(DateUtilities.removeTimezone(existingShift.punchOutTime))),
      'HH:mm'
    );

    return isTimeRangeOverlapping(startTime, endTime, existingStartTime, existingEndTime);
  });

const findConflictingShiftsWithMultipleShifts = (
  existingShifts: PlannedShiftResponseDto[],
  shiftsInForm: AddEditShiftType[],
  businessDay: string
) => {
  const result: PlannedShiftResponseDto[] = [];
  shiftsInForm.forEach((shift) => {
    const conflictingShifts = findConflictingShiftsNew(existingShifts, shift.startTime, shift.endTime, businessDay);
    result.push(...conflictingShifts);
  });
  return uniqueBy(result, 'id');
};

const formatPunchEdit = (punchEditReason: SchedulingNamespace.PunchEditReasons | undefined, t: TFunction) => {
  switch (punchEditReason) {
    case SchedulingNamespace.PunchEditReasons.INCORRECT_BREAK_IN_OUT:
      return t('labor.incorrectBreakOutIn');
    case SchedulingNamespace.PunchEditReasons.FORGOT_TO_CLOCK_IN:
      return t('labor.forgotToClockInOut');
    case SchedulingNamespace.PunchEditReasons.INCORRECT_CLOCK_IN_OUT:
      return t('labor.incorrectClockInOut');
    case SchedulingNamespace.PunchEditReasons.INCORRECT_JOBCODE:
      return t('labor.incorrectJobCode');
    case SchedulingNamespace.PunchEditReasons.OTHER:
      return t('labor.other');
    case null:
    default:
      return '';
  }
};

const formatJob = (jobs: Job[] | undefined, jobId: string | undefined) => {
  const job = jobs?.find((currentJob) => currentJob.jobId === jobId);
  return job?.name;
};

const formatJobProfile = (jobProfiles: SelfJobProfile[] | undefined, jobId: string | undefined) => {
  const job = jobProfiles?.find((currentJob) => currentJob.jobId === jobId);
  return job?.jobName;
};

const calculateBreaksTotalHours = (breaks: ActualBreakResponseDto[]): string => {
  const breakTotalMilliseconds = breaks
    .map((actualBreak): number =>
      dayjs(actualBreak.breakInformation?.breakInTime).diff(dayjs(actualBreak.breakInformation?.breakOutTime))
    )
    .reduce((acc, breakAmount) => acc + breakAmount, 0);
  return dayjs.duration({ milliseconds: breakTotalMilliseconds }).asHours().toFixed(2);
};

const formatEmployeeFromId = (employees: Employee[] | undefined, employeeId: string | undefined) => {
  const employee =
    employeeId && employees
      ? employees.find((currentEmployee) => currentEmployee.employeeId === employeeId)
      : undefined;
  return employee?.contact
    ? LaborUtilities.constructFullName(employee.contact.firstName, employee.contact.lastName)
    : '-';
};

const formatCompensationType = (compensationType: SchedulingNamespace.CompensationType | undefined, t: TFunction) => {
  switch (compensationType) {
    case SchedulingNamespace.CompensationType.PAID:
      return t('labor.paid');
    case SchedulingNamespace.CompensationType.UNPAID:
      return t('labor.unpaid');
    default:
      return undefined;
  }
};

const calculateRegularHours = (actualShift: ActualShiftResponseDto): string => {
  const regularSecondsWorked =
    actualShift.actualShiftWageBreakdowns?.[0]?.wageBreakdowns
      ?.filter((wageBreakdown) => wageBreakdown.rateAdjustment === 1)
      ?.reduce((acc, segment) => acc + (segment?.duration ?? 0), 0) ?? 0;

  return dayjs.duration({ seconds: regularSecondsWorked }).asHours().toFixed(2);
};

const calculateOvertimeHours = (actualShift: ActualShiftResponseDto): string => {
  const overtimeSecondsWorked =
    actualShift.actualShiftWageBreakdowns?.[0]?.wageBreakdowns
      ?.map((wageBreakdown) =>
        // rateAdjustment > 1 is not a regular pay rate, so we need to add these up
        wageBreakdown.rateAdjustment > 1 ? wageBreakdown.duration : 0
      )
      ?.reduce((acc, overtimeDuration) => acc + overtimeDuration, 0) ?? 0;

  return dayjs.duration({ seconds: overtimeSecondsWorked }).asHours().toFixed(2);
};

const hasBreaksOutsideShiftOrPunch = (
  startTime: string,
  endTime: string,
  breaks: BreakType[] | BreakPeriod[],
  key: 'punch' | 'shift'
): boolean =>
  breaks.some((breakPeriod) => {
    const { start, end } =
      key === 'punch'
        ? { start: (breakPeriod as BreakPeriod).breakOut, end: (breakPeriod as BreakPeriod).breakIn }
        : { start: (breakPeriod as BreakType).breakStart, end: (breakPeriod as BreakType).breakEnd };

    const isStartValid = isTimeDuringShift(start, startTime, endTime);
    const isEndValid = end ? isTimeDuringShift(end, startTime, endTime) : true;

    return !(isStartValid && isEndValid);
  });

/**
 * Returns an object containing two properties:
 * - isCurrentBreakOverlapping: true if an index parameter is passed
 *    and the break at that index has an overlap; defaults to false if index is not provided.
 * - areAnyBreaksOverlapping: true if there are any overlapping breaks at all.
 */
const checkCurrentAndOverallOverlapsInBreaks = (
  breaks: BreakPeriod[] | BreakType[],
  key: 'punch' | 'shift',
  currentBreakIndex?: number
) => {
  const extractBreakStartAndEnd = (breakObj: BreakPeriod | BreakType) => {
    const { start, end } =
      key === 'punch'
        ? { start: (breakObj as BreakPeriod).breakOut, end: (breakObj as BreakPeriod).breakIn }
        : { start: (breakObj as BreakType).breakStart, end: (breakObj as BreakType).breakEnd };

    return { start, end };
  };
  const breakOverlapInfo = fromPairs(range(0, breaks?.length).map((num) => [num, false]));

  if (!breaks?.length) return { isCurrentBreakOverlapping: false, areAnyBreaksOverlapping: false };

  let areAnyBreaksOverlapping = false;

  for (let i = 0; i < breaks.length; i += 1) {
    if (!breakOverlapInfo[i]) {
      const { start: startStr, end: endStr } = extractBreakStartAndEnd(breaks[i]);
      const currentBreakStart = dayjs(startStr, 'HH:mm');
      const currentBreakEnd = dayjs(endStr, 'HH:mm');
      for (let j = i + 1; j < breaks.length; j += 1) {
        const { start: otherBreakStartStr, end: otherBreakEndStr } = extractBreakStartAndEnd(breaks[j]);
        const otherBreakStart = dayjs(otherBreakStartStr, 'HH:mm');
        const otherBreakEnd = dayjs(otherBreakEndStr, 'HH:mm');

        const isOverlapping = isTimeRangeOverlapping(
          currentBreakStart,
          currentBreakEnd,
          otherBreakStart,
          otherBreakEnd
        );
        if (isOverlapping) {
          if (currentBreakIndex !== undefined && (i === currentBreakIndex || j === currentBreakIndex)) {
            return { isCurrentBreakOverlapping: true, areAnyBreaksOverlapping: true };
          }
          areAnyBreaksOverlapping = true;
          breakOverlapInfo[i] = true;
          breakOverlapInfo[j] = true;
        }
      }
    }
  }
  const isCurrentBreakOverlapping = currentBreakIndex !== undefined ? breakOverlapInfo[currentBreakIndex] : false;
  return { isCurrentBreakOverlapping, areAnyBreaksOverlapping };
};

const getJobProfilesForBusinessDay = (
  jobProfiles: JobProfile[] | undefined,
  currentBusinessDay: Dayjs,
  isEditing: boolean,
  allowInactive?: boolean
) => {
  const jobProfilesForBusinessDay: JobProfile[] = [];
  jobProfiles?.forEach((jobProfile) => {
    const dayjsStartDate = dayjs(jobProfile.startDate).utc().hour(0).minute(0).second(0);
    const dayjsEndDate = dayjs(jobProfile.endDate).utc().endOf('day');
    const today = dayjs();
    const fourWeeksAgo = today.subtract(28, 'days');
    const inactiveJob: boolean = allowInactive
      ? currentBusinessDay.isSameOrAfter(fourWeeksAgo, 'd') && dayjsEndDate.isSameOrAfter(currentBusinessDay, 'd')
      : dayjsEndDate.isSameOrAfter(currentBusinessDay, 'd');
    const isInActiveJobProfileDateRange = jobProfile.endDate
      ? dayjsStartDate.isSameOrBefore(currentBusinessDay, 'd') && inactiveJob
      : dayjsStartDate.isSameOrBefore(currentBusinessDay, 'd');

    const isInHistoryDateRange = jobProfile.history
      ? jobProfile.history.some((history) => {
          const dayjsHistoryStartDate = dayjs(history.startDate).utc().hour(0).minute(0).second(0);
          const dayjsHistoryEndDate = dayjs(history.endDate).utc().endOf('day');

          return (
            dayjsHistoryStartDate.isSameOrBefore(currentBusinessDay, 'd') &&
            (isEditing
              ? dayjsHistoryEndDate.isSameOrAfter(currentBusinessDay, 'd')
              : dayjsHistoryEndDate.isAfter(currentBusinessDay, 'd'))
          );
        })
      : false;

    if (isInActiveJobProfileDateRange || isInHistoryDateRange) {
      jobProfilesForBusinessDay.push(jobProfile);
    }
  });

  return jobProfilesForBusinessDay;
};

/**
 *
 * @param isLoading Whether or not any query data is loading
 * @param currentBusinessDay The current businessDay you want to find jobs for
 * @param jobProfilesWithHistory The job profiles needed to match employees and jobs
 * @param activeJobs The jobs an employee might have
 * @param employeeWithEmployeeHistory The employee with their employment history
 * @param isEditing Whether or not the user is editing a shift/punch
 * @returns The jobs an employee has on a given date, provided they are both hired and the job profiles associated have a start/end date range around the given date
 */
const getEmployeeJobs = (
  isLoading: boolean,
  currentBusinessDay: Dayjs | undefined,
  jobProfilesWithHistory: JobProfile[] | undefined,
  activeJobs: Job[] | undefined,
  employeeWithEmployeeHistory: Employee | undefined,
  isEditing: boolean,
  allowInactive?: boolean
) => {
  if (isLoading || !currentBusinessDay) {
    return [];
  }

  const jobProfiles = jobProfilesWithHistory?.filter(
    (jobProfile) => jobProfile.employeeId === employeeWithEmployeeHistory?.employeeId
  );

  // Gets all job profiles that have a start/end date around the current businessDay, using both currently active job profiles and their history as date ranges
  const jobProfilesForBusinessDay: JobProfile[] = getJobProfilesForBusinessDay(
    jobProfiles,
    currentBusinessDay,
    isEditing,
    allowInactive
  );

  const employmentHistoriesWithDifferentEffectiveDates = employeeWithEmployeeHistory?.employmentHistory.reduce(
    (acc, current) => {
      const currentEffectiveDate = dayjs.utc(current.effectiveDate);
      const isDuplicate = acc.result.some((item) => dayjs.utc(item.effectiveDate).isSame(currentEffectiveDate, 'd'));

      if (!isDuplicate) {
        acc.result.push(current);
      }

      return acc;
    },
    { result: [] as EmploymentHistory[] }
  ).result;

  // For each history record, create a date range from their effectiveDate to the next history record
  const historyMap: { startDate: Dayjs; endDate: Dayjs | null; history: EmploymentHistory }[] = [];
  employmentHistoriesWithDifferentEffectiveDates?.forEach((history, index) => {
    if (index === 0) {
      historyMap.push({
        startDate: dayjs.utc(history.effectiveDate).utc().hour(0).minute(0).second(0),
        endDate: null,
        history,
      });
    } else {
      historyMap.push({
        startDate: dayjs.utc(history.effectiveDate).utc().hour(0).minute(0).second(0),
        endDate: dayjs
          .utc(employmentHistoriesWithDifferentEffectiveDates[index - 1].effectiveDate)
          .subtract(1, 'd')
          .endOf('d'),
        history,
      });
    }
  });

  // If a date range of one of the remaining history records surrounds the business day and that status is HIRED, the employee is able to create shifts that day
  const isHiredOnBusinessDay = historyMap?.some(
    (mapping, index) =>
      mapping.history.employmentStatus === EmploymentStatus.HIRED &&
      ((mapping.startDate.isSameOrBefore(currentBusinessDay, 'd') &&
        mapping.endDate?.isSameOrAfter(currentBusinessDay, 'd')) ||
        (index === 0 && mapping.startDate.isSameOrBefore(currentBusinessDay, 'd')))
  );

  // If hired on business day, return job profiles that exist for that day. If not hired, no job profiles should be available.
  const employeeJobProfiles = isHiredOnBusinessDay ? jobProfilesForBusinessDay : [];

  const employeeJobs = employeeJobProfiles.map((jobProfile) =>
    activeJobs?.find((job) => job.jobId === jobProfile.jobId)
  );

  return employeeJobs;
};

const SchedulingUtilities = {
  formatHours,
  calculateDateRangeValues,
  calculateBreakEndTime,
  convertTime12to24,
  startTimeOccursBeforeEndTime,
  isTimeDuringShift,
  isTimeOutsideShiftRanges,
  findConflictingShifts,
  findConflictingShiftsNew,
  findConflictingShiftsWithMultipleShifts,
  is15MinuteInterval,
  formatYearOfService,
  jobFormatter,
  insertHoverElementIntoCalendarCell,
  filterEmployeesByJobs,
  dateOccursInFuture,
  calculateLeftForTimelineBgHarnessElement,
  insertTimelineBgHarnessElement,
  removeTimelineBgHarnessElement,
  configureApplyToStartDay,
  isShiftInChangelog,
  calculateLaborWarning,
  formatJobType,
  formatPunchEdit,
  calculateBreaksTotalHours,
  formatEmployeeFromId,
  formatCompensationType,
  calculateRegularHours,
  calculateOvertimeHours,
  isValidISOString,
  jobProfilePayRateFormatter,
  formatJob,
  hasBreaksOutsideShiftOrPunch,
  formatJobProfile,
  isTimeRangeOverlapping,
  checkCurrentAndOverallOverlapsInBreaks,
  getEmployeeJobs,
  getJobProfilesForBusinessDay,
  clockInTimeInBusinessHours,
};

export default SchedulingUtilities;
