import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid-premium';
import { FeatureFlag } from '@cbo/shared-library';
import { useFeatureFlag } from '../../utils/hooks/useFeatureFlag';
import colors from '../../lib/styles/colors';

const getDsStyles = (loading?: boolean) => ({
  backgroundColor: 'background.paper',
  '--DataGrid-overlayHeight': loading ? '519px' : '150px',
  width: '100%',
  border: 1,
  borderColor: colors.borderOnEmpty,
  '& .MuiDataGrid-main': {
    borderRadius: '8px',
  },
  '& .actionButton': {
    display: 'none',
  },
  '.MuiDataGrid-cell--pinnedRight': {
    backgroundColor: 'background.paper',
  },
  [`& .${gridClasses.row}:hover`]: {
    '.actionButton': {
      display: 'block',
    },
  },
  '& .MuiDataGrid-cell': {
    padding: '0px 16px',
    alignContent: 'center',
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: 'background.paper',
    padding: '0px 16px',
  },
  '& .MuiDataGrid-columnHeaderCheckbox': {
    padding: '0px 0px',
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: loading ? 'hidden' : 'auto',
  },
  '& .MuiDataGrid-cell--pinnedLeft .MuiDataGrid-cell--pinnedRight': {
    backgroundColor: 'background.paper',
    background: 'background.paper',
  },
  '& .MuiDataGrid-pinnedRows': {
    backgroundColor: 'background.paper',
    boxShadow: '-3px 0px 8px 0px rgba(51, 51, 51, 0.25)',
    width: 'var(--DataGrid-rowWidth)',
    fontWeight: 500,
  },
  '& .MuiDataGrid-pinnedRows > .MuiDataGrid-row--lastVisible': {
    backgroundColor: 'background.paper',
  },
  // This next sx change is to remove the last column vertical line separator
  '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-toolbarContainer': {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
});

const getUiKitStyles = (theme: Theme, loading?: boolean) => ({
  width: '100%',
  border: 1,
  borderColor: colors.surfaceBorder[theme.palette.mode],
  borderRadius: '12px',
  '--DataGrid-rowBorderColor': colors.surfaceBorder[theme.palette.mode],
  '--DataGrid-overlayHeight': loading ? '519px' : '150px',
  '& .MuiDataGrid-root': {
    borderRadius: '12px',
  },
  '& .MuiDataGrid-toolbarContainer': {
    padding: '6px',
    height: '52px',
    borderBottom: `1px solid ${colors.surfaceBorder[theme.palette.mode]}`,
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: colors.surface[theme.palette.mode],
  },
  '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '.MuiDataGrid-cell--pinnedRight': {
    borderLeft: `1px solid ${colors.surfaceBorder[theme.palette.mode]}`,
  },
  '& .MuiDataGrid-cell': {
    padding: '0px 10px',
    alignContent: 'center',
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'auto',
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${colors.surfaceBorder[theme.palette.mode]}`,
  },
  '& .MuiTablePagination-select': {
    marginTop: '6px',
  },
  '& .MuiTablePagination-selectIcon': {
    marginTop: '2px',
  },
});

export const DatagridStyles = (loading?: boolean, additionalSx?: SxProps<Theme>) => {
  const uiKit = useFeatureFlag(FeatureFlag.restaurantsUiKit);
  const theme = useTheme();

  const uiKitStyles = getUiKitStyles(theme, loading);
  const dsStyles = getDsStyles(loading);

  return uiKit ? { ...uiKitStyles, ...additionalSx } : { ...dsStyles, ...additionalSx };
};

export const quickFilter = (theme: Theme): SxProps => ({
  '&.MuiDataGrid-toolbarQuickFilter': { paddingBottom: '0px' },
  '& .MuiOutlinedInput-root': {
    margin: '0px',
    height: '32px',
    width: '220px',
    backgroundColor: theme.palette.grey[100],
    paddingLeft: '6px',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.action.active,
  },
});
