import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpOutlined from '@mui/icons-material/ArrowDropUpOutlined';
import StoreMallDirectory from '@mui/icons-material/StoreMallDirectory';
import { SiteInfo } from '@cbo/shared-library';
import { getSiteButtonText } from '../../../admin/utils/siteUtils';
import { selectedButtonStyling, arrowStyling } from '../GlobalFilterBarStyles';
import ButtonResetIcon from '../ButtonResetIcon';

interface SiteFilterButtonProps {
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  anchorEl: HTMLButtonElement | null;
  selected: SiteInfo[];
  optionsLength: number;
  currentSite: SiteInfo[] | undefined;
  resetEnabled: boolean;
  buttonSize: 'small' | 'medium' | 'large' | undefined;
  handleReset: () => void;
}

function SiteFilterButton({
  setAnchorEl,
  anchorEl,
  selected,
  optionsLength,
  currentSite,
  resetEnabled,
  buttonSize,
  handleReset,
}: SiteFilterButtonProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const sitesText = t('globalFilters.sites');

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const buttonText = getSiteButtonText(
    currentSite && currentSite[0] && currentSite[0].name ? currentSite[0].name : '',
    selected,
    optionsLength,
    false,
    false,
    t('globalFilters.sites'),
    t('globalFilters.allSites')
  );

  const open = Boolean(anchorEl);

  const handleButtonSx = () => selectedButtonStyling(anchorEl, theme);

  const arrowIcon = open ? (
    <ArrowDropUpOutlined sx={arrowStyling(theme, true)} fontSize='inherit' />
  ) : (
    <ArrowDropDown sx={arrowStyling(theme, true)} fontSize='inherit' />
  );

  return (
    <Button
      role='button'
      aria-haspopup='true'
      onClick={handleButtonClick}
      sx={handleButtonSx}
      size={buttonSize}
      data-testid='sitefilter-button'
      aria-label={`${sitesText}, ${buttonText}, selected`}
      startIcon={<StoreMallDirectory color='primary' sx={{ marginLeft: '6px', marginRight: '-4px' }} />}
    >
      <Typography
        sx={{ marginRight: '8px', marginLeft: '12px', fontWeight: 500, lineHeight: '22px' }}
        fontSize='inherit'
      >
        {buttonText}
      </Typography>
      {selected.length > 1 || resetEnabled ? <ButtonResetIcon handleReset={handleReset} /> : arrowIcon}
    </Button>
  );
}

export default SiteFilterButton;
