import i18next from 'i18next';
import { CboRole, FeatureFlag, Package } from '@cbo/shared-library';
import { useFeatureFlags } from '../../../utils/hooks/useFeatureFlag';
import isPermitted from '../../../lib/permissions';
import { useUsers } from '../../../contexts/userContext';
import routes from '../../../constants/routes';
import { NavItem } from '.';
import { usePackages } from '../../../contexts/packageContext';

export default function useSettingsRoutes(): NavItem[] {
  const [
    cboLaborRules,
    cboGeneralLedgerAccounts,
    cboConfigurableNetAndGrossSales,
    cboWorkWeekConfig,
    cboHideAlohaEssentialOrgs,
  ] = useFeatureFlags(
    FeatureFlag.laborRules,
    FeatureFlag.generalLedgerAccounts,
    FeatureFlag.configurableNetGrossSales,
    FeatureFlag.workWeekConfig,
    FeatureFlag.hideAlohaEssentialOrgs
  );
  const user = useUsers();
  const packages = usePackages();
  const hasStarterPackage = packages[Package.STARTER];
  const hasCoreAnalytics = packages[Package.ADVANCED_ANALYTICS];
  const hasCoreLaborPayroll = packages[Package.LABOR_PAYROLL];

  const settingsRoutes: NavItem[] = [];
  const organizationSettingsChildren: NavItem[] = [];
  const siteSettingsChildren: NavItem[] = [];
  const laborSettingsChildren: NavItem[] = [];
  const salesSettingsChildren: NavItem[] = [];

  // organization settings routes

  if (isPermitted(user, [CboRole.SITE_VIEW, CboRole.SITE_EDIT])) {
    organizationSettingsChildren.push({
      title: i18next.t('navItems.sites'),
      href: routes.SITES,
      testid: 'sites-menu-item',
    });
  }
  if (isPermitted(user, [CboRole.FISCAL_CALENDAR_VIEW, CboRole.FISCAL_CALENDAR_EDIT])) {
    organizationSettingsChildren.push({
      title: i18next.t('navItems.fiscalCalendar'),
      href: routes.FISCAL_CALENDAR,
      testid: 'fiscal-calendar-menu-item',
    });
  }

  if (isPermitted(user, [CboRole.GL_ACCOUNTS_READ]) && cboGeneralLedgerAccounts && hasCoreAnalytics) {
    organizationSettingsChildren.push(
      {
        title: i18next.t('navItems.manageGeneralLedgerAccounts'),
        href: routes.MANAGE_GL_ACCOUNTS,
        testid: 'manage-gl-accounts',
      },
      {
        title: i18next.t('navItems.glAccountMapping'),
        href: routes.GL_ACCOUNT_MAPPING,
        testid: 'gl-account-mapping',
      }
    );
  }

  if (organizationSettingsChildren.length > 0) {
    settingsRoutes.push({
      title: i18next.t('navItems.organizationSettings'),
      testid: 'organization-settings-menu-item',
      children: organizationSettingsChildren,
    });
  }

  // site settings routes

  if (isPermitted(user, [CboRole.SITE_VIEW, CboRole.SITE_EDIT])) {
    siteSettingsChildren.push({
      title: i18next.t('navItems.siteSettings'),
      href: routes.SITE_SETTINGS,
      testid: 'site-settings-menu-item',
    });
  }

  if (isPermitted(user, [CboRole.PAYROLL_CALENDAR_VIEW, CboRole.PAYROLL_CALENDAR_EDIT]) && !cboHideAlohaEssentialOrgs) {
    siteSettingsChildren.push({
      title: i18next.t('navItems.payrollCalendar'),
      href: routes.PAYROLL_CALENDAR,
      testid: 'payroll-calendar-menu-item',
    });
  }

  if (isPermitted(user, [CboRole.SITE_DAY_PART_VIEW, CboRole.SITE_DAY_PART_EDIT]) && hasStarterPackage) {
    siteSettingsChildren.push({
      title: i18next.t('navItems.dayParts'),
      href: routes.DAY_PARTS,
      testid: 'dayparts-menu-item',
    });
  }

  if (siteSettingsChildren.length > 0) {
    settingsRoutes.push({
      title: i18next.t('navItems.siteSettings'),
      testid: 'site-settings-menu-item-title',
      children: siteSettingsChildren,
    });
  }
  // labor settings routes
  if (cboWorkWeekConfig) {
    // once roles are created those roles should be applied to laborSettingsSchedule
    laborSettingsChildren.push({
      title: i18next.t('navItems.laborSettingsSchedule'),
      href: routes.LABOR_SETTINGS_SCHEDULE,
      testid: 'labor-setting-schedule',
    });
  }

  if (isPermitted(user, [CboRole.LRE_RULE_WRITER, CboRole.LRE_RULE_EXEC]) && cboLaborRules && hasCoreLaborPayroll) {
    laborSettingsChildren.push({
      title: i18next.t('navItems.laborRules'),
      href: routes.LABOR_RULES,
      testid: 'labor-rules-menu-item',
    });
  }

  if (isPermitted(user, [CboRole.JOBCODE_CONFIG_VIEW])) {
    laborSettingsChildren.push({
      title: i18next.t('navItems.jobCodeOverview'),
      href: routes.JOBCODE_CONFIGURATION_PAGE,
      testid: 'jobcode-configuration-overview',
    });
  }

  if (!cboHideAlohaEssentialOrgs && laborSettingsChildren.length > 0) {
    settingsRoutes.push({
      title: i18next.t('navItems.laborSettings'),
      testid: 'labor-settings-menu-item',
      children: laborSettingsChildren,
    });
  }

  // sales settings routes
  if (
    cboConfigurableNetAndGrossSales &&
    hasCoreAnalytics &&
    isPermitted(user, [CboRole.SALES_CONFIG_ADMIN, CboRole.SALES_CONFIG_READER, CboRole.SALES_CONFIG_WRITER])
  ) {
    salesSettingsChildren.push({
      title: i18next.t('navItems.salesDefinitions'),
      href: routes.SALES_DEFINITIONS_SETTINGS,
      testid: 'sales-definitions-settings',
    });
  }

  if (salesSettingsChildren.length > 0) {
    settingsRoutes.push({
      title: i18next.t('navItems.salesSettings'),
      testid: 'sales-settings',
      children: salesSettingsChildren,
    });
  }

  return settingsRoutes;
}
