import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Ty from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';
import { Res } from '@cbo/shared-library';
import formatAgeIndicator from '../../../../utils/notificationsUtils/notificationsHelper';
import { useAdminRequests } from '../../../../admin/requests';
import { useSnackbar } from '../../../../contexts/SnackbarContext';

export interface NotificationCardProps {
  notification: Res.Notifications.Notification;
  renderDelete?: boolean;
  minHeightInPx?: string;
  handleRefetchNotifications: () => void;
}

function NotificationCard(props: NotificationCardProps) {
  const { dismissNotification, markNotificationAsRead, markNotificationAsUnread } = useAdminRequests();
  const { setSnackbarState } = useSnackbar();

  const { t } = useTranslation(['translation'], {
    useSuspense: false,
  });
  const { notification, renderDelete, minHeightInPx, handleRefetchNotifications } = props;
  const currentDateInMinutes = Date.now() / 1000 / 60;

  async function updateNotificationReadFlag(notificationToUpdate: Res.Notifications.Notification) {
    try {
      if (notificationToUpdate.read) {
        await markNotificationAsUnread(notificationToUpdate.id);
      } else {
        await markNotificationAsRead(notificationToUpdate.id);
      }
      handleRefetchNotifications();
    } catch (e) {
      setSnackbarState({
        open: true,
        color: 'error',
        message: t('admin.allNotificationsDialog.errorUpdatingNotification'),
      });
    }
  }

  async function markNotificationAsDismissed(notificationToUpdate: Res.Notifications.Notification) {
    try {
      await dismissNotification(notificationToUpdate.id);
      handleRefetchNotifications();
    } catch (e) {
      setSnackbarState({
        open: true,
        color: 'error',
        message: t('admin.allNotificationsDialog.errorDismissNotification'),
      });
    }
  }

  return (
    <Box
      data-testid='notification-card'
      sx={{
        display: 'flex',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
        paddingTop: 1,
        paddingBottom: 0.5,
        width: '100%',
        minHeight: minHeightInPx,
        bgcolor: notification.priority && !notification.read ? 'background.default' : 'initial',
      }}
    >
      {/* unread icon */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minWidth: '40px',
          marginTop: '4px',
        }}
      >
        {!notification.read && (
          <CircleIcon
            data-testid='unread-notification-icon'
            color='primary'
            sx={{
              fontSize: '12px',
            }}
          />
        )}
      </Box>

      {/* Text & body */}
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            marginRight: 2,
          }}
        >
          {/* Text & chip */}
          <Box>
            <Ty
              variant='body2'
              sx={{
                color: 'text.secondary',
                fontWeight: '700',
              }}
            >
              {notification.siteName}
            </Ty>
            <Ty variant='body2' data-testid='notification-message'>
              {notification.description}
            </Ty>
            {notification.priority && (
              <Chip
                data-testid='priority-badge'
                label={t('rightPanel.notifications.priority')}
                color={notification.read ? 'secondary' : 'primary'}
                size='small'
                sx={{ marginTop: 1.5 }}
              />
            )}
          </Box>

          {/* Footer */}
          <Box
            sx={{
              marginTop: 0.5,
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: renderDelete ? 'flex-start' : 'space-between',
            }}
          >
            <Ty
              data-testid='age-indicator'
              variant='caption'
              color='text.secondary'
              sx={{
                minWidth: '82px',
                marginRight: 2,
              }}
            >
              {formatAgeIndicator(t, currentDateInMinutes, notification.createdAt)}
            </Ty>
            {renderDelete && (
              <Button
                data-testid='delete-notification'
                size='small'
                color='error'
                onClick={() => markNotificationAsDismissed(notification)}
              >
                {t('buttonText.delete')}
              </Button>
            )}
            <Button
              data-testid='mark-read-toggle'
              size='small'
              color='primary'
              onClick={() => updateNotificationReadFlag(notification)}
            >
              {notification.read ? t('rightPanel.notifications.markUnread') : t('rightPanel.notifications.markRead')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

NotificationCard.defaultProps = {
  renderDelete: false,
  minHeightInPx: '128px',
};

export default NotificationCard;
