import { Req } from '@cbo/shared-library';
import { useErrorHandler } from 'react-error-boundary';
import { Buffer } from 'buffer';
import { SingleQueryParam } from '@cbo/shared-library/request/firebase.request';
import { useSites } from '../../../contexts/siteContext';
import useCallBslAndHandleErrors from '../../../lib/firebaseApiHandleErrors';
import { Paginated } from '../../../models/Paginated';
import {
  CreateWeeklySchedulePdfBodySchema,
  DeletePunchEditRequest,
  PublishScheduleRequest,
  PunchEditRequest,
  SalesSummaryData,
  ScheduleCreateRequest,
  ScheduleUpdateRequest,
  WorkWeekConfig,
  ActualShiftResponseDto,
  PlannedShiftResponseDto,
  PlannedShiftReq,
  ScheduleResponse,
  CopyScheduleRequest,
  SalesSummaryDataBodySchema,
  WorkWeekConfigResponse,
  WorkWeekConfigBodySchema,
  SalesSummaryDataForWeekBodySchema,
} from '../types';

export type SchedulingRequests = {
  getPlannedShifts: (
    page: number,
    limit: number,
    startDate: string,
    endDate: string
  ) => Promise<Paginated<PlannedShiftResponseDto> | void>;
  createShifts: (shiftInfo: PlannedShiftReq[]) => Promise<PlannedShiftResponseDto[] | void>;
  getSchedules: (calendarWeekStart: string, calendarWeekEnd: string) => Promise<ScheduleResponse[] | void>;
  getSchedulesBySelf: (calendarWeekStart: string, calendarWeekEnd: string) => Promise<ScheduleResponse[] | void>;
  updateShift: (shiftId: string, shiftInfo: PlannedShiftReq) => Promise<PlannedShiftResponseDto | void>;
  deleteShift: (shiftId: string) => Promise<void>;
  publishSchedule: (scheduleId: string, payload: PublishScheduleRequest) => Promise<ScheduleResponse | void>;
  updateSchedule: (scheduleId: string, payload: ScheduleUpdateRequest) => Promise<ScheduleResponse | void>;
  createSchedule: (payload: ScheduleCreateRequest) => Promise<ScheduleResponse | void>;
  copySchedule: (payload: CopyScheduleRequest) => Promise<void>;
  getWorkWeekConfig: () => Promise<WorkWeekConfig | WorkWeekConfigResponse | void>;
  getActualShifts: (
    page: number,
    limit: number,
    startDate: string,
    endDate: string,
    employeeId?: string
  ) => Promise<Paginated<ActualShiftResponseDto> | void>;
  createPunchEdit: (payload: PunchEditRequest) => Promise<ActualShiftResponseDto | void>;
  updatePunchEdit: (shiftId: string, payload: PunchEditRequest) => Promise<ActualShiftResponseDto | void>;
  deletePunchEdit: (shiftId: string, payload: DeletePunchEditRequest) => Promise<void>;
  getSalesSummary: (scheduleId: string, payload?: SalesSummaryDataBodySchema) => Promise<SalesSummaryData[] | void>;
  getSalesSummaryForWeek: (payload: SalesSummaryDataForWeekBodySchema) => Promise<SalesSummaryData[] | void>;
  generateSchedulePdf: (scheduleId: string, body: CreateWeeklySchedulePdfBodySchema) => Promise<string | undefined>;
  generateSchedulePdfByDate: (
    startDate: string,
    endDate: string,
    body: CreateWeeklySchedulePdfBodySchema
  ) => Promise<string | undefined>;
  updateWorkWeekConfig: (payload: WorkWeekConfigBodySchema) => Promise<WorkWeekConfigResponse | void>;
};

const useSchedulingRequests = (): SchedulingRequests => {
  const handleError = useErrorHandler();
  const callBslAndHandleErrors = useCallBslAndHandleErrors();
  const { selectedSite } = useSites();

  const proxies = {
    schedules: 'schedules',
  };

  const Verb = Req.Firebase.HttpVerb;

  /**
   * SCHEDULING
   */

  const getPlannedShifts = async (
    page: number,
    limit: number,
    startDate: string,
    endDate: string
  ): Promise<Paginated<PlannedShiftResponseDto> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.schedules,
        pathSegments: ['planned-shifts'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
        queryParams: [
          { kind: 'single', key: 'page', value: page.toString() },
          { kind: 'single', key: 'limit', value: limit.toString() },
          { kind: 'single', key: 'startDate', value: startDate },
          { kind: 'single', key: 'endDate', value: endDate },
        ],
      },
      handleError
    );

  const createShifts = async (shiftInfo: PlannedShiftReq[]): Promise<PlannedShiftResponseDto[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.schedules,
        pathSegments: ['planned-shifts', 'bulk'],
        payload: { plannedShifts: shiftInfo },
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getSchedules = async (calendarWeekStart: string, calendarWeekEnd: string): Promise<ScheduleResponse[] | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.schedules,
        pathSegments: ['schedules'],
        verb: Verb.GET,
        queryParams: [
          { kind: 'single', key: 'startDate', value: calendarWeekStart },
          { kind: 'single', key: 'endDate', value: calendarWeekEnd },
        ],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getSchedulesBySelf = async (
    calendarWeekStart: string,
    calendarWeekEnd: string
  ): Promise<ScheduleResponse[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.schedules,
        pathSegments: ['schedules', 'self'],
        queryParams: [
          { kind: 'single', key: 'startDate', value: calendarWeekStart },
          { kind: 'single', key: 'endDate', value: calendarWeekEnd },
        ],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const updateShift = async (shiftId: string, shiftInfo: PlannedShiftReq): Promise<PlannedShiftResponseDto | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.schedules,
        pathSegments: ['planned-shifts', shiftId],
        payload: shiftInfo,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const deleteShift = async (shiftId: string): Promise<void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.DELETE,
        proxy: proxies.schedules,
        pathSegments: ['planned-shifts', shiftId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const publishSchedule = async (
    scheduleId: string,
    payload: PublishScheduleRequest
  ): Promise<ScheduleResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.schedules,
        pathSegments: ['schedules', scheduleId, 'publications'],
        payload,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const updateSchedule = async (scheduleId: string, payload: ScheduleUpdateRequest): Promise<ScheduleResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.schedules,
        pathSegments: ['schedules', scheduleId],
        payload,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const createSchedule = async (payload: ScheduleCreateRequest): Promise<ScheduleResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.schedules,
        pathSegments: ['schedules'],
        payload,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getWorkWeekConfig = async (): Promise<WorkWeekConfig | WorkWeekConfigResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.schedules,
        pathSegments: ['work-week', 'config'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const copySchedule = async (payload: CopyScheduleRequest): Promise<void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.schedules,
        pathSegments: ['schedules', 'copy'],
        payload,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getActualShifts = async (
    page: number,
    limit: number,
    startDate: string,
    endDate: string,
    employeeId?: string
  ): Promise<Paginated<ActualShiftResponseDto> | void> => {
    if (selectedSite.enterpriseUnitId) {
      return callBslAndHandleErrors(
        {
          verb: Verb.GET,
          proxy: proxies.schedules,
          enterpriseUnit: selectedSite.enterpriseUnitId,
          pathSegments: ['actual-shifts'],
          queryParams: [
            { kind: 'single', key: 'limit', value: limit.toString() },
            { kind: 'single', key: 'page', value: page.toString() },
            { kind: 'single', key: 'startDate', value: startDate },
            { kind: 'single', key: 'endDate', value: endDate },
            ...(employeeId ? [{ kind: 'single', key: 'employeeId', value: employeeId } as SingleQueryParam] : []),
          ],
        },
        handleError
      );
    }
    return {
      items: [],
      meta: {
        itemCount: selectedSite.enterpriseUnitId.length,
        totalItems: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0,
      },
    };
  };

  const createPunchEdit = async (payload: PunchEditRequest): Promise<ActualShiftResponseDto | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.schedules,
        pathSegments: ['punch-edit'],
        payload,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const updatePunchEdit = async (shiftId: string, payload: PunchEditRequest): Promise<ActualShiftResponseDto | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.schedules,
        pathSegments: ['punch-edit', shiftId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
        payload,
        queryParams: [{ kind: 'single', key: 'id', value: shiftId }],
      },
      handleError
    );

  const deletePunchEdit = async (shiftId: string, payload: DeletePunchEditRequest): Promise<void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.DELETE,
        proxy: proxies.schedules,
        pathSegments: ['punch-edit', shiftId],
        payload,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getSalesSummary = async (
    scheduleId: string,
    payload?: SalesSummaryDataBodySchema
  ): Promise<SalesSummaryData[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.schedules,
        payload,
        pathSegments: ['schedules', scheduleId, 'sales-summary'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getSalesSummaryForWeek = async (
    payload: SalesSummaryDataForWeekBodySchema
  ): Promise<SalesSummaryData[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.schedules,
        payload,
        pathSegments: ['schedules', 'sales-summary'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const updateWorkWeekConfig = async (payload: WorkWeekConfigBodySchema): Promise<WorkWeekConfigResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.schedules,
        pathSegments: ['work-week'],
        payload,
      },
      handleError
    );

  const generateSchedulePdf = (
    scheduleId: string,
    body: CreateWeeklySchedulePdfBodySchema
  ): Promise<string | undefined> =>
    callBslAndHandleErrors<string, unknown>(
      {
        verb: Verb.POST,
        proxy: proxies.schedules,
        payload: body,
        pathSegments: ['schedules', scheduleId, 'weekly/pdf'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    ).then((res) => {
      if (res) {
        const buffer = Buffer.from(res);
        const blob = new Blob([buffer], { type: 'application/pdf' });
        return URL.createObjectURL(blob);
      }
      return '';
    });

  const generateSchedulePdfByDate = (
    startDate: string,
    endDate: string,
    body: CreateWeeklySchedulePdfBodySchema
  ): Promise<string | undefined> =>
    callBslAndHandleErrors<string, unknown>(
      {
        verb: Verb.POST,
        proxy: proxies.schedules,
        payload: body,
        pathSegments: ['schedules', 'weekly/pdf'],
        queryParams: [
          { kind: 'single', key: 'startDate', value: startDate },
          { kind: 'single', key: 'endDate', value: endDate },
        ],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    ).then((res) => {
      if (res) {
        const buffer = Buffer.from(res);
        const blob = new Blob([buffer], { type: 'application/pdf' });
        return URL.createObjectURL(blob);
      }
      return '';
    });

  return {
    getPlannedShifts,
    createShifts,
    getSchedules,
    getSchedulesBySelf,
    updateShift,
    deleteShift,
    publishSchedule,
    updateSchedule,
    createSchedule,
    getWorkWeekConfig,
    getActualShifts,
    createPunchEdit,
    updatePunchEdit,
    deletePunchEdit,
    getSalesSummary,
    getSalesSummaryForWeek,
    generateSchedulePdf,
    generateSchedulePdfByDate,
    copySchedule,
    updateWorkWeekConfig,
  };
};

export default useSchedulingRequests;
