import { BSLEmploymentStatus, Req } from '@cbo/shared-library';
import { MultiQueryParam, SingleQueryParam } from '@cbo/shared-library/request/firebase.request';
import { UserData } from '@cbo/shared-library/types/bslCore/provisioning';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { validate as isValidUuid } from 'uuid';
import { downloadFile } from '../utilities/laborUtilities';
import routes from '../../constants/routes';
import { useSites } from '../../contexts/siteContext';
import { useSnackbar } from '../../contexts/SnackbarContext';
import useCallBslAndHandleErrors, { useUpdateUserStatusAndHandleErrors } from '../../lib/firebaseApiHandleErrors';
import useCallBsl from '../../utils/hooks/useCallBsl';

import { PaginationResponse } from '../../models/Paginated';
import { BaseReportDataRequest } from '../../sales/requests/requests';
import {
  ApproachingACAHourlyThresholdGridDataRequest,
  ApproachingACAHourlyThresholdGridDataResponse,
  ApproachingACAThresholdAverageHoursWorkedGridResponse,
  ChangeJobActivationStatusDto,
  CreateEmployeeRequestSchema,
  CreateEmployeeResponse,
  CreateSelfEmployeeRequestSchema,
  CreateSelfEmployeeResponse,
  Employee,
  EmployeeBreakReportCommonRequestSchema,
  EmployeeBreakReportGridDataResponse,
  EmployeeBreakReportKpiCardsDataResponse,
  EmployeeContactDetailsInformation,
  EmployeeDetailsInfo,
  EmployeePayrollDetailDataGridResponse,
  EmployeePayrollDto,
  EmployeePayrollGridResponse,
  EmployeePayrollKPICardData,
  EmployeeSalesPerformanceCommonRequest,
  EmployeeSalesPerformanceGridDataResponse,
  EmployeeSalesPerformanceKpiCardsResponse,
  EmployeeSitesGridRow,
  EmployeeTipKPICardsAndChartsData,
  EmployeeTipReportGridDataResponse,
  EmploymentHistoryIdResponse,
  GenericPayrollExportGridDataRequest,
  GenericPayrollExportGridDataResponse,
  GenericPayrollExportDataResponse,
  GetEmployeesRequestSchema,
  GetJobProfilesRequestSchema,
  GetPaginatedJobsRequest,
  IntervalSalesAndLaborAverageSalesResponse,
  IntervalSalesAndLaborGridDataResponse,
  IntervalSalesAndLaborKPIResponse,
  IntervalSalesAndLaborReportCommonRequestSchema,
  Job,
  JobcodeHistoryGridRequest,
  JobcodeHistoryGridResponse,
  JobProfile,
  LaborGroup,
  OvertimeThresholdCommonRequest,
  OvertimeThresholdGridDataResponse,
  OvertimeThresholdKpiCardsResponse,
  SelfJobProfile,
  UpdateEmployeeCertificationInfoSchema,
  UpdateEmployeeCertificationSchema,
  UpdateEmployeeContactInfoRequestSchema,
  UpdateEmployeeDetailsSchema,
  UpdateEmployeeHistorySchema,
  UpdateEmployeePersonalInfoRequestSchema,
  UpdateEmployeeRequestSchema,
  UpdateJobDto,
  UpdateJobProfileDto,
  UpdateResponse,
  UpdateUserStatusResponse,
} from '../types';

export type LaborRequests = {
  getEmployee: (employeeId: string) => Promise<Employee | void>;
  getSelfEmployee: () => Promise<Employee | void>;
  updateSelfEmployeeInfo: (
    updateSelfEmployeeRequestSchema: UpdateEmployeeContactInfoRequestSchema | UpdateEmployeePersonalInfoRequestSchema
  ) => Promise<UpdateResponse | void>;
  createEmploymentHistory(
    employeeId: string,
    employmentHistory: UpdateEmployeeHistorySchema
  ): Promise<EmploymentHistoryIdResponse | void>;
  getJobcodeHistoryGridData: (body: JobcodeHistoryGridRequest) => Promise<JobcodeHistoryGridResponse | void>;
  getSelfEmployeeDetailsInfo: () => Promise<EmployeeDetailsInfo | void>;
  updateSelfEmployeeCertification: (
    certification: UpdateEmployeeCertificationInfoSchema,
    certificationId: string
  ) => Promise<UpdateResponse | void>;
  createSelfEmployeeCertification: (
    certification: UpdateEmployeeCertificationInfoSchema
  ) => Promise<UpdateResponse | void>;
  updateContactInformation: (
    employeeId: string,
    contactInformation: EmployeeContactDetailsInformation
  ) => Promise<UpdateResponse | void>;
  updateEmployeeDetails: (
    employeeId: string,
    employeeDetails: UpdateEmployeeDetailsSchema
  ) => Promise<UpdateResponse | void>;
  updateEmployeeCertification: (
    employeeId: string,
    certificationId: string,
    updateCertificationsDTO: UpdateEmployeeCertificationInfoSchema
  ) => Promise<UpdateResponse | void>;
  createEmployeeCertification: (
    employeeId: string,
    updateCertificationsDTO: UpdateEmployeeCertificationSchema
  ) => Promise<UpdateResponse | void>;
  getGenericPayrollExportGridData: (
    body: GenericPayrollExportGridDataRequest
  ) => Promise<GenericPayrollExportGridDataResponse[] | void>;
  exportGenericPayrollData: (
    body: GenericPayrollExportGridDataRequest
  ) => Promise<GenericPayrollExportDataResponse | void>;
  getOvertimeThresholdReportGridData: (
    body: OvertimeThresholdCommonRequest
  ) => Promise<OvertimeThresholdGridDataResponse | void>;
  getOvertimeThresholdReportKpiCardsData: (
    body: OvertimeThresholdCommonRequest
  ) => Promise<OvertimeThresholdKpiCardsResponse | void>;
  getEmployeeSalesPerformanceReportGridData: (
    body: EmployeeSalesPerformanceCommonRequest
  ) => Promise<EmployeeSalesPerformanceGridDataResponse | void>;
  getEmployeeSalesPerformanceReportKpiCardsData: (
    body: EmployeeSalesPerformanceCommonRequest
  ) => Promise<EmployeeSalesPerformanceKpiCardsResponse | void>;
  getEmployeeTipReportGridData: (
    body: EmployeeBreakReportCommonRequestSchema
  ) => Promise<EmployeeTipReportGridDataResponse | void>;
  getEmployeeTipReportKpiCardsData: (
    body: EmployeeBreakReportCommonRequestSchema
  ) => Promise<EmployeeTipKPICardsAndChartsData | void>;
  getEmployeeBreakReportGridData: (
    body: EmployeeBreakReportCommonRequestSchema
  ) => Promise<EmployeeBreakReportGridDataResponse | void>;
  getEmployeeBreakReportKpiCardsData: (
    body: EmployeeBreakReportCommonRequestSchema
  ) => Promise<EmployeeBreakReportKpiCardsDataResponse | void>;
  getIntervalSalesAndLaborGridData: (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ) => Promise<IntervalSalesAndLaborGridDataResponse | void>;
  getIntervalSalesAndLaborKPICards: (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ) => Promise<IntervalSalesAndLaborKPIResponse | void>;
  getIntervalSalesAndLaborAverageSales: (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ) => Promise<IntervalSalesAndLaborAverageSalesResponse | void>;
  getApproachingACAHourlyThresholdGridData: (
    body: ApproachingACAHourlyThresholdGridDataRequest
  ) => Promise<ApproachingACAHourlyThresholdGridDataResponse | void>;
  getAcaAverageHoursGridData: (
    body: BaseReportDataRequest
  ) => Promise<ApproachingACAThresholdAverageHoursWorkedGridResponse | void>;
  getEmployeePayrollGridData: (body: EmployeePayrollDto) => Promise<EmployeePayrollGridResponse | void>;
  getEmployeePayrollKPICardsData: (body: EmployeePayrollDto) => Promise<EmployeePayrollKPICardData | void>;
  getEmployeePayrollDetailGridData: (body: EmployeePayrollDto) => Promise<EmployeePayrollDetailDataGridResponse | void>;
  getJobs: (query?: GetPaginatedJobsRequest) => Promise<PaginationResponse<Job> | void>;
  getJobById: (jobId: string) => Promise<Job | void>;
  updateJob: (jobId: string, job: UpdateJobDto) => Promise<void>;
  changeJobActivation: (jobId: string, changeJobActivationDto: ChangeJobActivationStatusDto) => Promise<void>;
  getLaborGroupByIdApi: (laborGroupId: string) => Promise<LaborGroup | void>;
  getJobProfilesByJobId: (jobId: string) => Promise<PaginationResponse<JobProfile> | void>;
  getEmployeeByEmployeeId: (employeeId: string, querySiteId?: string | null) => Promise<Employee | void>;
  getEmployees: (
    params?: GetEmployeesRequestSchema,
    excludeEUID?: boolean
  ) => Promise<PaginationResponse<Employee> | void>;
  getAsmEmployees: (params?: GetEmployeesRequestSchema) => Promise<PaginationResponse<Employee> | void>;
  updateEmployee: (employeeId: string, data: UpdateEmployeeRequestSchema) => Promise<UpdateResponse | void>;
  getJobProfilesByEmployeeId: (
    employeeId: string,
    includeHistory: boolean
  ) => Promise<PaginationResponse<JobProfile> | void>;
  getEmployeeSitesByEmployeeId: (employeeId: string) => Promise<PaginationResponse<EmployeeSitesGridRow> | void>;
  getLaborGroups: () => Promise<PaginationResponse<LaborGroup> | void>;
  getUrlWithEmployeeId: (id: string, querySiteId?: string | undefined) => Promise<string | undefined>;
  createJobProfile: (jobProfile: JobProfile) => Promise<string | void>;
  updateJobProfile: (jobProfileId: string, jobProfile: UpdateJobProfileDto) => Promise<string | void>;
  deleteJobProfile: (jobProfileId: string) => Promise<string | void>;
  getAllJobProfiles: (
    requestParameters?: GetJobProfilesRequestSchema
  ) => Promise<PaginationResponse<JobProfile> | void>;
  updateEmploymentHistory: (
    employeeId: string,
    employmentHistoryId: string,
    employmentHistory: UpdateEmployeeHistorySchema
  ) => Promise<void>;
  createSelfEmployee: (employee: CreateSelfEmployeeRequestSchema) => Promise<CreateSelfEmployeeResponse | void>;
  createEmployee: (employee: CreateEmployeeRequestSchema) => Promise<CreateEmployeeResponse | void>;
  getSelfJobProfile: () => Promise<SelfJobProfile[] | void>;
  updateUserStatus: (username: string, status: BSLEmploymentStatus) => Promise<UpdateUserStatusResponse | void>;
  getBspUser: (username: string) => Promise<UserData | void>;
};

const useLaborRequests = (): LaborRequests => {
  const handleError = useErrorHandler();
  const callBslAndHandleErrors = useCallBslAndHandleErrors();
  const callBsl = useCallBsl();
  const updateUserStatusAndHandleErrors = useUpdateUserStatusAndHandleErrors();
  const { selectedSite } = useSites();

  const proxies = {
    sales: 'sales',
    labor: 'labor',
    users: 'provisioning.users',
  };

  const Verb = Req.Firebase.HttpVerb;
  const { setSnackbarState } = useSnackbar();
  const { t } = useTranslation();
  /**
   * EMPLOYEE
   */

  const getEmployee = async (employeeId: string): Promise<Employee | void> =>
    callBslAndHandleErrors(
      {
        proxy: 'employees',
        verb: Verb.GET,
        pathSegments: ['employees', employeeId],
      },
      handleError
    );

  const getSelfEmployee = async (): Promise<Employee | void> =>
    callBslAndHandleErrors(
      {
        proxy: 'employees',
        verb: Verb.GET,
        pathSegments: ['employees', 'self'],
      },
      handleError
    );

  const updateContactInformation = async (
    employeeId: string,
    contactInformation: EmployeeContactDetailsInformation
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['employees', `${employeeId}`, 'contact'],
        payload: contactInformation,
      },
      handleError
    );

  const updateEmployeeDetails = async (
    employeeId: string,
    employeeDetails: UpdateEmployeeDetailsSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['employees', `${employeeId}`],
        payload: employeeDetails,
      },
      handleError
    );

  /**
   * EMPLOYEE CONFIG
   */
  // helper method to get query params for get employees

  const getEmployeesParams = (
    params?: GetEmployeesRequestSchema
  ): Array<Req.Firebase.MultiQueryParam | Req.Firebase.SingleQueryParam> => {
    const queryParams: Array<Req.Firebase.MultiQueryParam | Req.Firebase.SingleQueryParam> = [
      // TODO: Setting this to max page size allowed for now. Update once pagination is completely implemented.
      { kind: 'single', key: 'pagesize', value: '100' },
    ];

    if (params?.employmentStatus) {
      queryParams.push({ kind: 'single', key: 'employmentStatus', value: params.employmentStatus });
    }
    if (params?.employeeIds) {
      queryParams.push({ kind: 'multi', key: 'employeeIds', value: params.employeeIds });
    }
    if (params?.posEmployeeId) {
      queryParams.push({ kind: 'single', key: 'posEmployeeId', value: params.posEmployeeId });
    }
    if (params?.includeJobProfileCount) {
      queryParams.push({ kind: 'single', key: 'includeJobProfileCount', value: params.includeJobProfileCount });
    }
    if (params?.enterpriseUnit) {
      queryParams.push({ kind: 'single', key: 'enterpriseUnit', value: params.enterpriseUnit });
    }
    return queryParams;
  };

  const getJobProfilesParams = (
    params?: GetJobProfilesRequestSchema
  ): Array<Req.Firebase.MultiQueryParam | Req.Firebase.SingleQueryParam> => {
    const queryParams: Array<Req.Firebase.MultiQueryParam | Req.Firebase.SingleQueryParam> = [
      { kind: 'single', key: 'pagesize', value: '100' },
    ];

    if (params?.pagenum) {
      queryParams.push({ kind: 'single', key: 'pagenum', value: String(params.pagenum) });
    }

    if (params?.jobProfileIds) {
      queryParams.push({ kind: 'multi', key: 'jobProfileIds', value: params.jobProfileIds });
    }

    if (params?.includeHistory) {
      queryParams.push({ kind: 'single', key: 'includeHistory', value: String(params.includeHistory) });
    }

    return queryParams;
  };

  const getLaborGroupByIdApi = (laborGroupId: string): Promise<LaborGroup | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['labor-groups', laborGroupId],
      },
      handleError
    );

  /**
   * JOB CODES
   */

  const getJobcodeHistoryGridData = (body: JobcodeHistoryGridRequest): Promise<JobcodeHistoryGridResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['jobcode-history', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getSelfEmployeeDetailsInfo = async (): Promise<EmployeeDetailsInfo | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['self', 'employee-details'],
      },
      handleError
    );

  /**
   * Self Update Employee Info
   */

  const updateSelfEmployeeInfo = async (
    updateSelfEmployeeRequestSchema: UpdateEmployeeContactInfoRequestSchema | UpdateEmployeePersonalInfoRequestSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['self', 'employee-details'],
        payload: updateSelfEmployeeRequestSchema,
      },
      handleError
    );

  /**
   * CERTIFICATIONS
   */

  const updateEmployeeCertification = async (
    employeeId: string,
    certificationId: string,
    updateCertificationsDTO: UpdateEmployeeCertificationInfoSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['employees', `${employeeId}`, 'certifications', `${certificationId}`],
        payload: updateCertificationsDTO,
      },
      handleError
    );

  const createEmployeeCertification = async (
    employeeId: string,
    updateCertificationsDTO: UpdateEmployeeCertificationInfoSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.labor,
        pathSegments: ['employees', `${employeeId}`, 'certifications'],
        payload: updateCertificationsDTO,
      },
      handleError
    );

  const updateSelfEmployeeCertification = async (
    certification: UpdateEmployeeCertificationInfoSchema,
    certificationId: string
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['self', 'certifications', `${certificationId}`],
        payload: certification,
      },
      handleError
    );
  const createSelfEmployeeCertification = async (
    certification: UpdateEmployeeCertificationInfoSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.labor,
        pathSegments: ['self', 'certifications'],
        payload: certification,
      },
      handleError
    );

  /**
   * EMPLOYEE SALES PERFORMANCE
   */

  const getEmployeeSalesPerformanceReportGridData = async (
    body: EmployeeSalesPerformanceCommonRequest
  ): Promise<EmployeeSalesPerformanceGridDataResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['employee-sales', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getEmployeeSalesPerformanceReportKpiCardsData = async (
    body: EmployeeSalesPerformanceCommonRequest
  ): Promise<EmployeeSalesPerformanceKpiCardsResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['employee-sales', 'kpi-cards'],
        payload: body,
      },
      handleError
    );

  /**
   * APPROACHING OT THRESHOLD
   */

  const getOvertimeThresholdReportGridData = async (
    body: OvertimeThresholdCommonRequest
  ): Promise<OvertimeThresholdGridDataResponse | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['approaching-overtime-threshold', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getOvertimeThresholdReportKpiCardsData = async (
    body: OvertimeThresholdCommonRequest
  ): Promise<OvertimeThresholdKpiCardsResponse | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['approaching-overtime-threshold', 'kpi-cards'],
        payload: body,
      },
      handleError
    );

  /**
   * GENERIC PAYROLL EXPORT
   */
  const getGenericPayrollExportGridData = async (
    body: GenericPayrollExportGridDataRequest
  ): Promise<GenericPayrollExportGridDataResponse[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['generic-payroll-export'],
        payload: body,
      },
      handleError
    );

  /**
   * GENERIC PAYROLL EXPORT
   */
  const exportGenericPayrollData = async (
    body: GenericPayrollExportGridDataRequest
  ): Promise<GenericPayrollExportDataResponse | void> => {
    const data: GenericPayrollExportDataResponse = await callBsl({
      verb: Verb.POST,
      proxy: proxies.sales,
      pathSegments: ['generic-payroll-export', 'export'],
      payload: body,
    });
    if (data.signedUrl) {
      downloadFile(data.signedUrl);
    }
    return data;
  };
  /**
   * EMPLOYEE TIP REPORT
   */

  const getEmployeeTipReportGridData = async (
    body: EmployeeBreakReportCommonRequestSchema
  ): Promise<EmployeeTipReportGridDataResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['employee-tip', 'grid-data'], payload: body },
      handleError
    );

  const getEmployeeTipReportKpiCardsData = async (
    body: EmployeeBreakReportCommonRequestSchema
  ): Promise<EmployeeTipKPICardsAndChartsData | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['employee-tip', 'kpi-cards'], payload: body },
      handleError
    );

  /**
   * EMPLOYEE BREAK REPORT
   */
  const getEmployeeBreakReportGridData = async (
    body: EmployeeBreakReportCommonRequestSchema
  ): Promise<EmployeeBreakReportGridDataResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['employee-break', 'grid-data'], payload: body },
      handleError
    );

  const getEmployeeBreakReportKpiCardsData = async (
    body: EmployeeBreakReportCommonRequestSchema
  ): Promise<EmployeeBreakReportKpiCardsDataResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['employee-break', 'kpi-cards'], payload: body },
      handleError
    );

  /**
   * INTERVAL SALES AND LABOR REPORT
   */

  const getIntervalSalesAndLaborGridData = async (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ): Promise<IntervalSalesAndLaborGridDataResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['interval-sales-labor', 'grid-data'], payload: body },
      handleError
    );

  const getIntervalSalesAndLaborKPICards = async (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ): Promise<IntervalSalesAndLaborKPIResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['interval-sales-labor', 'kpi-cards'], payload: body },
      handleError
    );

  const getIntervalSalesAndLaborAverageSales = async (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ): Promise<IntervalSalesAndLaborAverageSalesResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['interval-sales-labor', 'average-sales'], payload: body },
      handleError
    );

  /**
   * APPROACHING ACA THRESHOLD
   */

  const getApproachingACAHourlyThresholdGridData = async (
    body: ApproachingACAHourlyThresholdGridDataRequest
  ): Promise<ApproachingACAHourlyThresholdGridDataResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['approaching-aca-threshold', 'hourly-threshold', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getAcaAverageHoursGridData = async (
    body: BaseReportDataRequest
  ): Promise<ApproachingACAThresholdAverageHoursWorkedGridResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['approaching-aca-threshold', 'employee-average', 'grid-data'],
        payload: body,
      },
      handleError
    );

  /**
   * Employee Payroll Report
   */

  const getEmployeePayrollDetailGridData = async (
    body: EmployeePayrollDto
  ): Promise<EmployeePayrollDetailDataGridResponse | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['employee-payroll', 'detail', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getEmployeePayrollKPICardsData = async (body: EmployeePayrollDto): Promise<EmployeePayrollKPICardData | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['employee-payroll', 'kpi-cards'],
        payload: body,
      },
      handleError
    );

  const getEmployeePayrollGridData = async (body: EmployeePayrollDto): Promise<EmployeePayrollGridResponse | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['employee-payroll', 'summary', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getJobs = async (query?: GetPaginatedJobsRequest): Promise<PaginationResponse<Job> | void> => {
    const queryParams: Array<MultiQueryParam | SingleQueryParam> = [];

    if (query?.ids?.length) {
      queryParams.push({ kind: 'multi', key: 'ids[]', value: query?.ids });
    }

    if (query?.pagenum) {
      queryParams.push({ kind: 'single', key: 'pagenum', value: String(query?.pagenum) });
    }

    if (query?.includeJobProfileCount) {
      queryParams.push({ kind: 'single', key: 'includeJobProfileCount', value: 'true' });
    }

    return callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['jobs'],
        queryParams: [
          ...queryParams,
          // TODO: Setting this to max page size allowed for now. Update once pagination is completely implemented.
          { kind: 'single', key: 'pagesize', value: '100' },
        ],
      },
      handleError
    );
  };

  const getJobById = async (jobId: string): Promise<Job | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['jobs', jobId],
        queryParams: [{ kind: 'single', key: 'includeJobProfileCount', value: 'true' }],
      },
      handleError
    );

  const updateJob = async (jobId: string, job: UpdateJobDto): Promise<void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['jobs', jobId],
        payload: job,
      },
      handleError
    );

  const changeJobActivation = async (
    jobid: string,
    changeJobActivationDto: ChangeJobActivationStatusDto
  ): Promise<void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.labor,
        pathSegments: ['jobs', jobid, 'changeActivation'],
        payload: changeJobActivationDto,
      },
      handleError
    );

  const getEmployees = async (
    params?: GetEmployeesRequestSchema,
    excludeEUID?: boolean
  ): Promise<PaginationResponse<Employee> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['employees'],
        queryParams: excludeEUID
          ? getEmployeesParams({ ...params })
          : getEmployeesParams({ ...params, enterpriseUnit: selectedSite.enterpriseUnitId }),
      },
      handleError
    );

  const getAsmEmployees = async (params?: GetEmployeesRequestSchema): Promise<PaginationResponse<Employee> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['employees', 'users-at-asm'],
        queryParams: getEmployeesParams({ ...params }),
      },
      handleError
    );

  const getJobProfilesByJobId = async (jobId: string): Promise<PaginationResponse<JobProfile> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['jobs', jobId, 'jobProfiles'],
        queryParams: [
          { kind: 'single', key: 'jobId', value: jobId },
          { kind: 'single', key: 'pagesize', value: '100' },
        ],
      },
      handleError
    );

  const getEmployeeByEmployeeId = async (employeeId: string, querySiteId?: string | null): Promise<Employee | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['employees', employeeId],
        queryParams: [{ kind: 'single', key: 'enterpriseUnit', value: querySiteId ?? selectedSite.enterpriseUnitId }],
      },
      handleError
    );

  const updateEmployee = async (
    employeeId: string,
    data: UpdateEmployeeRequestSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['employees', employeeId],
        payload: data,
      },
      handleError
    );

  const getJobProfilesByEmployeeId = async (
    employeeId: string,
    includeHistory?: boolean
  ): Promise<PaginationResponse<JobProfile> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['employees', employeeId, 'job-profiles'],
        queryParams: [
          { kind: 'single', key: 'includeHistory', value: (includeHistory ?? false).toString() },
          { kind: 'single', key: 'pagesize', value: '100' },
        ],
      },
      handleError
    );

  const getEmployeeSitesByEmployeeId = async (
    employeeId: string
  ): Promise<PaginationResponse<EmployeeSitesGridRow> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['employees', employeeId, 'effective-grants'],
        queryParams: [{ kind: 'single', key: 'pagesize', value: '100' }],
      },
      handleError
    );

  const getLaborGroups = (): Promise<PaginationResponse<LaborGroup> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['labor-groups'],
      },
      handleError
    );

  const getUrlWithEmployeeId = (id: string, querySiteId?: string): Promise<string | undefined> =>
    callBslAndHandleErrors<PaginationResponse<Employee>, void>(
      {
        proxy: proxies.labor,
        verb: Verb.GET,
        pathSegments: ['employees'],
        queryParams: isValidUuid(id)
          ? [
              {
                kind: 'multi',
                key: 'employeeIds',
                value: [id.toString()],
              },
            ]
          : [
              {
                kind: 'single',
                key: 'posEmployeeId',
                value: id.toString(),
              },
            ],
      },
      handleError
    )
      .then((res: PaginationResponse<Employee> | void) => {
        const castRes = res as PaginationResponse<Employee>;
        if (castRes.pageContent.length === 0) {
          setSnackbarState({
            open: true,
            message: t('errors.employeeNotFound'),
            autoHideDuration: 2000,
            color: 'error',
          });
          return '';
        }
        const { employeeId } = castRes.pageContent[0];
        const queryParams = querySiteId ? `?site=${querySiteId}` : '';
        const route = routes.EMPLOYEE_GENERAL_INFORMATION_PAGE.replace(':employeeId', employeeId);
        return window.location.origin + route + queryParams;
      })
      .catch((err) => {
        setSnackbarState({
          open: true,
          message: t('sales.errors.genericMessage'),
          color: 'error',
        });
        return err;
      });

  const createEmploymentHistory = async (
    employeeId: string,
    employmentHistory: UpdateEmployeeHistorySchema
  ): Promise<EmploymentHistoryIdResponse | void> => {
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.labor,
        pathSegments: ['employees', `${employeeId}`, 'employment-history'],
        payload: employmentHistory,
      },
      handleError
    );
  };

  const updateEmploymentHistory = async (
    employeeId: string,
    employmentHistoryId: string,
    employmentHistory: UpdateEmployeeHistorySchema
  ): Promise<void> => {
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['employees', employeeId, 'employment-history', employmentHistoryId],
        payload: employmentHistory,
      },
      handleError
    );
  };

  const createJobProfile = async (jobProfile: JobProfile): Promise<string | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.labor,
        pathSegments: ['job-profiles'],
        payload: jobProfile,
      },
      handleError
    );

  const updateJobProfile = async (jobProfileId: string, jobProfile: UpdateJobProfileDto): Promise<void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['job-profiles', jobProfileId],
        payload: jobProfile,
      },
      handleError
    );

  const deleteJobProfile = async (jobProfileId: string): Promise<string | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.DELETE,
        proxy: proxies.labor,
        pathSegments: ['job-profiles', jobProfileId],
      },
      handleError
    );

  const getAllJobProfiles = async (
    pageParam?: GetJobProfilesRequestSchema
  ): Promise<PaginationResponse<JobProfile> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['job-profiles'],
        queryParams: getJobProfilesParams(pageParam),
      },
      handleError
    );

  const createSelfEmployee = async (
    employee: CreateSelfEmployeeRequestSchema
  ): Promise<CreateSelfEmployeeResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.labor,
        pathSegments: ['self', 'employee'],
        payload: employee,
      },
      handleError
    );

  const createEmployee = async (employee: CreateEmployeeRequestSchema): Promise<CreateEmployeeResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.labor,
        pathSegments: ['employees'],
        payload: employee,
      },
      handleError
    );

  const getSelfJobProfile = async (): Promise<SelfJobProfile[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['self', 'job-profiles'],
      },
      handleError
    );

  const updateUserStatus = async (
    userName: string,
    status: BSLEmploymentStatus
  ): Promise<UpdateUserStatusResponse | void> =>
    updateUserStatusAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.users,
        pathSegments: [userName, 'status'],
        payload: { status },
      },
      handleError
    );

  const getBspUser = async (userName: string): Promise<UserData | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.users,
        pathSegments: [userName],
      },
      handleError
    );

  return {
    getEmployee,
    createEmploymentHistory,
    getSelfEmployee,
    getJobcodeHistoryGridData,
    getEmployeeByEmployeeId,
    getSelfEmployeeDetailsInfo,
    updateSelfEmployeeCertification,
    createSelfEmployeeCertification,
    updateContactInformation,
    updateEmployeeDetails,
    updateEmployeeCertification,
    createEmployeeCertification,
    getGenericPayrollExportGridData,
    exportGenericPayrollData,
    getOvertimeThresholdReportGridData,
    getOvertimeThresholdReportKpiCardsData,
    getEmployeeTipReportGridData,
    getEmployeeTipReportKpiCardsData,
    getEmployeeBreakReportGridData,
    getEmployeeBreakReportKpiCardsData,
    getIntervalSalesAndLaborGridData,
    getIntervalSalesAndLaborKPICards,
    getIntervalSalesAndLaborAverageSales,
    getEmployeeSalesPerformanceReportGridData,
    getEmployeeSalesPerformanceReportKpiCardsData,
    getApproachingACAHourlyThresholdGridData,
    getAcaAverageHoursGridData,
    getEmployeePayrollDetailGridData,
    getEmployeePayrollKPICardsData,
    getEmployeePayrollGridData,
    getJobs,
    getJobById,
    updateJob,
    changeJobActivation,
    getLaborGroupByIdApi,
    getJobProfilesByJobId,
    getEmployees,
    getAsmEmployees,
    updateEmployee,
    getJobProfilesByEmployeeId,
    getEmployeeSitesByEmployeeId,
    updateSelfEmployeeInfo,
    getLaborGroups,
    getUrlWithEmployeeId,
    createJobProfile,
    updateJobProfile,
    deleteJobProfile,
    getAllJobProfiles,
    updateEmploymentHistory,
    createSelfEmployee,
    createEmployee,
    getSelfJobProfile,
    updateUserStatus,
    getBspUser,
  };
};

export default useLaborRequests;
