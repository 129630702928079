const schedulesKeys = {
  listByCalendarWeek: (calendarWeekStart: string, calendarWeekEnd: string) => [
    'schedules',
    calendarWeekStart,
    calendarWeekEnd,
  ],
  selfListByCalendarWeek: (calendarWeekStart: string, calendarWeekEnd: string) => [
    'selfSchedules',
    calendarWeekStart,
    calendarWeekEnd,
  ],
  plannedShiftsByRange: (startDate: string, endDate: string, enterpriseUnitId: string) => [
    'plannedShifts',
    startDate,
    endDate,
    enterpriseUnitId,
  ],
  dayOfWeekStart: ['dayOfWeekStart'],
  actualShiftsByRange: (startDate: string, endDate: string, enterpriseUnitId: string, employeeId?: string) => [
    'actualShifts',
    startDate,
    endDate,
    enterpriseUnitId,
    employeeId,
  ],
  summarySales: (scheduleId: string) => ['schedules', scheduleId, 'sales-summary'],
  summarySalesForWeek: (startDate: string, endDate: string) => ['schedules', 'sales-summary', startDate, endDate],
};

const schedulingKeys = {
  schedules: schedulesKeys,
};

export default schedulingKeys;
