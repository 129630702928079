import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  DateRange,
  DateRangePickerDay,
  LocalizationProvider,
  PickersShortcutsItem,
  StaticDateRangePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { useTranslation } from 'react-i18next';
import FilterFrame from '../FilterFrame/FilterFrame';
import { useAccountPreferences } from '../../../contexts/accountPreferencesContext';
import { convertRegionToDayJsLocale } from '../../../../countryAndRegionData';
import {
  CustomToolbar,
  CustomPickersShortcuts,
  getDefaultChips,
  DateRangePickerDayStyled,
} from './DateFilterFunctions';
import DateFilterButton from './DateFilterButton';
import { BaseGlobalFilterProps, ButtonSize, DateFilterChip } from '../types';
import { chipBackgroundColor, darkBackgroundColor } from '../GlobalFilterBarStyles';
import { useFilters } from '../../../contexts/filterContext';
import { getPayrollPeriodOptions } from '../../../labor/utilities/laborUtilities';

dayjs.extend(LocalizedFormat);
dayjs.extend(isBetween);

/**
 * @param controllerName The name of the form value the component uses
 * @param customChips Optional, custom chips to be displayed on the date filter
 * @param onlyFilterContainer Optional, whether the date filter should be displayed as a standalone component
 * @param useChips Optional, whether the date filter should use chips
 * @param buttonText The text that appears on the button
 * @param buttonSize Optional, the size of the button
 * @param predefined Optional, whether the date filter should act as a predefined component with a date range set by default. Use this if the form has default values not equal to null
 * @param disableFutureDates Optional, whether the date filter should allow future dates
 * @param weekFilterSettings Optional, settings for when you want to utilize this date filter as a week filter
 * @param weekFilterSettings.weekFilterControllerName The name of the singular date form value the week filter uses. See datePickerNav in useFullCalendarToolbar for an example
 * @param weekFilterSettings.localeName The locale name for the week filter to use with dayjs for start/end of week dates. See useSchedulingOffsetLocale for how to create a locale for use here. When using that file, for example, localeName = 'schedule-offset'
 * @param yearsAgoMax Optional, the maximum number of years ago the date filter should allow
 */
export interface DateFilterProps extends BaseGlobalFilterProps {
  customChips?: DateFilterChip[];
  useChips?: boolean;
  predefined?: boolean;
  disableFutureDates?: boolean;
  weekFilterSettings?: {
    weekFilterControllerName: string;
    localeName: string;
  };
  showPayrollFilters?: boolean;
  yearsAgoMax?: number;
  numberOfCalendars?: 1 | 2 | 3;
}

function DateFilter(props: DateFilterProps) {
  const {
    predefined,
    customChips,
    onlyFilterContainer = true,
    buttonText = '',
    buttonSize = ButtonSize.SMALL,
    useChips,
    controllerName,
    disableFutureDates = true,
    showPayrollFilters,
    weekFilterSettings,
    yearsAgoMax = 3,
    numberOfCalendars = 2,
  } = props;
  const { localeName = '', weekFilterControllerName = '' } = weekFilterSettings || {};
  const { t } = useTranslation();
  const { preferences } = useAccountPreferences();
  const { fiscalCalendarFilters, payrollCalendarFilters } = useFilters();

  const form = useFormContext();
  const { control, resetField, watch, setValue } = form;
  const watching = watch(controllerName);
  const watchDateNav = watch(weekFilterControllerName);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dates, setDates] = React.useState<DateRange<Dayjs>>([null, null]);

  const useDefaultChips = useChips && !customChips;
  const useCustomChips = useChips && customChips;

  const defaultChipValuesWithLabels = useMemo(() => {
    const fiscalCalendarFiltersData =
      fiscalCalendarFilters && fiscalCalendarFilters.periods && fiscalCalendarFilters.periods?.length > 0
        ? getDefaultChips(fiscalCalendarFilters, disableFutureDates)
        : [];
    // To place payrollCalendarFiltersData at 11th, 12th Index
    const payrollCalendarFiltersData =
      showPayrollFilters && payrollCalendarFilters ? getPayrollPeriodOptions(t, payrollCalendarFilters) : [];
    return [
      ...fiscalCalendarFiltersData.slice(0, 10),
      ...payrollCalendarFiltersData,
      ...fiscalCalendarFiltersData.slice(10, fiscalCalendarFiltersData.length),
    ];
  }, [disableFutureDates, fiscalCalendarFilters, showPayrollFilters, payrollCalendarFilters, t]);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (dates[0] && dates[1] && !weekFilterSettings)
      setValue(
        controllerName,
        {
          startDate: dates[0].format('YYYY-MM-DD'),
          endDate: dates[1].format('YYYY-MM-DD'),
        },
        { shouldDirty: true }
      );
    if (dates[0] && dates[1] && weekFilterSettings) setValue('datePickerNav', dates[0]);
    setAnchorEl(null);
  };

  const handleReset = useCallback(() => {
    if (!weekFilterSettings) {
      resetField(controllerName);
      setDates([dayjs(watching.startDate), dayjs(watching.endDate)]);
    }
    if (weekFilterSettings) {
      setValue('datePickerNav', dayjs());
      setDates([dayjs().locale(localeName).startOf('week'), dayjs().locale(localeName).endOf('week')]);
    }
  }, [controllerName, localeName, resetField, setValue, watching.endDate, watching.startDate, weekFilterSettings]);

  const resetEnabled = useMemo(() => {
    const dirtiness = form.formState.dirtyFields;

    const resetValueForSchedule = weekFilterSettings
      ? !dates[0]?.isSame(dayjs().locale(localeName).startOf('week'), 'day') &&
        !dates[1]?.isSame(dayjs().locale(localeName).endOf('week'), 'day')
      : false;

    const resetValue =
      Object.keys(dirtiness).includes(controllerName) ||
      !dates[0]?.isSame(dayjs(watching.startDate), 'day') ||
      !dates[1]?.isSame(dayjs(watching.endDate), 'day');
    return weekFilterSettings ? resetValueForSchedule : resetValue;
  }, [
    controllerName,
    dates,
    form.formState.dirtyFields,
    localeName,
    watching.endDate,
    watching.startDate,
    weekFilterSettings,
  ]);

  const button = useMemo(
    () => (
      <DateFilterButton
        anchorEl={anchorEl}
        buttonSize={buttonSize}
        buttonText={buttonText}
        handleReset={handleReset}
        resetEnabled={resetEnabled}
        predefined={predefined}
        handleButtonClick={handleButtonClick}
        dates={dates}
      />
    ),
    [anchorEl, buttonSize, buttonText, dates, handleReset, predefined, resetEnabled]
  );

  useEffect(() => {
    if (watching && !weekFilterSettings) {
      setDates([dayjs(watching.startDate), dayjs(watching.endDate)]);
    }
    if (watching && weekFilterSettings) {
      setDates([watchDateNav.locale(localeName).startOf('week'), watchDateNav.locale(localeName).endOf('week')]);
    }
  }, [localeName, watchDateNav, watching, weekFilterSettings]);

  const slotProperties = useMemo(() => {
    if (useDefaultChips) {
      const defaultShortcuts: PickersShortcutsItem<DateRange<Dayjs>>[] = defaultChipValuesWithLabels.map((chip) => ({
        label: chip.label,
        getValue: () => chip.dates as DateRange<Dayjs>,
      }));
      return {
        actionBar: { actions: [] },
        shortcuts: { items: defaultShortcuts },
      };
    }
    if (useCustomChips) {
      const customChipItems: PickersShortcutsItem<DateRange<Dayjs>>[] = customChips.map((chip) => ({
        label: chip.label,
        getValue: () => chip.dates as DateRange<Dayjs>,
      }));
      return {
        actionBar: { actions: [] },
        shortcuts: { items: customChipItems },
      };
    }

    return { actionBar: { actions: [] } };
  }, [customChips, defaultChipValuesWithLabels, useCustomChips, useDefaultChips]);

  const handleChipSx = (idx: number): SxProps<Theme> | undefined => {
    if (
      (useDefaultChips &&
        dates[0] === defaultChipValuesWithLabels[idx].dates[0] &&
        dates[1] === defaultChipValuesWithLabels[idx].dates[1]) ||
      (useCustomChips && dates[0] === customChips[idx].dates[0] && dates[1] === customChips[idx].dates[1])
    ) {
      return {
        backgroundColor: chipBackgroundColor,
        margin: '5px',
        color: darkBackgroundColor,
        '&:hover': {
          backgroundColor: chipBackgroundColor,
          color: darkBackgroundColor,
        },
      };
    }
    return { margin: '5px' };
  };

  const handleOnChange = (date: DateRange<Dayjs>) => {
    if (date[0] && date[1] && !weekFilterSettings) {
      setDates([date[0], date[1]]);
    } else if (weekFilterSettings && !date[0]?.isBetween(dates[0], dates[1], 'd')) {
      setDates([dayjs(date[0]).locale(localeName).startOf('week'), dayjs(date[0]).locale(localeName).endOf('week')]);
    }
  };

  // This is the normal static date range picker, with chips to the left side of the calendar
  const IndividualDateComponent = (
    <Controller
      name={controllerName}
      control={control}
      render={({ field }) => (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={convertRegionToDayJsLocale(preferences?.region)}
        >
          <Box data-testid='date-filter'>
            <StaticDateRangePicker
              disableHighlightToday={weekFilterSettings ? true : undefined}
              rangePosition={weekFilterSettings ? 'start' : undefined}
              disableDragEditing
              disableFuture={disableFutureDates}
              key={controllerName}
              slots={{
                toolbar: (e) => CustomToolbar(e, handleClose),
                shortcuts: (e) =>
                  useChips ? CustomPickersShortcuts(e, slotProperties.shortcuts?.items, controllerName, dates) : null,
                day: weekFilterSettings ? DateRangePickerDayStyled : DateRangePickerDay,
              }}
              slotProps={slotProperties}
              calendars={numberOfCalendars}
              value={dates}
              onChange={(date: DateRange<Dayjs>) => handleOnChange(date)}
              minDate={dayjs().subtract(yearsAgoMax, 'year').startOf('year').date(1)}
            />
          </Box>
        </LocalizationProvider>
      )}
    />
  );

  // Within AllFilters, we want to only use one calendar, with the shortcuts located above the calendar
  const EmbeddedDateComponent = (
    <Controller
      name={controllerName}
      control={control}
      render={({ field }) => (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={convertRegionToDayJsLocale(preferences?.region)}
        >
          <Grid container direction='column' data-testid='date-filter'>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                paddingLeft: '34px', // 18px from the calendar + 5px from the chip margin + 16px from the padding (-5px from the chip margin)
              }}
            >
              {defaultChipValuesWithLabels.map((chip, index) => (
                <Chip
                  id={chip.label}
                  key={chip.label}
                  sx={handleChipSx(index)}
                  label={chip.label}
                  onClick={() => {
                    setDates([chip.dates[0], chip.dates[1]]);
                  }}
                />
              ))}
            </Box>
            <Grid item>
              <StaticDateRangePicker
                disableFuture={disableFutureDates}
                key={controllerName}
                sx={{ width: '100%', alignItems: 'center', paddingLeft: '18px' }}
                slotProps={{ actionBar: { actions: [] } }}
                calendars={1}
                value={dates}
                onChange={(date: DateRange<Dayjs>) => handleOnChange(date)}
                minDate={dayjs().subtract(yearsAgoMax, 'year').startOf('year').date(1)}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      )}
    />
  );

  // Return the filteframe with the date component if onlyFilterContainer is false, otherwise return the embedded date component
  if (!onlyFilterContainer) {
    return (
      <FilterFrame
        useHeader={false}
        anchorEl={anchorEl}
        body={IndividualDateComponent}
        title='titleText'
        button={button}
        handleClose={handleClose}
        resetEnabled={resetEnabled}
        resetFunction={handleReset}
        useMaxWidth={false}
        useDoneButton
        doneFunction={handleClose}
      />
    );
  }
  return EmbeddedDateComponent;
}

DateFilter.defaultProps = {
  useChips: true,
  customChips: undefined,
  predefined: false,
  disableFutureDates: true,
  showPayrollFilters: false,
  weekFilterSettings: undefined,
  yearsAgoMax: 3,
  numberOfCalendars: 2,
};

export default DateFilter;
