/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent, ReactNode } from 'react';
import { DatePicker, DatePickerProps, PickersDayProps } from '@mui/x-date-pickers-pro';
import { Control, Controller, ControllerProps, FieldError, Path, FieldValues } from 'react-hook-form';
import { TextFieldProps } from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';
import { useAccountPreferences } from '../../contexts/accountPreferencesContext';
import { formatDayJsDateInput } from '../../utils';

export declare type ParseableDate<TDate> = string | number | Date | null | undefined | TDate;

export type DatePickerElementProps<T extends FieldValues> = Omit<
  DatePickerProps<Dayjs>,
  'value' | 'onChange' | 'renderInput' | 'onBlur'
> & {
  name: Path<T>;
  required?: boolean;
  renderDay?: FunctionComponent<PickersDayProps<Dayjs>>;
  parseError?: (error: FieldError) => string;
  validation?: ControllerProps['rules'];
  control?: Control<FieldValues>;
  inputProps?: TextFieldProps;
  helperText?: TextFieldProps['helperText'];
  inputFormat?: string;
  disablePlaceholder?: boolean;
  disableMaskedInput?: boolean;
  disableOpenPicker?: boolean;
  handleBlur?: any;
  disabled?: boolean;
  outlined?: boolean;
};

export const getHelperTextForDatePicker = (
  error: FieldError | undefined,
  parseError: ((error: FieldError) => string) | undefined,
  helperText: ReactNode
): string | ReactNode | undefined => {
  if (error) {
    return typeof parseError === 'function' ? parseError(error) : error.message;
  }
  return helperText;
};

export default function DatePickerElement<TFieldValues extends FieldValues>({
  parseError,
  renderDay,
  name,
  required,
  validation = {},
  inputProps,
  control,
  inputFormat,
  disablePlaceholder,
  disableMaskedInput,
  disableOpenPicker,
  handleBlur,
  disabled,
  outlined,
  ...rest
}: DatePickerElementProps<TFieldValues>): JSX.Element {
  const { preferences } = useAccountPreferences();
  const userPreferenceFormat = formatDayJsDateInput(preferences);
  const dateFormat = inputFormat ?? userPreferenceFormat;
  const upperCaseInputFormat = dateFormat ? dateFormat.toString().toUpperCase() : '';

  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DatePicker
          disableOpenPicker={disableOpenPicker}
          {...rest}
          value={value ? dayjs(value) : null}
          format={dateFormat}
          slots={{ day: renderDay }}
          slotProps={{
            textField: {
              ...inputProps,
              name: 'startDate',
              variant: outlined ? 'outlined' : 'standard',
              required,
              error: !!error,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              'data-testid': `${name}-datepicker-text-field`,
              fullWidth: inputProps?.fullWidth ?? true,
              placeholder: disablePlaceholder ? '' : upperCaseInputFormat,
              helperText: getHelperTextForDatePicker(error, parseError, inputProps?.helperText || rest.helperText),
            },
          }}
          disabled={disabled}
          onChange={(inputValue, keyboardInputValue) => {
            if (handleBlur) {
              handleBlur();
            }
            onChange(inputValue, keyboardInputValue);
          }}
        />
      )}
    />
  );
}
