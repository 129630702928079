import Button, { ButtonProps } from '@mui/material/Button';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';

function LogoutButton({ onClick, ...props }: Omit<ButtonProps, 'type' | 'onClick'> & { onClick?: () => void }) {
  const { oktaAuth } = useOktaAuth();
  const { t } = useTranslation();

  const handleLogout = async () => {
    if (onClick) onClick();
    await oktaAuth.signOut();
  };

  return (
    <Button {...props} type='button' onClick={handleLogout} data-element-id='logout-button'>
      {t('buttonText.logout')}
    </Button>
  );
}

LogoutButton.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
};

export default LogoutButton;
