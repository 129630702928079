import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import Menu from '@mui/material/Menu';
import { UserProfileOption } from '@ncr-voyix-commerce/react-common-components';
import { MutableRefObject } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUserProfileContextInternal } from '../CommonComponentWrapper';
import LogoutButton from './LogoutButton';
import ProfileIcon from './ProfileIcon';

function MenuIconOption({
  option,
  onClick,
  selected,
}: {
  option: UserProfileOption;
  onClick: () => void;
  selected: boolean;
}) {
  const { linkProps = {}, onClick: onClickOption, icon, label, url } = option;

  const menuButton = (
    <Box display='flex' alignItems='center' padding='12px 16px'>
      {icon && (
        <Box display='flex' lineHeight={1} paddingRight='8px' color={(theme: Theme) => theme.palette.action.active}>
          {icon}
        </Box>
      )}
      <Typography variant='body1'>{label}</Typography>
    </Box>
  );

  return (
    <MenuItem
      onClick={() => {
        if (onClickOption) onClickOption();
        onClick();
      }}
      sx={{
        padding: 0,
        a: {
          textDecoration: 'none',
          width: '100%',
          color: 'inherit',
        },
      }}
      selected={selected}
    >
      {url !== undefined ? (
        <Link {...linkProps} to={url}>
          {menuButton}
        </Link>
      ) : (
        menuButton
      )}
    </MenuItem>
  );
}

function UserProfileSettingsMenu({
  anchorRef,
  menuOpen,
  onMenuClose,
  options,
}: {
  anchorRef: MutableRefObject<HTMLButtonElement | null>;
  menuOpen: boolean;
  onMenuClose: () => void;
  options: UserProfileOption[];
}) {
  const { userProfile } = useUserProfileContextInternal();
  const hasUserName = !!userProfile?.displayName;
  const theme = useTheme();
  const currentLocation = useLocation();

  return (
    <Menu
      anchorEl={() => anchorRef.current as HTMLButtonElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '.MuiMenu-paper': {
          minWidth: '288px',
        },
      }}
      MenuListProps={{
        sx: {
          padding: '4px 0px',
        },
      }}
      open={menuOpen}
      onClose={onMenuClose}
    >
      <Box display={hasUserName ? 'flex' : 'none'} alignItems='center' padding='8px 12px'>
        <Box paddingRight='16px'>
          <ProfileIcon />
        </Box>
        <Typography variant='body1'>{userProfile?.displayName}</Typography>
      </Box>
      <Divider sx={{ display: hasUserName ? 'block' : 'none' }} />
      <MenuList sx={{ padding: '4px 0px', width: '100%' }} data-element-id='user-menu-list'>
        {options.map((option: UserProfileOption) => (
          <MenuIconOption
            key={option.label}
            selected={currentLocation?.pathname === option?.url}
            option={option}
            onClick={onMenuClose}
          />
        ))}
      </MenuList>
      <Divider />
      <Box padding='4px' data-element-id='user-menu-footer'>
        <LogoutButton
          onClick={onMenuClose}
          sx={{
            width: '100%',
            justifyContent: 'flex-start',
            textTransform: 'none',
            padding: '8px',
            color: theme.palette.text.primary,
            fontWeight: theme.typography.body1.fontWeight,
            fontSize: theme.typography.body1.fontSize,
          }}
        />
      </Box>
    </Menu>
  );
}

export default UserProfileSettingsMenu;
