import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { TFunction } from 'react-i18next';
import * as yup from 'yup';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';

import { PayrollPeriod } from '@cbo/shared-library';
import { FunctionsErrorCode } from 'firebase/functions';
import { UseFormGetValues, UseFormReset } from 'react-hook-form';
import { QueryClient } from '@tanstack/react-query';
import { PayrollCalendarFilters } from '@cbo/shared-library/response/calendar.response';
import { DateFilterChip, SingleSelectGroup } from '../../components/GlobalFilterBar/types';
import { getEndDate } from '../../utils/reportingUtils/filterUtils';
import { TEmployeeDetailsRegistrationTask } from '../EmployeeRegistrationPage/EmployeeRegistrationHomePage/EmployeeRegistrationHomePage';
import {
  ActualHoursWorked,
  EmploymentHistory,
  EmploymentStatus,
  Employee,
  LaborGroupSchema,
  OvertimeThresholds,
  UpdateEmployeePermissionsSchema,
  UpdateResponse,
} from '../types';
import { PHONE_NUMBER_VALIDATION } from '../../utils/regexUtils/commonRegex';
import { HttpsError } from '../../contexts/firebaseApiContext';

export const countryCallingCodes = getCountries().map((countryCode) => `+${getCountryCallingCode(countryCode)}`);

const phoneNumberSchema = (validationMessage: string, optional = false, isValidationRequired = true): yup.AnySchema => {
  let baseSchema: yup.AnySchema = yup.string();
  if (optional) {
    baseSchema = yup.string().nullable();
  }
  return baseSchema.test('phoneValidation', validationMessage, (inputValue: string | undefined) => {
    if ((optional && !isValidationRequired) || countryCallingCodes.includes(inputValue ?? '')) {
      return true;
    }
    if (inputValue) {
      return PHONE_NUMBER_VALIDATION.test(inputValue);
    }
    return true;
  });
};

const employeePermissionsDataWrapper = (data: UpdateEmployeePermissionsSchema): UpdateEmployeePermissionsSchema => {
  const returnData: UpdateEmployeePermissionsSchema = {
    prefersQwertyKeyboard: data.prefersQwertyKeyboard,
    useMagneticCards: data.useMagneticCards,
    posEmployeeId: data.posEmployeeId,
    useFingerPrintScannerForClockIn: data.useFingerPrintScannerForClockIn,
    useFingerPrintScannerForLoginAndManagerApproval: data.useFingerPrintScannerForLoginAndManagerApproval,
  };
  return returnData;
};

const laborGroupDataMapper = (data: LaborGroupSchema[] | undefined) =>
  data
    ? data.map((item) => ({
        label: item.name,
        id: item.id,
        value: item.name,
      }))
    : [];

const createEmployeeRegistrationTasks = (): TEmployeeDetailsRegistrationTask[] => [
  {
    name: 'employeeInformation',
    link: 'employee-information',
  },
  {
    name: 'personalInformation',
    link: 'personal-information',
  },
  {
    name: 'certification',
    link: 'employee-certification',
  },
];

const constructFullName = (firstName: string | undefined, lastName: string | undefined) => `${firstName} ${lastName}`;

const getEmployeeNameUsingEmail = (employees: Employee[] | undefined, employeeEmail: string | undefined) => {
  if (employeeEmail) {
    const employee = employees?.find((currentEmployee) => {
      if (!currentEmployee?.contact?.emailAddress) return false;
      return currentEmployee?.contact?.emailAddress === employeeEmail;
    });
    return employee?.contact ? constructFullName(employee.contact.firstName, employee.contact.lastName) : employeeEmail;
  }
  return employeeEmail;
};

const getUpdatedBy = (
  stringToFormat: string,
  activeEmployees: Employee[] | undefined,
  inactiveEmployees: Employee[] | undefined,
  pendingEmployees: Employee[] | undefined,
  t: (label: string) => string
) => {
  // The NCRUserId is different than a user's email. Stripping string to be just the email.
  let splitStringToFormat = stringToFormat.substring(stringToFormat.indexOf(':') + 1);
  if (splitStringToFormat.split('-').length <= 2) {
    splitStringToFormat = splitStringToFormat.includes('-') ? splitStringToFormat.split('-')[1] : splitStringToFormat;
  }
  // Checking the string is successfully getting a first and last name and return it
  const activeFormat = getEmployeeNameUsingEmail(activeEmployees, splitStringToFormat);
  const inactiveFormat = getEmployeeNameUsingEmail(inactiveEmployees, splitStringToFormat);
  const pendingFormat = getEmployeeNameUsingEmail(pendingEmployees, splitStringToFormat);

  if (activeFormat?.includes(' ')) {
    return activeFormat;
  }
  if (inactiveFormat?.includes(' ')) {
    return inactiveFormat;
  }
  if (pendingFormat?.includes(' ')) {
    return pendingFormat;
  }
  return splitStringToFormat.includes('service-account') ? t('labor.serviceAccount') : splitStringToFormat;
};

export const getHoursWorkedOptions = (hoursWorked: Record<string, ActualHoursWorked>): SingleSelectGroup[] => {
  const options: SingleSelectGroup[] = [];
  Object.entries(hoursWorked).forEach(([key]) => {
    options.push({
      label: key,
    });
  });

  return options;
};

export const getACAHoursWorked = (
  t: TFunction<'translation', undefined>,
  overtimeThresholds: OvertimeThresholds,
  includeLessThan = true
): { hoursWorked: Record<string, ActualHoursWorked>; hoursWorkedOptions: SingleSelectGroup[] } => {
  const maxHoursPossibleInWeek = 168;
  const hoursWorked: Record<string, ActualHoursWorked> = {
    [t('labor.all')]: { lower: 0, higher: maxHoursPossibleInWeek },
    [t('labor.fromXToX', {
      number1: overtimeThresholds.lowerThreshold,
      number2: overtimeThresholds.middleThreshold,
    })]: { lower: overtimeThresholds.lowerThreshold, higher: overtimeThresholds.middleThreshold },
    [t('labor.fromXToX', {
      number1: overtimeThresholds.middleThreshold,
      number2: overtimeThresholds.upperThreshold,
    })]: { lower: overtimeThresholds.middleThreshold, higher: overtimeThresholds.upperThreshold },
    [t('labor.moreThanX', { number: overtimeThresholds.upperThreshold })]: {
      lower: overtimeThresholds.upperThreshold,
      higher: maxHoursPossibleInWeek,
    },
  };

  if (includeLessThan) {
    hoursWorked[t('labor.lessThanX', { number: overtimeThresholds.lowerThreshold })] = {
      lower: 0,
      higher: overtimeThresholds.lowerThreshold,
    };
  }

  return { hoursWorked, hoursWorkedOptions: getHoursWorkedOptions(hoursWorked) };
};

export const getOTHoursWorked = (
  t: TFunction<'translation', undefined>,
  overtimeThresholds: OvertimeThresholds
): { hoursWorked: Record<string, ActualHoursWorked>; hoursWorkedOptions: SingleSelectGroup[] } => {
  const hoursWorked: Record<string, ActualHoursWorked> = {
    [t('labor.all')]: { lower: undefined, higher: undefined },
    [t('labor.fromXToX', {
      number1: overtimeThresholds.lowerThreshold,
      number2: overtimeThresholds.middleThreshold,
    })]: { lower: overtimeThresholds.lowerThreshold, higher: overtimeThresholds.middleThreshold },
    [t('labor.fromXToX', {
      number1: overtimeThresholds.middleThreshold,
      number2: overtimeThresholds.upperThreshold,
    })]: { lower: overtimeThresholds.middleThreshold, higher: overtimeThresholds.upperThreshold },
    [t('labor.moreThanX', { number: overtimeThresholds.upperThreshold })]: {
      lower: overtimeThresholds.upperThreshold,
      higher: undefined,
    },
  };

  return { hoursWorked, hoursWorkedOptions: getHoursWorkedOptions(hoursWorked) };
};

export const getPayrollPeriodOptions = (
  t: TFunction<'translation', undefined>,
  payrollCalendarFilter: PayrollCalendarFilters | undefined
): DateFilterChip[] => {
  if (payrollCalendarFilter) {
    return [
      {
        label: t(`labor.currentPayrollPeriod`),
        dates: [
          dayjs(payrollCalendarFilter[PayrollPeriod.PAY_PERIOD_START_DATE].value),
          dayjs(getEndDate(payrollCalendarFilter[PayrollPeriod.PAY_PERIOD_END_DATE].value)),
        ],
      },
      {
        label: t(`labor.lastPayrollPeriod`),
        dates: [
          dayjs(payrollCalendarFilter[PayrollPeriod.PREVIOUS_PAY_PERIOD_START_DATE].value),
          dayjs(getEndDate(payrollCalendarFilter[PayrollPeriod.PREVIOUS_PAY_PERIOD_END_DATE].value)),
        ],
      },
    ];
  }
  return [];
};

const mapActivationStatusToEmploymentStatus = (employeeHistory?: EmploymentHistory | null): EmploymentStatus | null => {
  const activationStatus = employeeHistory?.employmentStatus;
  let mappedStatusValue;
  switch (activationStatus) {
    case 'PENDING':
      mappedStatusValue = EmploymentStatus.PENDING;
      break;
    case 'TERMINATED':
      mappedStatusValue = EmploymentStatus.TERMINATED;
      break;
    case 'LEAVE_OF_ABSENCE':
      mappedStatusValue = EmploymentStatus.LEAVE_OF_ABSENCE;
      break;
    case 'HIRED':
      mappedStatusValue = EmploymentStatus.HIRED;
      break;
    default:
      mappedStatusValue = null;
      break;
  }
  return mappedStatusValue;
};

export const getLatestEmployeeHistoryItem = (employee?: Employee | null) =>
  employee?.employmentHistory.sort((a, b) => {
    if (isNil(a.effectiveDate) || isNil(b.effectiveDate)) {
      return 0;
    }
    return new Date(b.effectiveDate).getTime() - new Date(a.effectiveDate).getTime();
  })[0];

export const isPhoneNumberWithOnlyCountryCode = (phoneNumber: string) => countryCallingCodes.includes(phoneNumber);

export const errorHandling = (
  err: HttpsError,
  checkFirebaseErrorCodeExists: (err: HttpsError, codeToCheck: FunctionsErrorCode) => boolean,
  setSnackbarState: (state: { open: boolean; message: string; color: 'error' | 'success' }) => void,
  t: TFunction<'translation', undefined>
) => {
  const errorMessage = (err?.details as { message?: string })?.message || err?.message;
  if (
    checkFirebaseErrorCodeExists(err as HttpsError, 'functions/invalid-argument') &&
    errorMessage?.includes('ERR_READ_ONLY_FIELD_CANNOT_BE_EDITED')
  ) {
    setSnackbarState({
      open: true,
      message: `${t('labor.FE_ERR_READONLY_FIELDS_CANNOT_BE_EDITED')}`,
      color: 'error',
    });
  } else if (checkFirebaseErrorCodeExists(err as HttpsError, 'functions/invalid-argument')) {
    setSnackbarState({
      open: true,
      message: `${t('labor.existingShiftsError')}`,
      color: 'error',
    });
  } else {
    setSnackbarState({
      open: true,
      message: `${t('errors.genericErrorMessage')}`,
      color: 'error',
    });
  }
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const successHandling = (
  results: PromiseSettledResult<void | UpdateResponse>[],
  setSnackbarState: (state: { open: boolean; message: string; color: 'error' | 'success' }) => void,
  setEditModes: (editModes: boolean[]) => void,
  t: TFunction<'translation', undefined>,
  reset: UseFormReset<any>,
  queryClient: QueryClient,
  queryKeys: any,
  selectedEmployee: Employee,
  getValues: UseFormGetValues<any>
) => {
  const rejectedResult = results.find((result) => result.status === 'rejected') as PromiseRejectedResult;
  if (rejectedResult) {
    throw rejectedResult.reason;
  } else if (results?.length > 0 && results.some((result) => result.status === 'fulfilled')) {
    setEditModes([false, false, false, false, false, false]);
    setSnackbarState({
      open: true,
      message: `${t('labor.formSuccessMessage')}`,
      color: 'success',
    });
    reset(getValues());
    queryClient.invalidateQueries(queryKeys.employee.byEmpId(selectedEmployee.employeeId));
  }
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const invalidateEmployeeList = (
  status: EmploymentStatus,
  queryClient: QueryClient,
  queryKeys: any,
  enterpriseUnitId: string
) => {
  queryClient.invalidateQueries(
    queryKeys.employee.listByFilters({
      employmentStatus: status,
      includeJobProfileCount: 'true',
      enterpriseUnit: enterpriseUnitId,
    })
  );
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-shadow */
export const getContactInformation = (data: any, dirtyFields: any) => {
  const employeeInfo: any = {};
  const dirtyFieldsMap = Object.keys(dirtyFields);

  if (!dirtyFieldsMap.length) return null;

  dirtyFieldsMap.forEach((fieldName) => {
    employeeInfo[fieldName] = data?.[fieldName];
  });

  return employeeInfo;
};

const getIntervalOptions = (intervalTypeOptions: Record<string, number>): SingleSelectGroup[] => {
  const options: SingleSelectGroup[] = [];
  Object.entries(intervalTypeOptions).forEach(([key]) => {
    options.push({
      label: key,
    });
  });

  return options;
};

export const getIntervalTypeOptions = (
  t: TFunction<'translation', undefined>
): { intervalTypes: Record<string, number>; intervalTypeOptions: SingleSelectGroup[] } => {
  const validIntervals = [15, 30, 60];
  const intervalTypes: Record<string, number> = Object.fromEntries(
    validIntervals.map((granularity) => [
      t('labor.intervalSalesAndLaborReportIntervalOption', { granularity }),
      granularity,
    ])
  );

  return { intervalTypes, intervalTypeOptions: getIntervalOptions(intervalTypes) };
};

export const downloadFile = (signedUrl: string) => {
  const anchor = document.createElement('a');
  anchor.href = signedUrl;
  anchor.download = '';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  return true;
};

const LaborUtilities = {
  phoneNumberSchema,
  constructFullName,
  getEmployeeNameUsingEmail,
  getUpdatedBy,
  employeePermissionsDataWrapper,
  laborGroupDataMapper,
  createEmployeeRegistrationTasks,
  mapActivationStatusToEmploymentStatus,
  getLatestEmployeeHistoryItem,
  isPhoneNumberWithOnlyCountryCode,
  errorHandling,
  getContactInformation,
  getIntervalTypeOptions,
};

export default LaborUtilities;
