import Person from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import { SxProps, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useUserProfileContextInternal } from '../CommonComponentWrapper';

const ProfileIconSizes = {
  size: '32px',
  fontSize: '12px',
  fontSizeOneInitial: '14px',
};

function ProfileIcon() {
  const [initials, setInitials] = useState<string>('');
  const theme = useTheme();
  const { userProfile } = useUserProfileContextInternal();

  useEffect(() => {
    const splitName = userProfile?.displayName ? userProfile?.displayName.split(' ') : [];

    if (splitName[0]) {
      setInitials(`${splitName[0][0].toUpperCase()}`);
    }

    if (splitName[0] && splitName[1]) {
      setInitials(`${splitName[0][0].toUpperCase()}${splitName[1][0].toUpperCase()}`);
    }
  }, [userProfile?.displayName]);

  const sxProps: SxProps = {
    height: ProfileIconSizes.size,
    width: ProfileIconSizes.size,
    fontSize: initials.length > 1 ? ProfileIconSizes.fontSize : ProfileIconSizes.fontSizeOneInitial,
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.common.white,
    lineHeight: 1,
    paddingTop: '.09em',
  };

  if (!userProfile?.displayName) {
    return <Skeleton variant='circular' sx={sxProps} />;
  }

  return initials.length > 0 ? (
    <Avatar sx={sxProps}>{initials}</Avatar>
  ) : (
    <Avatar sx={sxProps}>
      <Person fontSize='medium' />
    </Avatar>
  );
}

export default ProfileIcon;
