import { SxProps, Theme } from '@mui/material/styles';
import {
  DataGridPremium,
  GridEventListener,
  GridRowId,
  GridInitialState,
  DataGridPremiumProps,
  GridLocaleText,
  GridRowModesModel,
  GridFeatureMode,
  DATA_GRID_PREMIUM_PROPS_DEFAULT_VALUES,
  GridFilterModel,
  GridSortModel,
  GridRowParams,
  MuiEvent,
  GridColumnVisibilityModel,
  GridCellParams,
  GridCallbackDetails,
  GridPaginationModel,
  GridRowSelectionModel,
  GridInputRowSelectionModel,
  GridDensity,
} from '@mui/x-data-grid-premium';
import { useState, useCallback, MutableRefObject } from 'react';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { FeatureFlag } from '@cbo/shared-library';
import { Toolbar } from './helpers';
import CustomSkeletonLoadingOverlay from './CustomSkeletonLoadingOverlay';
import { DatagridStyles } from './styles';
import { useFeatureFlag } from '../../utils/hooks/useFeatureFlag';

export interface BaseGridRow {
  [key: string]: unknown;
  id: GridRowId;
}

export interface BaseDataGridProps extends DataGridPremiumProps {
  rows: BaseGridRow[];
  itemsPerPage?: number;
  pageSizeOptions?: number[];
  sx?: SxProps<Theme>;
  hideToolbar?: boolean;
  hideFooter?: boolean;
  disableColumnSelector?: boolean;
  disableColumnFilter?: boolean;
  disableExport?: boolean;
  disableDensitySelector?: boolean;
  disableQuickFilter?: boolean;
  disableMultipleColumnsFiltering?: boolean;
  disableMultipleColumnsSorting?: boolean;
  disableSelectionOnClick?: boolean;
  disableVirtualization?: boolean;
  disableColumnResize?: boolean;
  disableColumnReorder?: boolean;
  initialState?: GridInitialState;
  dataTestId?: string;
  autoPageSize?: boolean;
  onRowClick?: GridEventListener<'rowClick'>;
  onRowEdit?: (params: unknown, secondary?: unknown) => Promise<void>;
  onCellKeyDown?: (params: GridCellParams, event: MuiEvent<React.KeyboardEvent>, details: GridCallbackDetails) => void;
  loading?: boolean;
  localeText?: Partial<GridLocaleText>;
  rowModesModel?: GridRowModesModel;
  rowCount?: number;
  page?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  paginationMode?: GridFeatureMode;
  autoHeight?: boolean;
  apiRef?: MutableRefObject<GridApiPremium>;
  onStateChange?: GridEventListener<'stateChange'>;
  filterMode?: GridFeatureMode;
  sortingMode?: GridFeatureMode;
  onFilterModelChange?: (filterModel: GridFilterModel) => void;
  checkboxSelection?: boolean;
  keepNonExistentRowsSelected?: boolean;
  sortModel?: GridSortModel;
  onSortModelChange?: (sortModel: GridSortModel) => void;
  columnVisibilityModel?: GridColumnVisibilityModel | undefined;
  defaultGroupingExpansionDepth?: number;
  isRowSelectable?: (params: GridRowParams) => boolean;
  rowGroupingModel?: string[];
  onRowSelectionModelChange?: (params: GridRowSelectionModel) => void;
  rowSelectionModel?: GridInputRowSelectionModel;
  showExport?: boolean;
  isExportLoading?: boolean;
  exportCSV?: () => void;
}
function DataGrid(props: BaseDataGridProps) {
  const {
    pinnedRows,
    columns,
    rows,
    itemsPerPage,
    pageSizeOptions,
    sx,
    hideToolbar,
    hideFooter,
    autoPageSize,
    disableColumnSelector,
    disableColumnFilter,
    disableMultipleColumnsFiltering,
    disableMultipleColumnsSorting,
    disableExport,
    disableDensitySelector,
    disableQuickFilter,
    disableSelectionOnClick,
    disableVirtualization,
    disableColumnResize,
    disableColumnReorder,
    disableColumnMenu,
    disableColumnSorting,
    initialState,
    dataTestId,
    onRowClick,
    onRowEdit,
    onCellKeyDown,
    columnVisibilityModel,
    loading,
    localeText,
    rowModesModel,
    rowCount,
    page,
    onPageChange,
    onPageSizeChange,
    processRowUpdate,
    paginationMode,
    autoHeight,
    apiRef,
    onStateChange,
    filterMode,
    sortingMode,
    onFilterModelChange,
    checkboxSelection,
    keepNonExistentRowsSelected,
    onRowSelectionModelChange,
    rowSelectionModel,
    sortModel,
    onSortModelChange,
    hideFooterSelectedRowCount,
    treeData,
    getTreeDataPath,
    groupingColDef,
    getRowHeight,
    getRowClassName,
    pinnedColumns,
    defaultGroupingExpansionDepth,
    onCellClick,
    isRowSelectable,
    rowGroupingModel,
    rowGroupingColumnMode,
    showExport,
    isExportLoading,
    exportCSV,
  } = props;
  const uiKit = useFeatureFlag(FeatureFlag.restaurantsUiKit);
  const [pageSize, setPageSize] = useState<number | undefined>(itemsPerPage);
  const [density, setDensity] = useState<GridDensity>('standard');

  const handleRowEditStart = (_rows: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    // eslint-disable-next-line no-param-reassign
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (_params, event) => {
    // eslint-disable-next-line no-param-reassign
    event.defaultMuiPrevented = true;
  };

  const defaultProcessRowUpdate = useCallback(
    async (newRow: unknown, oldRow: unknown): Promise<BaseGridRow> => {
      if (onRowEdit) {
        try {
          await onRowEdit(newRow, oldRow);
          return newRow as BaseGridRow;
        } catch {
          return oldRow as BaseGridRow;
        }
      }
      throw new Error('onRowEdit prop not defined');
    },
    [onRowEdit]
  );

  const handleProcessRowUpdateError = useCallback((err: unknown): void => {
    // TODO: add snackbar with error message if processRowUpdate fails
    // eslint-disable-next-line no-console
    console.error(err);
  }, []);

  return (
    <DataGridPremium
      pinnedRows={pinnedRows}
      density={density}
      onDensityChange={(newDensity: GridDensity) => setDensity(newDensity)}
      data-testid={dataTestId}
      sx={DatagridStyles(loading, sx)}
      columns={columns}
      rows={rows}
      columnVisibilityModel={columnVisibilityModel}
      disableColumnFilter={disableColumnFilter}
      disableMultipleColumnsFiltering={disableMultipleColumnsFiltering}
      disableMultipleColumnsSorting={disableMultipleColumnsSorting}
      disableVirtualization={disableVirtualization}
      disableColumnMenu={disableColumnMenu}
      disableColumnSorting={disableColumnSorting}
      onRowEditStart={handleRowEditStart}
      onRowEditStop={handleRowEditStop}
      onCellKeyDown={onCellKeyDown}
      disableRowSelectionOnClick={disableSelectionOnClick}
      hideFooter={hideFooter}
      autoPageSize={autoPageSize}
      initialState={{
        ...initialState,
        pagination: {
          paginationModel: {
            page: page ?? 0,
            pageSize: pageSize ?? 10,
          },
        },
      }}
      pageSizeOptions={pageSizeOptions}
      onPaginationModelChange={(newModel: GridPaginationModel) => {
        onPageSizeChange?.(newModel.pageSize);
        setPageSize(newModel.pageSize);
        onPageChange?.(newModel.page);
      }}
      slots={{
        loadingOverlay: CustomSkeletonLoadingOverlay,
        toolbar: useCallback(
          () =>
            Toolbar({
              hideToolbar,
              disableColumnFilter,
              disableColumnSelector,
              disableDensitySelector,
              disableExport,
              disableQuickFilter,
              showExport,
              isExportLoading,
              exportCSV,
            }),
          [
            hideToolbar,
            disableColumnFilter,
            disableColumnSelector,
            disableDensitySelector,
            disableExport,
            disableQuickFilter,
            showExport,
            isExportLoading,
            exportCSV,
          ]
        ),
      }}
      pagination={!hideFooter}
      autoHeight={autoHeight}
      columnBufferPx={12}
      onRowClick={onRowClick}
      processRowUpdate={processRowUpdate ?? defaultProcessRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
      loading={loading && rows.length === 0}
      localeText={localeText}
      editMode='row'
      rowModesModel={rowModesModel}
      rowCount={rowCount}
      paginationMode={paginationMode}
      apiRef={apiRef}
      onStateChange={onStateChange}
      sortingMode={sortingMode}
      filterMode={filterMode}
      onFilterModelChange={onFilterModelChange}
      checkboxSelection={checkboxSelection}
      keepNonExistentRowsSelected={keepNonExistentRowsSelected}
      onRowSelectionModelChange={onRowSelectionModelChange}
      rowSelectionModel={rowSelectionModel}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      hideFooterSelectedRowCount={hideFooterSelectedRowCount}
      treeData={treeData}
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColDef}
      getRowHeight={getRowHeight}
      getRowClassName={getRowClassName}
      pinnedColumns={pinnedColumns}
      defaultGroupingExpansionDepth={defaultGroupingExpansionDepth}
      onCellClick={onCellClick}
      disableColumnResize={disableColumnResize}
      disableColumnReorder={disableColumnReorder}
      isRowSelectable={isRowSelectable}
      rowGroupingModel={rowGroupingModel}
      rowGroupingColumnMode={rowGroupingColumnMode}
      columnHeaderHeight={uiKit ? 44 : 56}
    />
  );
}

DataGrid.defaultProps = {
  ...DATA_GRID_PREMIUM_PROPS_DEFAULT_VALUES,
  sx: {},
  hideFooter: false,
  autoPageSize: false,
  disableColumnFilter: false,
  disableMultipleColumnsSorting: false,
  disableMultipleColumnsFiltering: false,
  disableExport: true,
  disableDensitySelector: false,
  disableSelectionOnClick: false,
  // Disabling this by default because it's causing test failures due to v7 introducing column
  // virtualization. I think if we decide to increase rows per page, scroll behavior of the data
  // grids, or improve the tests to handle the virtualization this can be re-enabled.
  disableVirtualization: true,
  disableColumnResize: false,
  disableColumnReorder: false,
  initialState: {},
  dataTestId: '',
  itemsPerPage: 10,
  pageSizeOptions: [5, 10, 20],
  onRowClick: undefined,
  onRowEdit: undefined,
  onCellKeyDown: undefined,
  loading: false,
  localeText: {},
  rowModesModel: undefined,
  autoHeight: true,
  apiRef: undefined,
  onStateChange: undefined,
  checkboxSelection: false,
  keepNonExistentRowsSelected: false,
  isExportLoading: false,
  showExport: false,
};

export default DataGrid;
