import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Theme, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { BasicOrganizationData } from '@ncr-voyix-commerce/react-common-components';
import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrgContext } from '../CommonComponentWrapper';

function OrganizationSwitcherMenu({
  anchorRef,
  menuOpen,
  onMenuClose,
}: {
  anchorRef: MutableRefObject<HTMLButtonElement | null>;
  menuOpen: boolean;
  onMenuClose: () => void;
}) {
  const { t } = useTranslation();
  const theme = useTheme<Theme>();
  const { userOrganizations, updateOrganization, setIsOrgDialogOpen, organization } = useOrgContext();

  const handleOrgClick = (orgName: string) => {
    onMenuClose();
    updateOrganization(orgName);
  };

  const handleViewAll = () => {
    onMenuClose();
    setIsOrgDialogOpen(true);
  };

  const effectiveOrgs = userOrganizations.slice(0, 5).map((org: BasicOrganizationData) => (
    <MenuItem
      sx={{ padding: '12px 8px 12px 12px', borderRadius: '8px', margin: '4px 12px' }}
      selected={org.organizationName === organization?.organizationName}
      key={org.id}
      onClick={() => handleOrgClick(org.organizationName)}
    >
      <Typography variant='body1'>{org.displayName}</Typography>
    </MenuItem>
  ));

  return (
    <Menu
      anchorEl={() => anchorRef.current as HTMLButtonElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        '.MuiMenu-paper': {
          minWidth: '288px',
          maxHeight: '342px',
          borderRadius: '8px',
          boxShadow:
            '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
        },
      }}
      MenuListProps={{
        sx: {
          padding: '4px 0px 0px',
        },
      }}
      open={menuOpen}
      onClose={onMenuClose}
    >
      <MenuItem
        aria-hidden
        disabled
        sx={{
          padding: '0px 16px 0px 20px',
          fontWeight: 500,
          fontSize: theme.typography.body2.fontSize,
          color: theme.palette.text.secondary,
          '&.Mui-disabled': {
            opacity: 1,
          },
          height: '48px',
        }}
      >
        {t('mockOrgSwitcher.menu.title')}
      </MenuItem>
      {effectiveOrgs}
      <Divider
        sx={{
          '&.MuiDivider-root.MuiDivider-fullWidth': {
            marginBottom: 0,
          },
        }}
      />
      <MenuItem
        sx={{
          padding: '20px 0px 20px 20px',
          fontWeight: 500,
          fontSize: theme.typography.body2.fontSize,
          color: theme.palette.primary.dark,
          height: '64px',
        }}
        onClick={() => handleViewAll()}
      >
        {t('mockOrgSwitcher.menu.manageAllOrgs')}
        <ArrowForwardIcon sx={{ fontSize: '20px', marginLeft: '8px' }} />
      </MenuItem>
    </Menu>
  );
}

export default OrganizationSwitcherMenu;
