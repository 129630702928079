import DomainIcon from '@mui/icons-material/Domain';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import LoadingButton from '@mui/lab/LoadingButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrgContext } from '../CommonComponentWrapper';
import OrganizationSwitcherDialog from './OrganizationSwitcherDialog';
import OrganizationSwitcherDrawer from './OrganizationSwitcherDrawer';
import OrganizationSwitcherMenu from './OrganizationSwitcherMenu';

function MockOrganizationSwitcher() {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { isLoading, organization, userOrganizations } = useOrgContext();
  const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);
  const enableContextSwitch = userOrganizations.length > 1;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenOrgMenu = () => {
    setMenuOpen(true);
  };

  const handleCloseOrgMenu = () => {
    setMenuOpen(false);
  };

  const switcherTitle = isLoading
    ? t('mockOrgSwitcher.title')
    : organization?.displayName || t('mockOrgSwitcher.title');

  return (
    <Box sx={{ display: 'flex' }}>
      <Tooltip title={enableContextSwitch ? t('mockOrgSwitcher.title') : ''}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LoadingButton
            ref={buttonRef}
            title={t('mockOrgSwitcher.accessibility.switcher')}
            variant='text'
            loadingPosition='start'
            startIcon={<DomainIcon />}
            endIcon={
              enableContextSwitch ? (
                <UnfoldMore sx={{ color: theme.palette.action.active, height: '24px', width: '24px' }} />
              ) : null
            }
            onClick={handleOpenOrgMenu}
            loading={isLoading}
            sx={(sxtheme: Theme) => ({
              textTransform: 'none',
              color: sxtheme.palette.text.primary,
              fontWeight: 400,
              '.MuiButton-endIcon': {
                marginLeft: 0.25,
              },
              '&.Mui-disabled': {
                color: !isLoading && !enableContextSwitch ? 'inherit' : '',
              },
            })}
            disabled={!enableContextSwitch}
          >
            <Box
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: {
                  xs: '140px',
                  sm: '100%',
                },
                fontWeight: 600,
                fontSize: '16px',
              }}
            >
              {switcherTitle}
            </Box>
          </LoadingButton>
        </Box>
      </Tooltip>
      {isMobile ? (
        <OrganizationSwitcherDrawer
          menuOpen={menuOpen}
          onMenuOpen={handleOpenOrgMenu}
          onMenuClose={handleCloseOrgMenu}
        />
      ) : (
        <OrganizationSwitcherMenu anchorRef={buttonRef} menuOpen={menuOpen} onMenuClose={handleCloseOrgMenu} />
      )}
      <OrganizationSwitcherDialog />
    </Box>
  );
}

export default MockOrganizationSwitcher;
