import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { ReactNode, useRef, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import useScrollWithShadow from '../../../utils/hooks/useScrollWithShadow';

/**
 * @param title The title that is displayed in the popup
 * @param body The filter(s) displayed inside the popup
 * @param button The button used for opening the popup
 * @param handleClose The function for closing the popup
 * @param anchorEl The anchor element the popup is opening off of
 * @param resetFunction The reset function that resets the filter(s) within the popup
 * @param resetEnabled Only allows reset button to be enabled when the filter(s) have detected a change from the default state
 * @param resetButtonText The text displayed on the reset button
 * @param useMaxWidth Whether the popup should have a max width of 360px
 * @param useHeader Whether the popup should have a header
 * @param useDoneButton Whether the popup should have a done button
 * @param doneFunction The function that is called when the done button is clicked
 */
export interface FilterFrameProps {
  title: string;
  body: ReactNode;
  button: ReactNode;
  handleClose: () => void;
  anchorEl: HTMLButtonElement | null;
  resetFunction: () => void | undefined;
  resetEnabled: boolean;
  resetButtonText?: string;
  useMaxWidth?: boolean;
  useHeader?: boolean;
  useDoneButton?: boolean;
  doneFunction?: () => void | undefined;
  backButton?: ReactNode;
  showNewList?: boolean;
}

function FilterFrame(props: FilterFrameProps) {
  const {
    useHeader,
    useMaxWidth,
    resetButtonText,
    title,
    body,
    button,
    anchorEl,
    handleClose,
    resetFunction,
    resetEnabled,
    useDoneButton,
    doneFunction,
    backButton,
    showNewList,
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  const { boxShadow, setShadow, onScrollHandler, resetScrollStates } = useScrollWithShadow();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      if (ref.current.clientHeight < 480) {
        setShadow('none');
      } else if (ref.current.clientHeight) {
        setShadow();
      }
    }
  }, [ref.current?.clientHeight, setShadow, anchorEl]);

  const handlePopoverClose = () => {
    handleClose();
    resetScrollStates();
  };

  useEffect(() => {
    if (ref.current && showNewList) {
      ref.current.scrollTop = 0;
    }
  }, [showNewList]);

  const handleWidth = () => {
    if (useMaxWidth) {
      return '360px';
    }
    return '100%';
  };

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        sx={{
          '.MuiPopover-paper': {
            minWidth: '360px',
            maxWidth: handleWidth(),
            boxShadow: '8',
          },
        }}
        data-testid='filterframe-popover'
      >
        {useHeader && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '10px',
              marginBottom: '10px',
              marginLeft: '24px',
              marginRight: '11px',
              height: '45px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {backButton}
              <Typography fontWeight={500}>{title}</Typography>
            </Box>
            <IconButton
              sx={{ '&:focus-visible': { boxShadow: 'none', outline: `2px solid ${theme.palette.primary.dark}` } }}
              onClick={handlePopoverClose}
              data-testid='filterframe-clear'
              aria-label={t('globalFilters.multiselect.closeDialog')}
            >
              <ClearIcon />
            </IconButton>
          </Box>
        )}
        <Box onScroll={onScrollHandler} sx={{ maxHeight: '480px', overflow: 'auto', boxShadow }} ref={ref}>
          {body}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            sx={{
              marginBottom: '12px',
              marginTop: '12px',
              marginLeft: '16px',
              '&:focus-visible': {
                boxShadow: 'none',
                color: theme.palette.primary.main,
                background: theme.palette.mode === 'dark' ? '#163349' : '#eaf5fe',
                outline: `2px solid ${theme.palette.primary.dark}`,
              },
            }}
            disabled={!resetEnabled}
            onClick={resetFunction}
            data-testid='filterframe-reset'
          >
            {resetButtonText || t('buttonText.reset')}
          </Button>
          {useDoneButton && (
            <Button
              data-testid='filterframe-done'
              onClick={doneFunction}
              variant='contained'
              sx={{
                marginRight: '16px',
                marginBottom: '12px',
                marginTop: '12px',
                '&:focus-visible': {
                  boxShadow: 'none',
                  color: theme.palette.primary.main,
                  background: theme.palette.mode === 'dark' ? '#163349' : '#eaf5fe',
                  outline: `2px solid ${theme.palette.primary.dark}`,
                },
              }}
            >
              {t('buttonText.done')}
            </Button>
          )}
        </Box>
      </Popover>
      {button}
    </>
  );
}
FilterFrame.defaultProps = {
  useHeader: true,
  useMaxWidth: false,
  resetButtonText: undefined,
  useDoneButton: false,
  doneFunction: undefined,
  backButton: undefined,
  showNewList: undefined,
};
export default FilterFrame;
