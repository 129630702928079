import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { FieldValues, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { FormContainer } from 'react-hook-form-mui';
import { useHistory } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';
import { PersonaRole } from '@cbo/shared-library';
import Header from '../../components/Header/Header';
import createFormTextField from '../../components/FormTextFields/FormTextFields';
import useDialog from '../../utils/hooks/useDialog';
import UnsavedFormDataPrompt from '../../components/UnsavedDataPrompt/UnsavedFormDataPrompt';
import { useSnackbar } from '../../contexts/SnackbarContext';
import FormFooter from '../../components/StickyFooter/FormFooter';
import LaborSettingsScheduleChangeWarning from './LaborSettingsScheduleChangeWarning';
import { useWorkWeekConfigQuery } from '../Scheduling/requests/queries';
import { useUsers } from '../../contexts/userContext';
import { useUpdateWorkWeekConfigMutation } from '../Scheduling/requests/mutations';
import queryKeys from '../Scheduling/requests/queryKeys';
import useYupValidationResolver from '../../utils/formUtils/yupValidationResolver';
import { WorkWeekConfigBodySchema, WorkWeekConfigResponse } from '../Scheduling/types';
import { loggedInAsPersona } from '../../lib/persona';
import AuditStamp from '../AuditStamp/AuditStamp';

export default function LaborSettingsSchedulePage() {
  const { t } = useTranslation();
  const user = useUsers();
  const isAdminPersona = loggedInAsPersona(user, [PersonaRole.ADMIN]);
  const history = useHistory();
  const queryClient = useQueryClient();
  const updateWorkWeekConfig = useUpdateWorkWeekConfigMutation();
  const theme = useTheme();
  const { open: openConfirmDialog, close: closeConfirmDialog, isOpen: isConfirmDialogOpen } = useDialog();
  const [attemptedCloseWhileDirty, setAttemptedCloseWhileDirty] = useState(false);
  const [formData, setFormData] = useState<WorkWeekConfigBodySchema>();

  const {
    data,
    isLoading: loadingWorkWeekStart,
    isFetching,
    refetch,
  } = useWorkWeekConfigQuery(user.fullyAuthenticated === 'authenticated');

  const workWeeks = [
    { label: t('weekDay.sunday'), id: 'SUNDAY', value: 'Sunday' },
    { label: t('weekDay.monday'), id: 'MONDAY', value: 'Monday' },
    { label: t('weekDay.tuesday'), id: 'TUESDAY', value: 'Tuesday' },
    { label: t('weekDay.wednesday'), id: 'WEDNESDAY', value: 'Wednesday' },
    { label: t('weekDay.thursday'), id: 'THURSDAY', value: 'Thursday' },
    { label: t('weekDay.friday'), id: 'FRIDAY', value: 'Friday' },
    { label: t('weekDay.saturday'), id: 'SATURDAY', value: 'Saturday' },
  ];

  const validationSchema = yup.object({
    workdayStartTime: yup.string().required(),
    workweekStartDay: yup.string().required().nullable(),
  });
  const resolver = useYupValidationResolver(validationSchema);

  const workweekApiData = data as WorkWeekConfigResponse;

  const formContext = useForm<WorkWeekConfigBodySchema>({
    resolver,
    defaultValues: {
      workdayStartTime: '03:00',
      workweekStartDay: t('weekDay.wednesday'),
    },
  });

  const { setSnackbarState } = useSnackbar();
  const {
    watch,
    formState: { isDirty },
    reset,
  } = formContext;

  useEffect(() => {
    reset({
      workdayStartTime: dayjs(workweekApiData?.workdayStartTime, 'HH:mm').format('HH:mm'),
      workweekStartDay: workweekApiData?.dayOfWeekStart,
    });
  }, [workweekApiData, reset]);

  useEffect(() => {
    if (user.org?.bslId) {
      refetch();
    }
  }, [user.org?.bslId, refetch]);

  const watchWorkDayStartTime = watch('workdayStartTime');
  const watchWorkWeekStartDay = watch('workweekStartDay');

  const workdayStartTimeField: FieldValues = {
    name: 'workdayStartTime',
    required: true,
    variant: 'time',
    gridProps: { xs: 12, lg: 12, md: 12 },
    dataTestId: 'WorkdayStartTme',
    isInputComplete: !!watchWorkDayStartTime,
    lightMode: theme.palette.mode === 'light',
    disabled: !isAdminPersona,
  };

  const workweekStartDayField: FieldValues = {
    name: 'workweekStartDay',
    required: true,
    variant: 'autocomplete',
    gridProps: { xs: 12, lg: 12, md: 12 },
    dataTestId: 'WorkWeekStartDay-autocomplete-textfield',
    isInputComplete: !!watchWorkWeekStartDay,
    lightMode: theme.palette.mode === 'light',
    options: workWeeks,
    disabled: !isAdminPersona,
  };

  const onClickConfirm = () => {
    setAttemptedCloseWhileDirty(false);
    const workweekData = workweekApiData;
    reset({
      workdayStartTime: dayjs(workweekData?.workdayStartTime, 'HH:mm').format('HH:mm'),
      workweekStartDay: workweekData?.dayOfWeekStart,
    });
  };

  const onClickCancel = () => {
    setAttemptedCloseWhileDirty(false);
  };

  const handleOnError = () => {
    closeConfirmDialog();
    setSnackbarState({
      open: true,
      message: `${t('laborSettingsForSchedule.workweekConfigSubmitError')}`,
      color: 'error',
    });
  };

  const handleSubmitForm = async () => {
    const mutateOptions = {
      onSuccess: () => {
        setSnackbarState({
          open: true,
          message: `${t('labor.formSuccessMessage')}`,
          color: 'success',
        });
      },
      onError: handleOnError,
    };

    await updateWorkWeekConfig.mutateAsync(
      {
        payload: {
          workweekStartDay: formData?.workweekStartDay ?? '',
          workdayStartTime: formData?.workdayStartTime ?? '',
        },
      },
      mutateOptions
    );
    queryClient.invalidateQueries(queryKeys.schedules.dayOfWeekStart);
  };

  const submitForm = async () => {
    await handleSubmitForm();
    closeConfirmDialog();
  };
  const attemptDiscardChanges = () => {
    setAttemptedCloseWhileDirty(true);
  };

  return (
    <Box data-testid='labor-settings-schedule' marginLeft={4} marginTop={3}>
      <Grid xs={12} item sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        {isFetching || loadingWorkWeekStart ? (
          <Grid item xs={12}>
            <Skeleton variant='rounded' height={32} width={400} />
          </Grid>
        ) : (
          <Grid minWidth={120} width={800}>
            <Header
              maxDescriptionWidth='600px'
              title={t('labor.ScheduleSettingHeader')}
              dataTestId='labor-schedule-setting-header'
            />
          </Grid>
        )}
        <Grid sx={{ marginRight: '1.5rem' }}>
          <AuditStamp entity={data as WorkWeekConfigResponse} />
        </Grid>
      </Grid>
      {isFetching || loadingWorkWeekStart ? (
        <Grid item xs={12} marginTop={5}>
          <Skeleton variant='rounded' height={60} width={800} />
        </Grid>
      ) : (
        <FormContainer
          formContext={formContext}
          onSuccess={async (submittedFormData: WorkWeekConfigBodySchema) => {
            setFormData(submittedFormData);
            openConfirmDialog();
          }}
        >
          <Grid container spacing={2} mb={2} justifyContent='flex-start'>
            {loadingWorkWeekStart ? (
              <Grid item xs={12}>
                <Skeleton variant='rounded' height={32} width={400} />
              </Grid>
            ) : (
              <>
                <Grid item xs={3} lg={3} mb={2} md={3.5} justifyContent='flex-start' sx={{ marginTop: '2rem' }}>
                  {createFormTextField(workdayStartTimeField, { t, tKey: 'labor' })}
                </Grid>
                <Grid item xs={4} lg={7} md={5} sx={{ marginTop: '3.7rem', marginLeft: '1rem' }}>
                  <Typography
                    sx={{
                      fontWeight: '400',
                    }}
                    data-testid='desc-For-workDayStartTime'
                  >
                    {t('laborSettingsForSchedule.descForWorkDayStartTime')}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container spacing={2} mb={2} justifyContent='flex-start'>
            <Grid item xs={3} lg={3} md={3.5} justifyContent='flex-start'>
              {createFormTextField(workweekStartDayField, { t, tKey: 'labor' })}
            </Grid>
            <Grid item xs={4} md={5} lg={7} sx={{ marginTop: '1.6rem', marginLeft: '1rem' }}>
              <Typography
                sx={{
                  fontWeight: '400',
                }}
                data-testid='desc-For-workWeekStartDay'
              >
                {t('laborSettingsForSchedule.descForWorkWeekStartDay')}
              </Typography>
            </Grid>
          </Grid>
          {isDirty && (
            <FormFooter
              loading={updateWorkWeekConfig.isLoading}
              buttons={[
                { onClick: attemptDiscardChanges, dataTestId: 'close-button', name: t('buttonText.discardChanges') },
                {
                  dataTestId: 'save-button',
                  submitType: true,
                },
              ]}
            />
          )}

          <LaborSettingsScheduleChangeWarning
            open={isConfirmDialogOpen}
            onCancel={closeConfirmDialog}
            onConfirm={submitForm}
          />
          <UnsavedFormDataPrompt
            open={attemptedCloseWhileDirty}
            onConfirm={onClickConfirm}
            onCancel={onClickCancel}
            when={isDirty}
            navigate={(path) => history.push(path)}
            shouldBlockNavigation={(location) => isDirty}
          />
        </FormContainer>
      )}
    </Box>
  );
}
