import {
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridFilterItem,
  GridFilterModel,
  GridRenderEditCellParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarExportContainer,
  GridExportMenuItemProps,
} from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import { SxProps, useTheme } from '@mui/material/styles';
import { FeatureFlag } from '@cbo/shared-library';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';

import { useTranslation } from 'react-i18next';

import { useFeatureFlag } from '../../utils/hooks/useFeatureFlag';
import { quickFilter } from './styles';

export const numberComparator = (total1: string, total2: string) => {
  const parsedTotal1 = parseFloat(total1);
  const parsedTotal2 = parseFloat(total2);
  const isTotal1NaN = Number.isNaN(parsedTotal1);
  const isTotal2NaN = Number.isNaN(parsedTotal2);
  if (isTotal1NaN && isTotal2NaN) {
    return 0;
  }
  if (isTotal1NaN) {
    return -1;
  }
  if (isTotal2NaN) {
    return 1;
  }
  return parsedTotal1 - parsedTotal2;
};

export const dateComparator = (date1: string, date2: string) => new Date(date1).getTime() - new Date(date2).getTime();

export const stringParser = (length: number) => (value: string) => value.slice(0, length);

export const numberParser = (max: number | undefined, allowNegatives: boolean) => (value: string) => {
  if (value === '') return '';
  if (allowNegatives && value === '-') return '-';
  if (value === '.') return '.';

  const regexValue = value.replace(allowNegatives ? /[^\d.-]/g : /[^\d.]/g, '');

  const negative = regexValue.charAt(0) === '-' ? '-' : '';
  const parsedValue = parseFloat(negative ? regexValue.substring(1) : regexValue);

  if (Number.isNaN(parsedValue)) return negative;

  let wholeNumbers = Math.floor(parsedValue);
  wholeNumbers = max && wholeNumbers > max ? wholeNumbers / 10 : wholeNumbers;

  const decimalSplit = regexValue.split('.');
  const decimals = decimalSplit.length > 1 ? decimalSplit[decimalSplit.length - 1].substring(0, 2) : '';

  return `${negative}${Math.trunc(wholeNumbers)}${regexValue.includes('.') ? '.' : ''}${decimals}`;
};

export interface CustomEditableCellProps extends GridRenderEditCellParams {
  hasError?: boolean;
  dataTestId?: string;
  handleValue?: (value: unknown) => void;
  wrapperStyles?: SxProps;
  inputStyles?: SxProps;
  valueOptions?: string[];
}

export interface CustomEditableInputCellProps extends CustomEditableCellProps {
  max?: number;
  autoFocus?: boolean;
  tooltip?: string;
}

export interface DisableProps {
  hideToolbar: boolean | undefined;
  disableColumnSelector: boolean | undefined;
  disableColumnFilter: boolean | undefined;
  disableDensitySelector: boolean | undefined;
  disableExport: boolean | undefined;
  disableQuickFilter: boolean | undefined;
  showExport?: boolean;
  isExportLoading?: boolean;
  exportCSV?: () => void;
}

// Note - the DataGridColumnType and DataGridOperator types below are derived from MUI's column definitions https://github.com/mui/mui-x/tree/master/packages/grid/x-data-grid/src/colDef
export type DataGridColumnType =
  | 'string'
  | 'date'
  | 'dateTime'
  | 'number'
  | 'singleSelect'
  | 'actions'
  | 'boolean'
  | 'checkboxSelection';

export type DataGridOperator =
  | 'is'
  | 'not'
  | 'after'
  | 'onOrAfter'
  | 'before'
  | 'onOrBefore'
  | 'isEmpty'
  | 'isNotEmpty'
  | '='
  | '!='
  | '>'
  | '>='
  | '<'
  | '<='
  | 'isAnyOf'
  | 'contains'
  | 'equals'
  | 'startsWith'
  | 'endsWith';

// eslint-disable-next-line react/require-default-props
function CsvMenuItem(props: GridExportMenuItemProps<Record<string, unknown>> & { exportCsv?: () => void }) {
  const { t } = useTranslation();

  const { hideMenu, exportCsv } = props;

  return (
    <MenuItem
      data-testid='export-csv'
      onClick={() => {
        hideMenu?.();
        if (exportCsv) {
          exportCsv();
        }
      }}
    >
      {t('dataGrid.csvExport')}
    </MenuItem>
  );
}

// Function to disable or enable the options for the toolbar. Unfortunately there is no support to hide the "export" option, so this is the workaround.
export function Toolbar(disableProps: DisableProps) {
  const theme = useTheme();
  const uiKit = useFeatureFlag(FeatureFlag.restaurantsUiKit);
  const {
    hideToolbar,
    disableColumnSelector,
    disableColumnFilter,
    disableDensitySelector,
    disableQuickFilter,
    showExport,
    isExportLoading,
    exportCSV,
  } = disableProps;
  if (hideToolbar) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  return (
    <GridToolbarContainer data-testid='toolbar-container' sx={{ margin: '8x' }}>
      <>
        {disableColumnSelector ? null : <GridToolbarColumnsButton />}
        {disableColumnFilter ? null : <GridToolbarFilterButton />}
        {disableDensitySelector ? null : <GridToolbarDensitySelector />}
        {/* Hiding export for now since it doesn't work with pagination */}
        {/* {disableExport ? null : <GridToolbarExport />} */}
        {/* Added showExport props for export button with csv option */}
        {showExport && !isExportLoading && (
          <GridToolbarExportContainer>
            {/* <MenuItem data-testid='export-csv' onClick={exportCSV}>
              {t('dataGrid.csvExport')}
            </MenuItem> */}
            <CsvMenuItem exportCsv={exportCSV} />
          </GridToolbarExportContainer>
        )}
        {showExport && isExportLoading && <CircularProgress size={16} />}
        {/* required to position searchbar on the right of container by default */}
        <Box sx={{ flex: 1 }} />
        {disableQuickFilter ? null : (
          <GridToolbarQuickFilter
            data-testid='quick-search'
            quickFilterParser={(searchInput) => searchInput.split(' ').map((value) => value)}
            variant={uiKit ? 'outlined' : 'standard'}
            sx={uiKit ? quickFilter(theme) : undefined}
          />
        )}
      </>
    </GridToolbarContainer>
  );
}

export const transformSortModel = (sortModel: GridSortModel) =>
  sortModel[0] ? `${sortModel[0].field}:${sortModel[0].sort}` : undefined;

export const isValidOperatorValue = (item: GridFilterItem) => {
  switch (item.operator) {
    case '=':
    case '!=':
    case '<':
    case '>':
    case '<=':
    case '>=':
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(Number(item.value));
    default:
      return true;
  }
};

export const transformFilterOperator = (item: GridFilterItem) => {
  if (!isValidOperatorValue(item) || !item.value) return '';

  let newOperator = '';

  switch (item.operator) {
    case 'is':
    case '=':
    case 'equals':
      newOperator = 'eq'; // TODO, move these filters from the nest-library to shared-lib so we can reference them here
      break;
    case 'not':
    case '!=':
      newOperator = 'neq';
      break;
    case 'before':
    case '<':
      newOperator = 'lt';
      break;
    case 'after':
    case '>':
      newOperator = 'gt';
      break;
    case 'onOrBefore':
    case '<=':
      newOperator = 'lte';
      break;
    case 'onOrAfter':
    case '>=':
      newOperator = 'gte';
      break;
    case 'contains':
    default:
      newOperator = 'ciin';
  }

  return `${item.field}:${newOperator}:${item.value}`;
};

export const getFilterOperators = (type: DataGridColumnType, filters: DataGridOperator[]) => {
  switch (type) {
    case 'date':
      return getGridDateOperators().filter((operator) => filters.indexOf(operator.value as DataGridOperator) !== -1);
    case 'dateTime':
      return getGridDateOperators(true).filter(
        (operator) => filters.indexOf(operator.value as DataGridOperator) !== -1
      );
    case 'number':
      return getGridNumericOperators().filter((operator) => filters.indexOf(operator.value as DataGridOperator) !== -1);
    case 'singleSelect':
      return getGridSingleSelectOperators().filter(
        (operator) => filters.indexOf(operator.value as DataGridOperator) !== -1
      );
    case 'boolean':
      return getGridBooleanOperators().filter((operator) => filters.indexOf(operator.value as DataGridOperator) !== -1);
    case 'string':
    default:
      return getGridStringOperators().filter((operator) => filters.indexOf(operator.value as DataGridOperator) !== -1);
  }
};

/**
 * Parses a GridFilterModel into an array filters that can be handled by server-side filtering
 * @param {GridFilterModel} filterModel Model describing the filters to apply to the grid
 * @param {string} quickFilterColumn The column that will be used for the quick filter (with the ciin operator)
 * @param {any} [mapFilterFn] A function that checks to map a filter value to a different value that is expected by the API
 * @returns {string[]}
 */
export const parseFilters = (
  filterModel: GridFilterModel,
  quickFilterColumn: string,
  mapFilterFn?: (item: GridFilterItem) => GridFilterItem
): string[] => {
  const newFilters: string[] = [];

  // handle column filters
  filterModel.items.forEach((item) => {
    let filterItem = item;
    if (mapFilterFn) filterItem = mapFilterFn(item);

    newFilters.push(transformFilterOperator(filterItem));
  });

  // handle quick filter
  if (filterModel.quickFilterValues?.length) {
    newFilters.push(`${quickFilterColumn}:ciin:${filterModel.quickFilterValues.join(' ')}`);
  }
  return newFilters.filter((f) => !!f);
};
