import { LoginCallback } from '@okta/okta-react';
import { Route, Switch as RouterSwitch } from 'react-router-dom';
import SplashScreen from '../../shared/components/SplashScreen/SplashScreen';
import routes from '../../shared/constants/routes';
import Shell from '../Shell';

export interface LoginRouterProps {
  apiRoot: string;
}

export default function LoginRouter(props: LoginRouterProps) {
  const { apiRoot } = props;

  return (
    <RouterSwitch>
      <Route path={routes.LOGIN}>
        <LoginCallback />
      </Route>

      <Route>
        <SplashScreen>
          <Shell apiRoot={apiRoot} />
        </SplashScreen>
      </Route>
    </RouterSwitch>
  );
}
