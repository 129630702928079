import dayjs from 'dayjs';
import isSameorAfter from 'dayjs/plugin/isSameOrAfter';
import isSameorBefore from 'dayjs/plugin/isSameOrBefore';
import { PayrollFrequency, WeekDay, Period, Prior, Same } from '@cbo/shared-library';
import {
  FiscalCalendar,
  FiscalCalendarPreview,
  FiscalCalendarPreviewRow,
  PayrollCalendarFilters,
  IPeriodFilter,
} from '@cbo/shared-library/response/calendar.response';

dayjs.extend(isSameorAfter);
dayjs.extend(isSameorBefore);

const firstDayOfWeek: {
  [monthIndex: number]: string;
} = {
  [WeekDay.SUNDAY]: 'sunday',
  [WeekDay.MONDAY]: 'monday',
  [WeekDay.TUESDAY]: 'tuesday',
  [WeekDay.WEDNESDAY]: 'wednesday',
  [WeekDay.THURSDAY]: 'thursday',
  [WeekDay.FRIDAY]: 'friday',
  [WeekDay.SATURDAY]: 'saturday',
};

export const fiscalCalendarHeader: FiscalCalendarPreviewRow = {
  fiscalQuarter: 'fiscalQuarter',
  fiscalPeriod: 'fiscalPeriod',
  fiscalWeek: 'fiscalWeek',
  day1: '',
  day2: '',
  day3: '',
  day4: '',
  day5: '',
  day6: '',
  day7: '',
};
/**
 * calculates fiscal calendar start date
 */
const getfiscalCalendarStartDate = (fiscalCalendar: FiscalCalendar, currentYear: number) => {
  const calendarDate = dayjs(`${currentYear}-${fiscalCalendar.month + 1}-${fiscalCalendar.day}`);
  if (calendarDate.day() <= fiscalCalendar.firstDayOfWeek) {
    if (fiscalCalendar.firstDayOfWeek - calendarDate.day() <= 3) {
      return dayjs(calendarDate).add(fiscalCalendar.firstDayOfWeek - calendarDate.day(), 'day');
    }
    return dayjs(calendarDate).subtract(
      Object.keys(firstDayOfWeek).length - (fiscalCalendar.firstDayOfWeek - calendarDate.day()),
      'day'
    );
  }
  if (calendarDate.day() - fiscalCalendar.firstDayOfWeek <= 3) {
    return dayjs(calendarDate).subtract(calendarDate.day() - fiscalCalendar.firstDayOfWeek, 'day');
  }
  return dayjs(calendarDate).add(
    Object.keys(firstDayOfWeek).length - (calendarDate.day() - fiscalCalendar.firstDayOfWeek),
    'day'
  );
};

/**
 * This function generates fiscal calendar preview data based on calendar configuration for current year.
 */
export const getFiscalCalendarPreview = (fiscalCalendar: FiscalCalendar, currentYear: number) => {
  const fiscalCalendarData: FiscalCalendarPreviewRow[] = [];
  const fiscalCalendarPreviewData: FiscalCalendarPreview = {
    header: {
      fiscalQuarter: 'fiscalQuarter',
      fiscalPeriod: 'fiscalPeriod',
      fiscalWeek: 'fiscalWeek',
      day1: firstDayOfWeek[fiscalCalendar.firstDayOfWeek % 7],
      day2: firstDayOfWeek[(fiscalCalendar.firstDayOfWeek + WeekDay.MONDAY) % 7],
      day3: firstDayOfWeek[(fiscalCalendar.firstDayOfWeek + WeekDay.TUESDAY) % 7],
      day4: firstDayOfWeek[(fiscalCalendar.firstDayOfWeek + WeekDay.WEDNESDAY) % 7],
      day5: firstDayOfWeek[(fiscalCalendar.firstDayOfWeek + WeekDay.THURSDAY) % 7],
      day6: firstDayOfWeek[(fiscalCalendar.firstDayOfWeek + WeekDay.FRIDAY) % 7],
      day7: firstDayOfWeek[(fiscalCalendar.firstDayOfWeek + WeekDay.SATURDAY) % 7],
    },
    rows: fiscalCalendarData,
  };
  let fiscalStartDate = getfiscalCalendarStartDate(fiscalCalendar, currentYear);
  const endDate = dayjs(fiscalStartDate).add(1, 'year');
  let fiscalQuarter = 1;
  let fiscalPeriod = 1;
  let fiscalWeek = 1;
  let eachPattern = 1;
  let paternIndex = 0;
  while (fiscalStartDate.isBefore(endDate)) {
    const periodEnd = fiscalStartDate.add(7, 'days');
    if (periodEnd.isAfter(endDate)) {
      break;
    }
    if (dayjs(fiscalStartDate).diff(dayjs(periodEnd), 'year') > 0) {
      fiscalQuarter = 1;
      fiscalPeriod = 1;
      fiscalWeek = 1;
    }
    fiscalCalendarData.push({
      fiscalQuarter: `${fiscalQuarter > 9 ? '' : '0'}${fiscalQuarter}`,
      fiscalPeriod: `${fiscalPeriod > 9 ? '' : '0'}${fiscalPeriod}`,
      fiscalWeek: `${fiscalWeek > 9 ? '' : '0'}${fiscalWeek}`,
      day1: dayjs(fiscalStartDate).format('YYYY-MM-DD'),
      day2: dayjs(fiscalStartDate).add(1, 'day').format('YYYY-MM-DD'),
      day3: dayjs(fiscalStartDate).add(2, 'day').format('YYYY-MM-DD'),
      day4: dayjs(fiscalStartDate).add(3, 'day').format('YYYY-MM-DD'),
      day5: dayjs(fiscalStartDate).add(4, 'day').format('YYYY-MM-DD'),
      day6: dayjs(fiscalStartDate).add(5, 'day').format('YYYY-MM-DD'),
      day7: dayjs(fiscalStartDate).add(6, 'day').format('YYYY-MM-DD'),
    });
    fiscalWeek += 1;
    if (fiscalCalendar.monthPattern) {
      const pattern = fiscalCalendar.monthPattern.split('-');
      eachPattern += 1;
      if (paternIndex === pattern.length) {
        paternIndex = 0;
      }
      if (eachPattern % parseInt(pattern[paternIndex], 10) === 1) {
        fiscalPeriod += 1;
        paternIndex += 1;
        eachPattern = 1;
      }
      fiscalQuarter = fiscalWeek % 13 === 1 ? fiscalQuarter + 1 : fiscalQuarter;
    } else {
      fiscalPeriod = fiscalWeek % 4 === 1 ? fiscalPeriod + 1 : fiscalPeriod;
      fiscalQuarter = fiscalWeek % 12 === 1 && fiscalQuarter < 4 ? fiscalQuarter + 1 : fiscalQuarter;
    }
    fiscalStartDate = periodEnd;
  }
  return fiscalCalendarPreviewData;
};

export const getFrequencyDays = (frequency: PayrollFrequency) => {
  if (frequency === PayrollFrequency.BI_WEEKLY) {
    return 14;
  }
  if (frequency === PayrollFrequency.WEEKLY) {
    return 7;
  }
  return 16;
};

/**
 * This function generates payroll calendar data based on start date and frequency about 5 years.
 */
export const getPayrollCalendarFilters = (
  siteId: string,
  payrollStartDate: string | Date,
  frequency: PayrollFrequency,
  curentDate: string | Date
) => {
  const payrollCalendarData: PayrollCalendarFilters[] = [];
  const frequencyDays = getFrequencyDays(frequency);
  const payrollCalendarEndDate = dayjs(payrollStartDate).add(5, 'year');
  let employeePayrollStartDate = dayjs(payrollStartDate);
  while (employeePayrollStartDate.isBefore(payrollCalendarEndDate)) {
    let periodEnd = employeePayrollStartDate.add(frequencyDays - 1, 'days');
    if (periodEnd.isAfter(payrollCalendarEndDate)) {
      periodEnd = payrollCalendarEndDate;
    }
    payrollCalendarData.push({
      siteId,
      calendarDate: { value: dayjs(curentDate).format('YYYY-MM-DD') },
      payrollFrequency: frequency,
      payPeriodStartDate: { value: employeePayrollStartDate.format('YYYY-MM-DD') },
      payPeriodEndDate: { value: periodEnd.format('YYYY-MM-DD') },
      previousPayPeriodStartDate: {
        value: dayjs(employeePayrollStartDate).subtract(frequencyDays, 'day').format('YYYY-MM-DD'),
      },
      previousPayPeriodEndDate: { value: dayjs(employeePayrollStartDate).subtract(1, 'day').format('YYYY-MM-DD') },
      payrollCalendarHierarchyType: 'Site',
      payPeriodStartDateSameDayPriorYear: { value: employeePayrollStartDate.subtract(1, 'year').format('YYYY-MM-DD') },
      payPeriodEndDateSameDayPriorYear: { value: periodEnd.subtract(1, 'year').format('YYYY-MM-DD') },
      previousPayPeriodStartDateSameDayPriorYear: {
        value: dayjs(employeePayrollStartDate).subtract(frequencyDays, 'day').subtract(1, 'year').format('YYYY-MM-DD'),
      },
      previousPayPeriodEndDateSameDayPriorYear: {
        value: dayjs(employeePayrollStartDate).subtract(1, 'day').subtract(1, 'year').format('YYYY-MM-DD'),
      },
    });
    employeePayrollStartDate = periodEnd.add(1, 'day');
  }
  const payrollCalendarFilters = payrollCalendarData.filter(
    (payrollDates) =>
      dayjs(curentDate).isSameOrAfter(dayjs(payrollDates?.payPeriodStartDate?.value), 'day') &&
      dayjs(curentDate).isSameOrBefore(dayjs(payrollDates?.payPeriodEndDate?.value), 'day')
  )[0];
  return payrollCalendarFilters;
};

const generatePeriod = (startOffset: number, endOffset: number, yearOffset = 0) => ({
  startDate: dayjs().add(startOffset, 'week').startOf('week').subtract(yearOffset, 'year').format('YYYY-MM-DD'),
  endDate: dayjs().add(endOffset, 'week').endOf('week').subtract(yearOffset, 'year').format('YYYY-MM-DD'),
});

const generateMonthPeriod = (monthOffset: number, yearOffset = 0) => ({
  startDate: dayjs().subtract(monthOffset, 'month').startOf('month').subtract(yearOffset, 'year').format('YYYY-MM-DD'),
  endDate: dayjs().subtract(monthOffset, 'month').endOf('month').subtract(yearOffset, 'year').format('YYYY-MM-DD'),
});

const generateYearPeriod = (yearOffset: number) => ({
  startDate: dayjs().subtract(yearOffset, 'year').startOf('year').format('YYYY-MM-DD'),
  endDate: dayjs().subtract(yearOffset, 'year').endOf('year').format('YYYY-MM-DD'),
});
const getDateRange = (unit: dayjs.OpUnitType) => ({
  key: Prior.YEAR,
  startDate: {
    key: Same.DATE,
    value: dayjs().startOf(unit).subtract(1, 'year').format('YYYY-MM-DD'),
  },
  endDate: {
    key: Same.DATE,
    value: dayjs().endOf(unit).subtract(1, 'year').format('YYYY-MM-DD'),
  },
  startDay: {
    key: Same.DAY,
    value: dayjs().startOf(unit).subtract(1, 'year').day(dayjs().startOf(unit).day()).format('YYYY-MM-DD'),
  },
  endDay: {
    key: Same.DAY,
    value: dayjs().endOf(unit).subtract(1, 'year').day(dayjs().endOf(unit).day()).format('YYYY-MM-DD'),
  },
});

export const getFiscalCalendarPeriodsFilters = (fiscalCalender: FiscalCalendar) => {
  const { day } = fiscalCalender;
  const firstDay = fiscalCalender.firstDayOfWeek;
  const startFiscaldate = getfiscalCalendarStartDate(fiscalCalender, dayjs().get('year'));
  const endFiscalDate =
    startFiscaldate.add(1, 'year').day() < firstDay - 1
      ? startFiscaldate.add(1, 'year').subtract(1, 'week').day(firstDay)
      : startFiscaldate.add(1, 'year').day(firstDay - 1);
  const previousFiscalStartDate =
    startFiscaldate.subtract(1, 'year').day() > firstDay
      ? startFiscaldate.subtract(1, 'year').add(1, 'week').day(firstDay)
      : startFiscaldate.subtract(1, 'year').day(firstDay);
  const previousFiscalEndDate = startFiscaldate.subtract(1, 'day');
  const lastWeek = dayjs().subtract(1, 'week');
  const periods: IPeriodFilter[] = [
    {
      current: {
        key: Period.TODAY,
        date: dayjs().format('YYYY-MM-DD'),
      },
      prior: {
        key: Prior.PERIOD,
        date: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      },
      priorYear: {
        key: Prior.YEAR,
        date: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        day: dayjs().subtract(1, 'year').day(dayjs().day()).format('YYYY-MM-DD'),
      },
    },
    {
      current: {
        key: Period.YESTERDAY,
        date: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      },
      prior: {
        key: Prior.PERIOD,
        date: dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
      },
      priorYear: {
        key: Prior.YEAR,
        date: dayjs(dayjs().subtract(1, 'day')).subtract(1, 'year').format('YYYY-MM-DD'),
        day: dayjs().subtract(1, 'year').day(dayjs().day()).format('YYYY-MM-DD'),
      },
    },
    {
      current: {
        key: Period.CALENDAR_WEEK_END,
        ...generatePeriod(0, 0),
      },
      prior: {
        key: Prior.PERIOD,
        ...generatePeriod(-1, -1),
      },
      priorYear: {
        key: Prior.YEAR,
        startDate: {
          key: Same.DATE,
          value: dayjs().startOf('week').subtract(1, 'year').format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: dayjs().endOf('week').subtract(1, 'year').format('YYYY-MM-DD'),
        },
        startDay: {
          key: Same.DAY,
          value: dayjs().subtract(1, 'year').startOf('week').format('YYYY-MM-DD'),
        },
        endDay: {
          key: Same.DAY,
          value: dayjs().subtract(1, 'year').endOf('week').format('YYYY-MM-DD'),
        },
      },
    },
    {
      current: {
        key: Period.LAST_CALENDAR_WEEK_END,
        ...generatePeriod(-1, -1),
      },
      prior: {
        key: Prior.PERIOD,
        ...generatePeriod(-2, -2),
      },
      priorYear: {
        key: Prior.YEAR,
        startDate: {
          key: Same.DATE,
          value: lastWeek.startOf('week').subtract(1, 'year').format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: lastWeek.endOf('week').subtract(1, 'year').format('YYYY-MM-DD'),
        },
        startDay: {
          key: Same.DAY,
          value: lastWeek.subtract(1, 'year').startOf('week').format('YYYY-MM-DD'),
        },
        endDay: {
          key: Same.DAY,
          value: lastWeek.subtract(1, 'year').endOf('week').format('YYYY-MM-DD'),
        },
      },
    },
    {
      current: {
        key: Period.CALENDAR_MONTH_END,
        ...generateMonthPeriod(0),
      },
      prior: {
        key: Prior.PERIOD,
        ...generateMonthPeriod(1),
      },
      priorYear: {
        ...getDateRange('month'),
      },
    },
    {
      current: {
        key: Period.LAST_CALENDAR_MONTH_END,
        ...generateMonthPeriod(1),
      },
      prior: {
        key: Prior.PERIOD,
        ...generateMonthPeriod(2),
      },
      priorYear: {
        key: Prior.YEAR,
        startDate: {
          key: Same.DATE,
          value: dayjs().subtract(1, 'month').subtract(1, 'year').startOf('month').format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: dayjs().subtract(1, 'month').subtract(1, 'year').endOf('month').format('YYYY-MM-DD'),
        },
        startDay: {
          key: Same.DAY,
          value: dayjs()
            .startOf('month')
            .subtract(1, 'month')
            .subtract(1, 'year')
            .day(dayjs().subtract(1, 'month').startOf('month').day())
            .format('YYYY-MM-DD'),
        },
        endDay: {
          key: Same.DAY,
          value: dayjs()
            .endOf('month')
            .subtract(1, 'month')
            .subtract(1, 'year')
            .day(dayjs().subtract(1, 'month').endOf('month').day())
            .format('YYYY-MM-DD'),
        },
      },
    },
    {
      current: {
        key: Period.CALENDAR_YEAR_END,
        ...generateYearPeriod(0),
      },
      prior: {
        key: Prior.PERIOD,
        ...generateYearPeriod(1),
      },
      priorYear: {
        ...getDateRange('year'),
      },
    },
    {
      current: {
        key: Period.LAST_CALENDAR_YEAR_END,
        ...generateYearPeriod(1),
      },
      prior: {
        key: Prior.PERIOD,
        ...generateYearPeriod(2),
      },
      priorYear: {
        key: Prior.YEAR,
        startDate: {
          key: Same.DATE,
          value: dayjs().subtract(2, 'year').startOf('year').format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: dayjs().subtract(2, 'year').endOf('year').format('YYYY-MM-DD'),
        },
        startDay: {
          key: Same.DAY,
          value: dayjs()
            .startOf('year')
            .subtract(2, 'year')
            .day(dayjs().subtract(1, 'year').startOf('year').day())
            .format('YYYY-MM-DD'),
        },
        endDay: {
          key: Same.DAY,
          value: dayjs()
            .endOf('year')
            .subtract(2, 'year')
            .day(dayjs().subtract(1, 'year').endOf('year').day())
            .format('YYYY-MM-DD'),
        },
      },
    },
    {
      current: {
        key: Period.FISCAL_WEEK_END,
        startDate: dayjs().day(firstDay).format('YYYY-MM-DD'),
        endDate: dayjs().day(firstDay).add(6, 'days').format('YYYY-MM-DD'),
      },
      prior: {
        key: Prior.PERIOD,
        startDate: {
          key: Same.DATE,
          value: dayjs().day(firstDay).subtract(1, 'week').format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: dayjs().day(firstDay).subtract(1, 'day').format('YYYY-MM-DD'),
        },
      },
      priorYear: {
        key: Prior.YEAR,
        startDate: {
          key: Same.DATE,
          value: dayjs().day(firstDay).subtract(1, 'year').format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: dayjs().day(firstDay).add(6, 'days').subtract(1, 'year').format('YYYY-MM-DD'),
        },
        startDay: {
          key: Same.DAY,
          value:
            dayjs().day(firstDay).subtract(1, 'year').day() > firstDay
              ? dayjs().day(firstDay).subtract(1, 'year').add(1, 'week').day(firstDay).format('YYYY-MM-DD')
              : dayjs().day(firstDay).subtract(1, 'year').day(firstDay).format('YYYY-MM-DD'),
        },
        endDay: {
          key: Same.DAY,
          value:
            dayjs()
              .day(firstDay - 1)
              .subtract(1, 'year')
              .day() >
            firstDay - 1
              ? dayjs()
                  .day(firstDay - 1)
                  .subtract(1, 'year')
                  .add(2, 'week')
                  .day(firstDay - 1)
                  .format('YYYY-MM-DD')
              : dayjs()
                  .day(firstDay - 1)
                  .subtract(1, 'year')
                  .day(firstDay)
                  .format('YYYY-MM-DD'),
        },
      },
    },

    {
      current: {
        key: Period.LAST_FISCAL_WEEK_END,
        startDate: dayjs().day(firstDay).subtract(1, 'week').format('YYYY-MM-DD'),
        endDate: dayjs().day(firstDay).subtract(1, 'day').format('YYYY-MM-DD'),
      },
      prior: {
        key: Prior.PERIOD,
        startDate: {
          key: Same.DATE,
          value: dayjs().day(firstDay).subtract(2, 'week').format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: dayjs().day(firstDay).subtract(1, 'day').subtract(1, 'week').format('YYYY-MM-DD'),
        },
      },
      priorYear: {
        key: Prior.YEAR,
        startDate: {
          key: Same.DATE,
          value: dayjs().day(firstDay).subtract(1, 'week').subtract(1, 'year').format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: dayjs().day(firstDay).subtract(1, 'day').subtract(1, 'year').format('YYYY-MM-DD'),
        },
        startDay: {
          key: Same.DAY,
          value: dayjs().day(firstDay).subtract(1, 'year').day(firstDay).format('YYYY-MM-DD'),
        },
        endDay: {
          key: Same.DAY,
          value:
            dayjs().day(firstDay).subtract(1, 'year').day() < firstDay
              ? dayjs()
                  .day(firstDay)
                  .subtract(1, 'year')
                  .add(2, 'week')
                  .day(firstDay)
                  .add(6, 'days')
                  .format('YYYY-MM-DD')
              : dayjs().day(firstDay).subtract(1, 'year').day(firstDay).add(6, 'days').format('YYYY-MM-DD'),
        },
      },
    },

    {
      current: {
        key: Period.FISCAL_MONTH_END,
        startDate: dayjs().date(day).day(firstDay).format('YYYY-MM-DD'),
        endDate: dayjs().date(day).day(firstDay).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD'),
      },
      prior: {
        key: Prior.PERIOD,
        startDate: {
          key: Same.DATE,
          value: dayjs().date(day).subtract(1, 'month').day(firstDay).format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: dayjs().date(day).day(firstDay).subtract(1, 'day').format('YYYY-MM-DD'),
        },
      },
      priorYear: {
        key: Prior.YEAR,
        startDate: {
          key: Same.DATE,
          value: dayjs().date(day).day(firstDay).subtract(1, 'year').format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: dayjs()
            .date(day)
            .day(firstDay)
            .subtract(1, 'year')
            .add(1, 'month')
            .date(day)
            .day(firstDay)
            .format('YYYY-MM-DD'),
        },
        startDay: {
          key: Same.DAY,
          value:
            dayjs().date(day).subtract(1, 'year').day() < day
              ? dayjs().date(day).subtract(1, 'year').date(day).day(firstDay).format('YYYY-MM-DD')
              : dayjs().date(day).subtract(1, 'year').date(day).add(1, 'week').day(firstDay).format('YYYY-MM-DD'),
        },
        endDay: {
          key: Same.DAY,
          value: dayjs()
            .date(day)
            .subtract(1, 'year')
            .date(day)
            .add(1, 'week')
            .day(firstDay)
            .add(1, 'month')
            .day(firstDay - 1)
            .format('YYYY-MM-DD'),
        },
      },
    },
    {
      current: {
        key: Period.LAST_FISCAL_MONTH_END,
        startDate: dayjs().subtract(1, 'month').date(day).day(firstDay).format('YYYY-MM-DD'),
        endDate: dayjs().date(day).day(firstDay).subtract(1, 'day').format('YYYY-MM-DD'),
      },
      prior: {
        key: Prior.PERIOD,
        startDate: {
          key: Same.DATE,
          value: dayjs().date(firstDay).subtract(2, 'month').day(day).format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: dayjs()
            .date(firstDay)
            .subtract(1, 'month')
            .day(day - 1)
            .format('YYYY-MM-DD'),
        },
      },
      priorYear: {
        key: Prior.YEAR,
        startDate: {
          key: Same.DATE,
          value: dayjs().subtract(1, 'month').date(day).day(firstDay).subtract(1, 'year').format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: dayjs().date(day).day(firstDay).subtract(1, 'day').subtract(1, 'year').format('YYYY-MM-DD'),
        },
        startDay: {
          key: Same.DAY,
          value: dayjs()
            .startOf('month')
            .subtract(1, 'year')
            .subtract(1, 'month')
            .date(firstDay)
            .day(day)
            .format('YYYY-MM-DD'),
        },
        endDay: {
          key: Same.DAY,
          value:
            dayjs().endOf('month').subtract(1, 'year').subtract(1, 'month').day() < day - 1
              ? dayjs()
                  .endOf('month')
                  .subtract(1, 'year')
                  .subtract(1, 'month')
                  .day(day - 1)
                  .format('YYYY-MM-DD')
              : dayjs()
                  .endOf('month')
                  .subtract(1, 'year')
                  .subtract(1, 'month')
                  .add(1, 'week')
                  .day(day - 1)
                  .format('YYYY-MM-DD'),
        },
      },
    },
    {
      current: {
        key: Period.FISCAL_YEAR_END,
        startDate: startFiscaldate.format('YYYY-MM-DD'),
        endDate: endFiscalDate.format('YYYY-MM-DD'),
      },
      prior: {
        key: Prior.PERIOD,
        startDate: {
          key: Same.DATE,
          value:
            startFiscaldate.subtract(1, 'year').day() > firstDay
              ? startFiscaldate.subtract(1, 'year').add(1, 'week').day(firstDay).format('YYYY-MM-DD')
              : startFiscaldate.subtract(1, 'year').day(firstDay).format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: startFiscaldate.subtract(1, 'day').format('YYYY-MM-DD'),
        },
      },
      priorYear: {
        key: Prior.YEAR,
        startDate: {
          key: Same.DATE,
          value: startFiscaldate.format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: endFiscalDate.subtract(1, 'year').format('YYYY-MM-DD'),
        },
        startDay: {
          key: Same.DAY,
          value:
            startFiscaldate.subtract(1, 'year').day() > firstDay
              ? startFiscaldate.subtract(1, 'year').add(1, 'week').day(firstDay).format('YYYY-MM-DD')
              : startFiscaldate.subtract(1, 'year').day(firstDay).format('YYYY-MM-DD'),
        },
        endDay: {
          key: Same.DAY,
          value: startFiscaldate.subtract(1, 'day').format('YYYY-MM-DD'),
        },
      },
    },

    {
      current: {
        key: Period.LAST_FISCAL_YEAR_END,
        startDate: previousFiscalStartDate.format('YYYY-MM-DD'),
        endDate: previousFiscalEndDate.format('YYYY-MM-DD'),
      },
      prior: {
        key: Prior.PERIOD,
        startDate: {
          key: Same.DATE,
          value:
            previousFiscalStartDate.subtract(1, 'year').day() > firstDay
              ? previousFiscalStartDate.subtract(1, 'year').add(1, 'week').day(firstDay).format('YYYY-MM-DD')
              : previousFiscalStartDate.subtract(1, 'year').day(firstDay).format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: previousFiscalStartDate.subtract(1, 'day').format('YYYY-MM-DD'),
        },
      },
      priorYear: {
        key: Prior.YEAR,
        startDate: {
          key: Same.DATE,
          value: previousFiscalStartDate.subtract(1, 'year').format('YYYY-MM-DD'),
        },
        endDate: {
          key: Same.DATE,
          value: previousFiscalEndDate.subtract(1, 'year').format('YYYY-MM-DD'),
        },
        startDay: {
          key: Same.DAY,
          value:
            previousFiscalStartDate.subtract(1, 'year').day() > firstDay
              ? previousFiscalStartDate.subtract(1, 'year').add(1, 'week').day(firstDay).format('YYYY-MM-DD')
              : previousFiscalStartDate.subtract(1, 'year').day(firstDay).format('YYYY-MM-DD'),
        },
        endDay: {
          key: Same.DAY,
          value: previousFiscalStartDate.subtract(1, 'day').format('YYYY-MM-DD'),
        },
      },
    },
  ];
  return periods;
};

const CalendarUtilities = {
  fiscalCalendarHeader,
  getFrequencyDays,
  getFiscalCalendarPreview,
  getPayrollCalendarFilters,
  getFiscalCalendarPeriodsFilters,
};

export default CalendarUtilities;
