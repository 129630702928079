import { CboRole, SiteInfo } from '@cbo/shared-library';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import WestIcon from '@mui/icons-material/West';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import FilterContainer from '../FilterFrame/FilterFrame';
import SearchBar from '../SearchBar/SearchBar';
import TabBar, { getUniqueTabs } from '../TabBar/TabBar';
import { BaseGlobalFilterProps, ButtonSize, DateComparisonRanges, SingleSelectGroup } from '../types';
import SingleSelectButton from './SingleSelectButton';
import { useSites } from '../../../contexts/siteContext';
import SiteFilterButton from '../SiteFilter/SiteFilterButton';
import { getSiteInfo } from '../../../utils/reportingUtils/filterUtils';
import { Jurisdiction, JurisdictionResponse } from '../../../laborRules/models/JurisdictionModels';
import { useUsers } from '../../../contexts/userContext';
import isPermitted from '../../../lib/permissions';

interface ExpandedMenuItemProps {
  onClick: () => void;
  text: string;
  backgroundColor: string;
}

function ExpandedMenuItem({
  onClick,
  text,
  backgroundColor,
  children,
}: React.PropsWithChildren<ExpandedMenuItemProps>) {
  return (
    <MenuItem
      id={`${text}-id`}
      data-testid={`${text}-id`}
      aria-label={text}
      role={undefined}
      key={text}
      sx={{
        backgroundColor,
        borderRadius: 1,
        padding: '8px 16px',
        marginLeft: '8px',
        marginRight: '8px',
      }}
      onClick={onClick}
    >
      {children}
    </MenuItem>
  );
}

/**
 * @param options Array of options with type SingleSelectGroup that appear on the filter
 * @param useTabs Whether to use tabs within the component
 * @param predefined Whether the filter should have a predefined value
 * @param useAsDateComparison Internally used to handle date range comparison. Do not use this prop.
 * @param currentSite The default value for the site filter
 * @param defaultDateComparisonPeriod Internally used to handle date range comparison. Do not use this prop here, only use it with the DateComparison component.
 * @param jurisdictionData The data for jurisdictions, to be used in labor rules page for nested jurisdictions
 * @param errorHandling An object that contains a boolean to show an error message and a function to pass in a refetch function that is called when the refresh button is clicked
 */
export interface SingleSelectProps extends BaseGlobalFilterProps {
  options: SingleSelectGroup[];
  useTabs?: boolean;
  predefined?: boolean;
  useAsDateComparison?: boolean;
  currentSite?: SingleSelectGroup;
  defaultDateComparisonPeriod?: number;
  jurisdictionData?: JurisdictionResponse;
  errorHandling?: {
    showError: boolean;
    refetch: () => Promise<unknown>;
  };
}

type ExtendedJurisdiction = Jurisdiction & {
  pathSegments: string[];
};

function SingleSelect(props: SingleSelectProps) {
  const {
    useTabs,
    options,
    buttonText,
    titleText = '',
    onlyFilterContainer,
    controllerName,
    buttonSize = ButtonSize.SMALL,
    predefined,
    useAsDateComparison,
    currentSite,
    defaultDateComparisonPeriod = 0,
    jurisdictionData,
    errorHandling,
  } = props;

  const { t } = useTranslation();
  const user = useUsers();
  const isUserLREWriter = isPermitted(user, [CboRole.LRE_RULE_WRITER]);

  const jurisdictionDataCleaned = useMemo(
    () =>
      jurisdictionData && {
        ...jurisdictionData,
        jurisdictions: jurisdictionData.jurisdictions
          .map((juris) => ({
            ...juris,
            pathSegments: juris.path.split('.').map((segment) => segment.replaceAll('_', ' ')),
          }))
          // Non-admin users should not see jurisdictions without sites
          .filter((juris) => (isUserLREWriter ? true : juris.siteEuIds.length > 0)),
      },
    [isUserLREWriter, jurisdictionData]
  );
  const firstMySiteIndex: number =
    jurisdictionDataCleaned?.jurisdictions.findIndex(
      (juris) => juris.path.includes('.') && juris.siteEuIds.length > 0
    ) ?? 1;

  const alphabetize = jurisdictionData !== undefined;

  const form = useFormContext();
  const { control, watch, resetField, setValue } = form;
  const watching = watch(controllerName);

  const [selectedLabel, setSelectedLabel] = useState<string>('');
  const firstJurisIndex = isUserLREWriter ? firstMySiteIndex : 0;
  if (jurisdictionData && selectedLabel === '') {
    setSelectedLabel(jurisdictionDataCleaned?.jurisdictions[firstJurisIndex].pathSegments[1] ?? '');
    setValue(
      controllerName,
      [
        {
          label: jurisdictionDataCleaned?.jurisdictions[firstJurisIndex].pathSegments[1],
          jurisdictionData: jurisdictionDataCleaned?.jurisdictions[firstJurisIndex],
        },
      ],
      { shouldDirty: false }
    );
  }
  const [selectedIndex, setSelectedIndex] = useState<number>(defaultDateComparisonPeriod || 0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [resetEnabled, setResetEnabled] = useState(false);
  const { updateMultiSelectedSites, multiSelectedSites } = useSites();
  const [showNewList, setShowNewList] = useState(false);
  const [prevNestedOption, setPrevNestedOption] = useState<ExtendedJurisdiction>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchBox, setSearchBox] = useState<string[]>(options.map((group) => `${group.label} ${group.subtext}`));
  const [searchValue, setSearchValue] = useState<string>('');

  const [stateLocalities, setStateLocalities] = useState<ExtendedJurisdiction[]>([]);
  const showNestedJurisdictions = jurisdictionData
    ? stateLocalities && stateLocalities.length > 0 && showNewList
    : false;

  const uniqueTabs: string[] = getUniqueTabs(options);
  const initialTabValue = jurisdictionData ? t('labor.mySites') : uniqueTabs[0];
  const [tabValue, setTabValue] = useState<string>(initialTabValue);
  useEffect(() => {
    setTabValue(initialTabValue);
  }, [initialTabValue]);

  const previousOptionsRef = useRef<SingleSelectGroup[]>();

  let currentSiteInfo: SiteInfo[];
  if (currentSite) {
    currentSiteInfo = getSiteInfo([currentSite]);
  }

  // update sites when currentSite changes
  useEffect(() => {
    if (currentSite) {
      const selectedGroup = options.find((group) => group.label === selectedLabel);
      if (selectedGroup) {
        const site: SiteInfo[] = getSiteInfo([selectedGroup]);
        updateMultiSelectedSites(site);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMultiSelectedSites, selectedLabel]);

  // set value of filter
  useEffect(() => {
    if (!anchorEl && currentSite && multiSelectedSites.length > 0) {
      const storedSites = multiSelectedSites.map((site) => ({
        label: site.name ?? '',
        subtext: site.referenceId,
        id: site.enterpriseUnitId ?? '',
      }));
      setValue(controllerName, storedSites, { shouldValidate: true });
      setSelectedLabel(storedSites[0].label);
    } else if (!anchorEl && currentSite) {
      setValue(controllerName, [currentSite], { shouldValidate: true });
      setSelectedLabel(currentSite.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Gets the total sites returned for a given jurisdiction, either on the state or city level
  const getTotalSites = (jurisdiction: string, isCity = false) => {
    let totalSiteEuIdsLength = 0;

    if (jurisdictionDataCleaned) {
      const index = isCity ? 2 : 1;
      jurisdictionDataCleaned.jurisdictions.forEach((juris) => {
        if (juris.pathSegments[index] && juris.pathSegments[index] === jurisdiction) {
          totalSiteEuIdsLength += juris.siteEuIds.length;
        }
      });
    }
    return totalSiteEuIdsLength;
  };

  const getStateLocalities = (label: string) =>
    jurisdictionDataCleaned?.jurisdictions
      .map((jurisdiction) => {
        if (jurisdiction.pathSegments[2]) {
          if (jurisdiction.pathSegments[1] === label) {
            return jurisdiction;
          }
          if (label.length > 0 && jurisdiction.pathSegments[1] && jurisdiction.pathSegments[2] === label) {
            return jurisdiction;
          }
        }
        return undefined;
      })
      .filter((jurisdiction) => jurisdiction !== undefined) as ExtendedJurisdiction[];

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      if (selectedLabel === '' && predefined) {
        setSelectedLabel(options[0].label);
      }
      if (jurisdictionDataCleaned?.jurisdictions.some((juris) => juris.pathSegments[2] === selectedLabel)) {
        setShowNewList(true);
      }
      setSearchBox(options.map((group) => `${group.label} ${group.subtext}`));
    },
    [jurisdictionDataCleaned, options, predefined, selectedLabel]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFormSubtext = useCallback(
    (index: number) => {
      if (options[index].subtext?.includes(' - ')) {
        return {
          startDate: dayjs(options[index].subtext?.split(' - ')[0]).format('YYYY-MM-DD'),
          endDate: dayjs(options[index].subtext?.split(' - ')[1]).format('YYYY-MM-DD'),
        };
      }
      return {
        startDate: dayjs(options[index].subtext).format('YYYY-MM-DD'),
        endDate: dayjs(options[index].subtext).format('YYYY-MM-DD'),
      };
    },
    [options]
  );

  const handleReset = useCallback(() => {
    if (!useAsDateComparison) resetField(controllerName);
    if (useAsDateComparison) {
      resetField(controllerName);
      setSelectedLabel(options[defaultDateComparisonPeriod].label);
      setSelectedIndex(defaultDateComparisonPeriod);
      setValue(
        controllerName,
        {
          label: Object.values(DateComparisonRanges).find(
            (val) => t(`globalFilters.comparisonDateRanges.${val}`) === options[defaultDateComparisonPeriod].label
          ),
          ...handleFormSubtext(defaultDateComparisonPeriod),
        },
        { shouldDirty: false }
      );
      previousOptionsRef.current = options;
    }
    if (currentSite) {
      setValue(controllerName, [currentSite], { shouldValidate: true });
      setSelectedLabel(currentSite.label);
    }
    if (!predefined && !useAsDateComparison && !currentSite && !jurisdictionDataCleaned) setSelectedLabel('');
    if (useTabs) setTabValue(form.getValues(`${controllerName}.tab`) || uniqueTabs[0] || '');
    if (jurisdictionDataCleaned && jurisdictionDataCleaned.jurisdictions.length > 1) {
      setValue(
        controllerName,
        [
          {
            label: jurisdictionDataCleaned.jurisdictions[firstJurisIndex].pathSegments[1],
            jurisdictionData: jurisdictionDataCleaned.jurisdictions[firstJurisIndex],
          },
        ],
        { shouldDirty: false }
      );
      setPrevNestedOption(undefined);
      setShowNewList(false);
    }
    setResetEnabled(false);
    if (!jurisdictionDataCleaned) handleClose();
  }, [
    useAsDateComparison,
    resetField,
    controllerName,
    currentSite,
    predefined,
    jurisdictionDataCleaned,
    useTabs,
    form,
    uniqueTabs,
    options,
    defaultDateComparisonPeriod,
    setValue,
    handleFormSubtext,
    t,
    firstJurisIndex,
  ]);

  const handleBackgroundColor = (label: string) => {
    const checkIfLabelSelected = selectedLabel === label;
    const checkIfPredefined =
      predefined && options.findIndex((group) => group.label === label) === 0 && selectedLabel === '';
    const checkIfJurisdictions =
      jurisdictionData &&
      watching[0] &&
      !showNewList &&
      watching[0].jurisdictionData?.pathSegments &&
      watching[0].jurisdictionData?.pathSegments[1] === label &&
      watching[0].label === selectedLabel;
    const checkIfNestedJurisdictions = jurisdictionData && watching[0] && showNewList && watching[0].label === label;

    let color =
      checkIfLabelSelected || checkIfPredefined || checkIfJurisdictions || checkIfNestedJurisdictions ? '#d7edff' : '';

    if (color !== '' && theme.palette.mode === 'dark') {
      color = '#1e3756';
    }

    return color;
  };

  const getDateRange = (subtext: string | undefined, label: string) => {
    const dates = subtext?.split(' - ');
    return dates?.length === 2
      ? {
          label: Object.values(DateComparisonRanges).find(
            (val) => t(`globalFilters.comparisonDateRanges.${val}`) === label
          ),
          startDate: dayjs(dates[0]).format('YYYY-MM-DD'),
          endDate: dayjs(dates[1]).format('YYYY-MM-DD'),
        }
      : {
          label: Object.values(DateComparisonRanges).find(
            (val) => t(`globalFilters.comparisonDateRanges.${val}`) === label
          ),
          startDate: dayjs(subtext).format('YYYY-MM-DD'),
          endDate: dayjs(subtext).format('YYYY-MM-DD'),
        };
  };

  useEffect(() => {
    if (
      form.formState.isDirty &&
      selectedLabel !== '' &&
      selectedLabel !== undefined &&
      !predefined &&
      !useAsDateComparison &&
      !jurisdictionData &&
      selectedLabel !== currentSite?.label
    )
      setResetEnabled(true);
    else if (useAsDateComparison && selectedLabel !== options[defaultDateComparisonPeriod].label) setResetEnabled(true);
    else if (predefined && selectedLabel !== options[0].label) setResetEnabled(true);
    else if (jurisdictionData) {
      if (
        (isUserLREWriter &&
          watching[0].label === jurisdictionDataCleaned?.jurisdictions[firstMySiteIndex].pathSegments[1]) ||
        (!isUserLREWriter && watching[0].label === jurisdictionDataCleaned?.jurisdictions[0].pathSegments[1])
      )
        setResetEnabled(false);
      else setResetEnabled(true);
    } else setResetEnabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDateComparisonPeriod, form, options, predefined, selectedLabel, useAsDateComparison]);

  useEffect(() => {
    const allSearchResults = showNewList
      ? stateLocalities.map((juris) => juris.pathSegments[2])
      : options
          .map((group) => `${group.label} ${group.subtext}`)
          .filter((label) => label.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
    setSearchBox(allSearchResults);
  }, [options, searchValue, showNewList, stateLocalities]);

  useEffect(() => {
    if (watching[0] && !useAsDateComparison) setSelectedLabel(watching[0].label);
    if (useAsDateComparison)
      if (JSON.stringify(options) !== JSON.stringify(previousOptionsRef.current) && resetEnabled) {
        setSelectedLabel(options[selectedIndex].label);
        setValue(
          controllerName,
          {
            label: Object.values(DateComparisonRanges).find(
              (val) => t(`globalFilters.comparisonDateRanges.${val}`) === options[selectedIndex].label
            ),
            ...handleFormSubtext(selectedIndex),
          },
          { shouldDirty: false }
        );
        previousOptionsRef.current = options;
      } else
        setSelectedLabel(
          options[selectedIndex]?.subtext?.includes(' - ')
            ? options.find(
                (group) =>
                  group.subtext === `${dayjs(watching.startDate).format('L')} - ${dayjs(watching.endDate).format('L')}`
              )?.label || ''
            : options.find((group) => group.subtext === dayjs(watching.startDate).format('L'))?.label || ''
        );
  }, [
    controllerName,
    handleFormSubtext,
    options,
    resetEnabled,
    selectedIndex,
    selectedLabel,
    setValue,
    showNewList,
    t,
    useAsDateComparison,
    watching,
  ]);

  const ErrorMessage = (
    <Alert
      sx={{
        marginLeft: '16px',
        marginRight: '16px',
        marginBottom: '8px',
      }}
      data-testid='alert-box'
      action={
        <Button
          sx={{ fontWeight: 500, fontSize: 'inherit', color: 'inherit' }}
          variant='text'
          data-testid='refresh-button'
          onClick={errorHandling?.refetch}
        >
          <Typography fontWeight={500} fontSize='inherit' color='inherit'>
            {t('labor.refresh')}
          </Typography>
        </Button>
      }
      severity='error'
    >
      {t('globalFilters.failLoadingSites')}
    </Alert>
  );

  const backButton = showNewList ? (
    <IconButton
      data-testid='single-select-back-button'
      sx={{
        color: '#666666',
        '&:focus-visible': { boxShadow: 'none', outline: `2px solid ${theme.palette.primary.dark}` },
      }}
      onClick={() => {
        setShowNewList(false);
        setSearchValue('');
      }}
    >
      <WestIcon />
    </IconButton>
  ) : null;

  const generateNestedOptions = (field: ControllerRenderProps<FieldValues, string>) =>
    stateLocalities
      .filter((state) => state.pathSegments[2].toLowerCase().includes(searchValue.toLowerCase()))
      .sort((state1, state2) => state1.pathSegments[2].localeCompare(state2.pathSegments[2]))
      .map((group, idx) => (
        <ExpandedMenuItem
          key={`${group.pathSegments[2]}-key`}
          backgroundColor={handleBackgroundColor(group.pathSegments[2] || '')}
          onClick={() => {
            setSelectedIndex(idx + 1);
            setPrevNestedOption(group);
            field.onChange([
              {
                label: group.pathSegments[2],
                jurisdictionData: group,
              },
            ] as SingleSelectGroup[]);
          }}
          text={group.pathSegments[2]}
        >
          <Typography sx={{ fontSize: '16px' }}>{`${group.pathSegments[2]} ${
            getTotalSites(group.pathSegments[2], true) ? `(${getTotalSites(group.pathSegments[2], true)})` : ''
          }`}</Typography>
        </ExpandedMenuItem>
      ));

  // Creates an 'All' option that is displayed first in the nested options for states
  const generateNestedOptionsAll = (field: ControllerRenderProps<FieldValues, string>) => {
    const elements: JSX.Element[] = [];
    const nestedOptions = generateNestedOptions(field);
    const city = jurisdictionDataCleaned?.jurisdictions.find((juris) => juris.pathSegments[2] === watching[0].label);

    let allOption: ExtendedJurisdiction | undefined;
    if (city) {
      allOption = jurisdictionDataCleaned?.jurisdictions.find(
        (juris) => juris.pathSegments[1] === city.pathSegments[1] && !juris.pathSegments[2]
      );
    } else {
      allOption = jurisdictionDataCleaned?.jurisdictions.find(
        (juris) => juris.pathSegments[1] === watching[0].label && !juris.pathSegments[2]
      );
    }
    if (allOption) {
      const allJurisdictions = (
        <ExpandedMenuItem
          key={`${allOption.pathSegments[1]}-key`}
          backgroundColor={handleBackgroundColor(allOption.pathSegments[1])}
          onClick={() => {
            setSelectedIndex(0);
            field.onChange([
              {
                label: allOption?.pathSegments[1] ?? '',
                jurisdictionData: allOption,
              },
            ] as SingleSelectGroup[]);
          }}
          text={`${allOption.pathSegments[1]} ${t('globalFilters.all')}`}
        >
          <Typography sx={{ fontSize: '16px' }}>{`${allOption.pathSegments[1]} ${t('globalFilters.all')}`}</Typography>
        </ExpandedMenuItem>
      );

      elements.push(allJurisdictions);
    }
    elements.push(...nestedOptions);

    return elements;
  };

  const generateOptions = (field: ControllerRenderProps<FieldValues, string>) =>
    options
      .filter((group) => (useTabs ? group.tab === tabValue : group))
      .filter((group) => searchBox.includes(`${group.label} ${group.subtext}`))
      .map((group, idx) => (
        <ExpandedMenuItem
          key={`${group.label}-key`}
          backgroundColor={handleBackgroundColor(group.label)}
          text={group.label}
          onClick={() => {
            setSelectedIndex(idx);
            if (jurisdictionData) {
              if (group.label !== prevNestedOption?.pathSegments[1]) {
                setPrevNestedOption(undefined);
                field.onChange(useAsDateComparison ? getDateRange(group.subtext, group.label) : [group]);
              }
            } else {
              field.onChange(useAsDateComparison ? getDateRange(group.subtext, group.label) : [group]);
            }
            if (jurisdictionData) {
              const localities = getStateLocalities(group.label);
              setStateLocalities(localities);
              if (localities?.length > 0) {
                setShowNewList(true);
              } else {
                setShowNewList(false);
              }
              setSearchValue('');
            }
            if (!jurisdictionData) {
              handleClose();
            }
          }}
        >
          <Grid container direction='column' sx={{ justifyContent: 'space-between' }}>
            <Grid item>
              <Grid alignContent='center' container direction='row' sx={{ justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '16px' }}>{`${group.label}${
                  jurisdictionDataCleaned && getTotalSites(group.label) > 0 ? ` (${getTotalSites(group.label)})` : ''
                }`}</Typography>
                {jurisdictionDataCleaned &&
                  jurisdictionDataCleaned.jurisdictions.some(
                    (juris) => `${juris.pathSegments[1]}` === group.label && juris.pathSegments[2]
                  ) && <ArrowForwardIosIcon sx={{ width: '20px', height: '20px', color: '#6F6F73' }} />}
              </Grid>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: '14px', color: theme.palette.text.secondary }}>{group.subtext}</Typography>
            </Grid>
          </Grid>
        </ExpandedMenuItem>
      ));

  const SingleSelectComponent = (
    <Controller
      name={controllerName}
      control={control}
      render={({ field }) => (
        <Box>
          {errorHandling?.showError && ErrorMessage}
          <Box
            aria-label='single-select-popup'
            data-testid='single-select-popup'
            minHeight={jurisdictionData ? '700px' : ''}
          >
            {useTabs && !showNewList && (
              <TabBar activeTab={tabValue} tabs={uniqueTabs} setActiveTab={setTabValue} alphabetize={alphabetize} />
            )}
            {options.filter((group) => (useTabs ? group.tab === tabValue : group)).length > 9 && (
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                showClearIcon={searchValue.length > 0}
              />
            )}
            <MenuList>{showNestedJurisdictions ? generateNestedOptionsAll(field) : generateOptions(field)}</MenuList>
          </Box>
        </Box>
      )}
    />
  );

  const siteButton = useMemo(
    () => (
      <SiteFilterButton
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        selected={multiSelectedSites}
        optionsLength={options.length}
        currentSite={currentSiteInfo}
        resetEnabled={resetEnabled}
        buttonSize={buttonSize}
        handleReset={handleReset}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedLabel, resetEnabled, anchorEl, buttonSize, handleReset, options.length, currentSite, multiSelectedSites]
  );

  const button = useMemo(
    () => (
      <SingleSelectButton
        anchorEl={anchorEl}
        buttonSize={buttonSize}
        buttonText={buttonText || ''}
        handleClick={handleClick}
        isMobile={isMobile}
        options={options}
        selectedLabel={selectedLabel}
        controllerName={controllerName}
        handleReset={handleReset}
        predefined={predefined || false}
        useAsDateComparison={useAsDateComparison || false}
        resetValue={resetEnabled}
        useAsJurisdiction={!!jurisdictionData}
      />
    ),
    [
      anchorEl,
      buttonSize,
      buttonText,
      controllerName,
      handleClick,
      handleReset,
      isMobile,
      options,
      predefined,
      resetEnabled,
      selectedLabel,
      useAsDateComparison,
      jurisdictionData,
    ]
  );

  return onlyFilterContainer ? (
    SingleSelectComponent
  ) : (
    <FilterContainer
      resetEnabled={resetEnabled}
      handleClose={handleClose}
      anchorEl={anchorEl}
      button={currentSite ? siteButton : button}
      title={
        jurisdictionData && showNestedJurisdictions
          ? watching[0].jurisdictionData?.path.split('.')[1].replaceAll('_', ' ')
          : titleText
      }
      body={SingleSelectComponent}
      resetFunction={handleReset}
      backButton={backButton}
      showNewList={showNewList}
    />
  );
}

SingleSelect.defaultProps = {
  useTabs: false,
  predefined: false,
  useAsDateComparison: false,
  currentSite: undefined,
  defaultDateComparisonPeriod: 0,
  jurisdictionData: undefined,
  errorHandling: undefined,
};

export default SingleSelect;
