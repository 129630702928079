import { TFunction } from 'i18next';
import { NotificationResponse } from '../../admin/requests/index';

/**
 * @param t Translation function
 * @param currentDateInMinutes Used to compute difference in minutes
 * @param createdAt Used to compute difference in minutes
 * @param timeDifference Already supplied difference in minutes
 * @param showNumbersOnly If true, do not show text for "a moment ago"
 */
function formatAgeIndicator(
  t: TFunction,
  currentDateInMinutes?: number,
  createdAt?: Date,
  timeDifference?: number,
  showNumbersOnly?: boolean
) {
  // Either use a supplied time difference or calculate it with given parameters
  let differenceInMinutes = 0;
  if (timeDifference) {
    differenceInMinutes = timeDifference;
  }
  if (currentDateInMinutes && createdAt) {
    const createdAtInMinutes = Math.floor(createdAt.getTime() / 1000 / 60);
    differenceInMinutes = currentDateInMinutes - createdAtInMinutes;
  }

  // TODO: Take into account user preferences when displaying age indicator. i.e: number format & language
  if (differenceInMinutes < 1 && !showNumbersOnly) {
    return t('rightPanel.notifications.aMomentAgo');
    // eslint-disable-next-line no-else-return
  } else if (differenceInMinutes < 60) {
    const shouldDisplayPluralText = differenceInMinutes > 1;
    const minutesAgo = Math.floor(differenceInMinutes);

    return `${minutesAgo} ${
      shouldDisplayPluralText ? t('rightPanel.notifications.minsAgo') : t('rightPanel.notifications.minAgo')
    }`;
  } else if (differenceInMinutes < 1440) {
    const hoursAgo = Math.floor(differenceInMinutes / 60);
    const shouldDisplayPluralText = hoursAgo > 1;

    return `${hoursAgo} ${
      shouldDisplayPluralText ? t('rightPanel.notifications.hoursAgo') : t('rightPanel.notifications.hourAgo')
    }`;
  } else if (differenceInMinutes < 43200) {
    const daysAgo = Math.floor(differenceInMinutes / 60 / 24);
    const shouldDisplayPluralText = daysAgo > 1;

    return `${daysAgo} ${
      shouldDisplayPluralText ? t('rightPanel.notifications.daysAgo') : t('rightPanel.notifications.dayAgo')
    }`;
  } else if (differenceInMinutes < 518400) {
    const monthsAgo = Math.floor(differenceInMinutes / 60 / 24 / 30);
    const shouldDisplayPluralText = monthsAgo > 1;

    return `${monthsAgo} ${
      shouldDisplayPluralText ? t('rightPanel.notifications.monthsAgo') : t('rightPanel.notifications.monthAgo')
    }`;
  }

  return null;
}

export function isValidNotificationRes(notifications: NotificationResponse | null | undefined) {
  return (
    notifications?.data !== undefined &&
    Array.isArray(notifications.data) &&
    notifications?.totalResults !== undefined &&
    notifications?.totalUnread !== undefined
  );
}

export default formatAgeIndicator;
