import { TFunction } from 'i18next';
import { isNil } from 'lodash';

interface GlAccountEditDialogTranslations {
  actionText?: string;
  actionTitle?: string;
  actionStart?: string;
  actionButtonText?: string;
  actionDetail: string;
  didToggleBothFlags: boolean;
}

const getEditDialogContent = (
  t: TFunction,
  deactivate: boolean | undefined,
  hideInInvoicing: boolean | undefined
): GlAccountEditDialogTranslations => {
  const didToggleBothFlags: boolean = !isNil(deactivate) && !isNil(hideInInvoicing);
  const didToggleIsActive: boolean = !isNil(deactivate) && isNil(hideInInvoicing);
  const didToggleDisplayInInvoicing: boolean = isNil(deactivate) && !isNil(hideInInvoicing);

  const res: GlAccountEditDialogTranslations = { didToggleBothFlags, actionDetail: '' };
  if (!didToggleBothFlags && !didToggleIsActive && !didToggleDisplayInInvoicing) return res;

  if (didToggleIsActive) {
    if (deactivate) {
      res.actionTitle = t('admin.glAccountConfiguration.actionContent.deactivateTitle');
      res.actionText = t('admin.glAccountConfiguration.actionContent.deactivate');
      res.actionStart = t('admin.glAccountConfiguration.actionContent.deactivating');
      res.actionButtonText = t('buttonText.deactivate');
    } else {
      res.actionTitle = t('admin.glAccountConfiguration.actionContent.activateTitle');
      res.actionText = t('admin.glAccountConfiguration.actionContent.activate');
      res.actionStart = t('admin.glAccountConfiguration.actionContent.activating');
      res.actionButtonText = t('buttonText.activate');
    }
  } else if (didToggleDisplayInInvoicing) {
    res.actionDetail = t('admin.glAccountConfiguration.actionContent.inInvoicing');
    if (hideInInvoicing) {
      res.actionTitle = t('buttonText.hide');
      res.actionText = t('admin.glAccountConfiguration.actionContent.hide');
      res.actionStart = t('admin.glAccountConfiguration.actionContent.hiding');
      res.actionButtonText = t('buttonText.hide');
    } else {
      res.actionTitle = t('buttonText.show');
      res.actionText = t('admin.glAccountConfiguration.actionContent.show');
      res.actionStart = t('admin.glAccountConfiguration.actionContent.showing');
      res.actionButtonText = t('buttonText.show');
    }
  } else if (didToggleBothFlags) {
    res.actionDetail = t('admin.glAccountConfiguration.actionContent.inInvoicing');
    if (deactivate && hideInInvoicing) {
      res.actionTitle = t('admin.glAccountConfiguration.actionContent.deactivateAndHideTitle');
      res.actionText = t('admin.glAccountConfiguration.actionContent.deactivateAndHide');
      res.actionButtonText = res.actionTitle;
    } else if (deactivate && !hideInInvoicing) {
      res.actionTitle = t('admin.glAccountConfiguration.actionContent.deactivateAndShowTitle');
      res.actionText = t('admin.glAccountConfiguration.actionContent.deactivateAndShow');
      res.actionButtonText = res.actionTitle;
    } else if (!deactivate && hideInInvoicing) {
      res.actionTitle = t('admin.glAccountConfiguration.actionContent.activateAndHideTitle');
      res.actionText = t('admin.glAccountConfiguration.actionContent.activateAndHide');
      res.actionButtonText = res.actionTitle;
    } else {
      res.actionTitle = t('admin.glAccountConfiguration.actionContent.activateAndShowTitle');
      res.actionText = t('admin.glAccountConfiguration.actionContent.activateAndShow');
      res.actionButtonText = res.actionTitle;
    }
  }

  return res;
};

export default getEditDialogContent;
