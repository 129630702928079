import {
  ApplicationsContextProvider,
  IOrganizationContext,
  IUserProfileContext,
  Organization,
  RccContextConfig,
  RccContextProvider,
  RccEnvironment,
  RccLanguage,
  UserStatus,
  useOrganizationContext,
} from '@ncr-voyix-commerce/react-common-components';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const organizationContextValue: IOrganizationContext = {
  totalOrganizations: undefined,
  hasPreloaded: false,
  isLoadingOrg: false,
  organization: undefined,
  isOrgDialogOpen: false,
  setIsOrgDialogOpen: {} as Dispatch<SetStateAction<boolean>>,
  userOrganizations: [],
  updateOrganization: () => null,
  error: undefined,
  isLoading: true,
};

interface IUserProfileInternalContext extends IUserProfileContext {
  setUserDisplayName: Dispatch<SetStateAction<string>>;
}

const userProfileContextValue: IUserProfileInternalContext = {
  userProfile: {
    username: '',
    displayName: undefined,
    displayUsername: '',
    status: 'Active' as UserStatus,
  },
  userProfileLoading: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserDisplayName: () => {},
};

export const OrgContext = createContext<IOrganizationContext>(organizationContextValue);
export const UserProfileContext = createContext<IUserProfileInternalContext>(userProfileContextValue);

const sharedConfig: RccContextConfig = {
  language: 'en' as RccLanguage, // en is the only supported locale as of RCC v1.0.0
  environment: (process.env.REACT_APP_RCC_CONFIG_ENV as RccEnvironment | undefined) ?? 'dev',
  enableAutoLogout: true,
};

const mockOrgs: Organization[] = [
  {
    organizationName: 'hsp-cbo-qe',
    displayName: 'hsp-cbo-qe',
    id: 'e6c83e611d184e38aad8c2be5594dcaf',
    parent: false,
    linesOfBusiness: [],
  },
  {
    id: 'b49ac7d61b0b4c259749468a46d1d401',
    organizationName: 'admin-sales-test-02-28-24',
    displayName: 'Admin Sales test -02-28-24',
    parent: false,
    linesOfBusiness: [],
  },
];

/**
 * This wrapper around RccContextProvider is meant to conditionally return our
 * own version of the organization context provider if the environment variable
 * denoting the use of the preview environment is set to true. Otherwise, we want
 * to use the regular RccContextProvider and organization switcher component.
 */
export function CommonComponentWrapper({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(true);
  const [userDisplayName, setUserDisplayName] = useState<string>('');
  const [organizationName, setOrganizationName] = useState<string | null>(null);
  const [userOrganizations, setUserOrganizations] = useState<Organization[]>([]);
  const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);
  const [organization, setOrganization] = useState<Organization | undefined>(undefined);

  useEffect(() => {
    const selectedOrg = userOrganizations.find((org) => org.organizationName === organizationName);
    setOrganization(selectedOrg || undefined);
  }, [organizationName, userOrganizations]);

  const updateOrganization = useCallback(
    async (orgName: string | undefined) => {
      setOrganizationName(orgName ?? null);
    },
    [setOrganizationName]
  );

  const orgContextValue: IOrganizationContext = useMemo(
    () => ({
      organization,
      isOrgDialogOpen,
      setIsOrgDialogOpen,
      userOrganizations,
      updateOrganization,
      isLoading,
      error: undefined,
      totalOrganizations: undefined,
      hasPreloaded: false,
      isLoadingOrg: false,
    }),
    [organization, isOrgDialogOpen, setIsOrgDialogOpen, userOrganizations, updateOrganization, isLoading]
  );

  const userContextValue: IUserProfileInternalContext = useMemo(
    () => ({
      userProfile: {
        username: userDisplayName,
        displayName: userDisplayName,
        displayUsername: userDisplayName,
        status: UserStatus.ACTIVE,
      },
      userProfileLoading: false,
      setUserDisplayName,
    }),
    [userDisplayName]
  );

  useEffect(() => {
    if (process.env.REACT_APP_USE_PREVIEW_ORG_SWITCHER === 'true') {
      setOrganizationName(mockOrgs[0].organizationName);
      setUserOrganizations(mockOrgs);
      setIsLoading(false);
    }
  }, []);

  return (
    <RccContextProvider config={sharedConfig}>
      <ApplicationsContextProvider defaultApp='ALOHA_SMART_MANAGER'>
        {process.env.REACT_APP_USE_PREVIEW_ORG_SWITCHER === 'true' ? (
          <OrgContext.Provider value={orgContextValue}>
            <UserProfileContext.Provider value={userContextValue}>{children}</UserProfileContext.Provider>
          </OrgContext.Provider>
        ) : (
          children
        )}
      </ApplicationsContextProvider>
    </RccContextProvider>
  );
}

export default CommonComponentWrapper;

export const useUserProfileContextInternal = () => useContext(UserProfileContext);

// eslint-disable-next-line import/no-mutable-exports
export let useOrgContext = useOrganizationContext;

if (process.env.REACT_APP_USE_PREVIEW_ORG_SWITCHER === 'true') {
  useOrgContext = () => useContext(OrgContext);
}
