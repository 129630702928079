import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { ReactNode } from 'react';

const defaultSxProps = { padding: '16px' };

function SwipeableDrawerFooter({ sx = {}, children }: { sx?: SxProps; children: ReactNode }) {
  return (
    <Box
      height='auto'
      width='100%'
      sx={{
        ...defaultSxProps,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

SwipeableDrawerFooter.defaultProps = {
  sx: {},
};

export default SwipeableDrawerFooter;
