import { CboRole, FeatureFlag, Package, PersonaRole } from '@cbo/shared-library';
import i18next from 'i18next';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import routes from '../../../constants/routes';
import { usePackage } from '../../../contexts/packageContext';
import { useUsers } from '../../../contexts/userContext';
import isPermitted from '../../../lib/permissions';
import { loggedInAsEmployeeOnly, loggedInAsPersona } from '../../../lib/persona';
import { useFeatureFlags } from '../../../utils/hooks/useFeatureFlag';
import useAccountRoutes from './useAccountRoutes';
import useCompanyLinksRoutes from './useCompanyLinksRoutes';
import useInventoryRoutes from './useInventoryRoutes';
import useLaborRoutes from './useLaborRoutes';
import useSalesRoutes from './useSalesRoutes';
import useSettingsRoutes from './useSettingsRoutes';

export type NavItem = {
  title?: string;
  children?: NavItem[];
  href?: string;
  icon?: ReactNode;
  key?: string;
  forceNewFrame?: boolean;
  testid?: string;
};

export default function useNavItems(companyLinks: NavItem[]): NavItem[] {
  const user = useUsers();
  const { pathname: currentUrl } = useLocation();
  const [cboLabor, cboInventory, cboHideAlohaEsentialOrgs] = useFeatureFlags(
    FeatureFlag.labor,
    FeatureFlag.inventory,
    FeatureFlag.hideAlohaEssentialOrgs
  );
  const hasStarterPackage = usePackage(Package.STARTER);

  const accountSettingsRoutes = [routes.ACCOUNT_PREFERENCES, routes.EMPLOYEE_REGISTRATION_HOME];
  const showAccountSettings = accountSettingsRoutes.some((item) => currentUrl.includes(item));

  const laborRoutes = useLaborRoutes();
  const inventoryRoutes = useInventoryRoutes();
  const salesRoutes = useSalesRoutes();
  const companyLinksRoutes = useCompanyLinksRoutes(companyLinks);
  const settingsRoutes = useSettingsRoutes();
  const accountRoutes = useAccountRoutes();

  const navItems: NavItem[] = [];
  const notLoggedInAsEmployee = !loggedInAsEmployeeOnly(user);

  if (notLoggedInAsEmployee) {
    if (isPermitted(user, [CboRole.ADMIN_DASHBOARD_VIEWER])) {
      navItems.push({
        title: i18next.t('navItems.dashboard'),
        testid: 'home-dashboard',
        href: routes.HOME_PAGE,
      });
    }

    if (!cboHideAlohaEsentialOrgs && cboLabor) {
      navItems.push({
        title: i18next.t('navItems.laborManagement'),
        forceNewFrame: true,
        testid: 'labor-management',
        href: '/labor',
        children: laborRoutes,
      });
    }

    if (hasStarterPackage && cboInventory) {
      navItems.push({
        title: i18next.t('navItems.inventoryManagement'),
        href: '/inventory',
        forceNewFrame: true,
        testid: 'inventory-management',
        children: inventoryRoutes,
      });
    }

    navItems.push({
      title: i18next.t('navItems.salesManagement'),
      forceNewFrame: true,
      href: '/sales',
      testid: 'sales-management',
      children: salesRoutes,
    });

    if (hasStarterPackage) {
      navItems.push({
        title: i18next.t('navItems.companyLinks'),
        forceNewFrame: true,
        href: routes.EDIT_COMPANY_LINKS,
        testid: 'company-links-side-menu-item',
        children: companyLinksRoutes,
      });
    }

    if (isPermitted(user, [CboRole.AUDIT_LOG_VIEW])) {
      navItems.push({
        title: i18next.t('navItems.activityLog'),
        testid: 'activity-log-side-menu-item',
        href: routes.ACTIVITY_LOG,
      });
    }
  }

  if (isPermitted(user, [CboRole.SCHEDULE_PERSONAL_VIEW]) && loggedInAsPersona(user, [PersonaRole.EMPLOYEE])) {
    navItems.push({
      title: i18next.t('navItems.mySchedule'),
      href: routes.MY_SCHEDULE,
      testid: 'my-schedule-menu-item',
    });
  }

  if (notLoggedInAsEmployee) {
    navItems.push({
      title: i18next.t('navItems.settings'),
      forceNewFrame: true,
      href: '/settings',
      testid: 'settings-side-menu-item',
      children: settingsRoutes,
    });
  }

  if (showAccountSettings) {
    navItems.push({
      title: i18next.t('navItems.myAccount'),
      forceNewFrame: true,
      href: '/account',
      testid: 'my-account-side-menu-item',
      children: accountRoutes,
    });
  }

  return navItems;
}
