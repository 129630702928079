import { Theme, SxProps } from '@mui/material/styles';
import colors from '../../lib/styles/colors';
import { rowFlex } from '../../lib/styles/commonStyles';

export const outerBox = (uiKit: boolean, theme: Theme): SxProps<Theme> => ({
  overflowX: 'auto',
  width: 'auto',
  border: uiKit ? `1px solid ${colors.surfaceBorder[theme.palette.mode]}` : `1px solid ${colors.borderOnEmpty}`,
  borderRadius: uiKit ? '12px' : undefined,
});

export const bar = (selectedRowsCount: number, theme: Theme): SxProps<Theme> => ({
  minHeight: '44px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: selectedRowsCount > 0 ? theme.palette.primary.main : theme.palette.background.paper,
  color: selectedRowsCount > 0 ? theme.palette.primary.contrastText : theme.palette.text.primary,
});

export const barBox = {
  ...rowFlex,
  justifyContent: 'space-between',
  height: '100%',
  flex: 1,
};

export const rowsSelectedText = {
  padding: '8px 16px',
  marginY: 'auto',
};

export const buttonText = (theme: Theme): SxProps<Theme> => ({
  '&.MuiButton-text': { color: theme.palette.primary.contrastText },
});

export const divider = (theme: Theme): SxProps<Theme> => ({
  height: '55%',
  alignSelf: 'center',
  margin: '0px 2px 0px 4px',
  borderColor: theme.palette.primary.contrastText,
});

export const cancelButton = (theme: Theme): SxProps<Theme> => ({
  ...buttonText(theme),
  marginRight: '4px',
});
