/* eslint-disable no-nested-ternary */
import { CboRole } from '@cbo/shared-library';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import Header from '../../components/Header/Header';
import LaborRuleCard from '../LaborRuleCard/LaborRuleCard';
import RuleGuidanceDrawer from '../RuleGuidanceDrawer/RuleGuidanceDrawer';
import {
  LaborRuleConfigData,
  LaborRuleGroup,
  MinorRuleTypes,
  RuleTypes,
  SchedulingRuleTypes,
  WagesRuleTypes,
  ruleTypesByGroup,
} from '../models/LaborRuleConfiguration';
import { JurisdictionSitesRow } from '../JurisdictionSitesDataGrid/JurisdictionSitesDataGrid';
import { filterRuleConfigsForType, buildConfigsForTippedRules, mutateConfigsForRuleForm } from '../utils/utils';
import { SingleSelectGroup } from '../../components/GlobalFilterBar/types';
import SingleSelect from '../../components/GlobalFilterBar/SingleSelect/SingleSelect';
import { JurisdictionResponse } from '../models/JurisdictionModels';
import { useUsers } from '../../contexts/userContext';
import isPermitted from '../../lib/permissions';

const RULE_GROUPS = [
  LaborRuleGroup.OVERTIME,
  LaborRuleGroup.BREAKS,
  LaborRuleGroup.WAGES_AND_TIPS,
  LaborRuleGroup.SCHEDULING,
  LaborRuleGroup.MINORS,
];

export const renderTabs = (tabNames: LaborRuleGroup[] | string[], t: TFunction<'translation', undefined>) =>
  tabNames.map((tabName) => (
    <Tab key={tabName} label={tabName && t(`laborRules.tabs.${tabName}`)} data-testid={`${tabName}-tab`} />
  ));

const parseJurisdiction = (jurisdiction: string) => jurisdiction.split('.')[1].replaceAll('_', ' ');

const getRuleConfigsByGroup = (
  ruleConfigs: LaborRuleConfigData[] | undefined,
  currentTabName: LaborRuleGroup,
  currentJurisdictionPath: string
) =>
  ruleConfigs
    ? (mutateConfigsForRuleForm(
        ruleConfigs.filter((ruleConfig) => ruleConfig.rule?.group === currentTabName)
      ) as LaborRuleConfigData[])
    : [];

const getDefaultRuleConfigsByGroup = (
  defaultRuleConfigs: LaborRuleConfigData[] | undefined,
  currentTabName: LaborRuleGroup,
  currentJurisdictionPath: string
) =>
  defaultRuleConfigs
    ? mutateConfigsForRuleForm(
        defaultRuleConfigs.filter(
          (defaultRuleConfig) =>
            defaultRuleConfig.rule?.group === currentTabName && currentJurisdictionPath.includes(defaultRuleConfig.path)
        )
      )
    : [];

const getRulesForLaborRuleCard = (filteredConfigs: LaborRuleConfigData[], type: string) => {
  let final = filteredConfigs;

  if (type === WagesRuleTypes.TippedMinimumWageRule) {
    final = buildConfigsForTippedRules(filteredConfigs);
  }
  return final;
};

export interface LaborRulesFilter {
  jurisdictions: SingleSelectGroup[];
}

export interface LaborRulesMainViewProps {
  setLruaModalOpen: (value: boolean) => void;
  selectedJurisdiction: string;
  setSelectedJurisdiction: (value: string) => void;
  jurisdictionPath: string;
  setJurisdictionPath: (value: string) => void;
  defaultRuleConfigs: LaborRuleConfigData[] | undefined;
  defaultRuleConfigsLoading: boolean;
  orgRuleConfigsLoading: boolean;
  lruaRecordLoading: boolean;
  orgRuleConfigs: LaborRuleConfigData[] | undefined;
  sites: JurisdictionSitesRow[] | undefined;
  refetchConfigs: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<LaborRuleConfigData[], unknown>>;
  jurisdictionData: JurisdictionResponse | void;
  refetchJurisdictionData: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<void | JurisdictionResponse, unknown>>;
}

export default function LaborRulesMainView(props: LaborRulesMainViewProps) {
  const {
    setLruaModalOpen,
    jurisdictionPath,
    setJurisdictionPath,
    selectedJurisdiction,
    setSelectedJurisdiction,
    defaultRuleConfigs,
    defaultRuleConfigsLoading,
    orgRuleConfigs,
    orgRuleConfigsLoading,
    lruaRecordLoading,
    sites: unfilteredSites,
    refetchConfigs,
    jurisdictionData,
    refetchJurisdictionData,
  } = props;

  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();
  const handleTabChange = (event: SyntheticEvent, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };
  const currentTabName = RULE_GROUPS[tabIndex];

  const ruleConfigsByGroup = getRuleConfigsByGroup(orgRuleConfigs, currentTabName, jurisdictionPath);

  const defaultRuleConfigsByGroup = getDefaultRuleConfigsByGroup(defaultRuleConfigs, currentTabName, jurisdictionPath);
  const user = useUsers();
  const isUserLREWriter = isPermitted(user, [CboRole.LRE_RULE_WRITER]);

  const filteredJurisdictions = useMemo(
    () =>
      isUserLREWriter
        ? jurisdictionData?.jurisdictions
        : jurisdictionData?.jurisdictions.filter((juris) => juris.siteEuIds.length > 0),
    [isUserLREWriter, jurisdictionData?.jurisdictions]
  );

  const sites = unfilteredSites?.filter((site) =>
    filteredJurisdictions?.some((juris) => juris.siteEuIds.includes(site.id))
  );

  const formContext = useForm<LaborRulesFilter>({
    defaultValues: {
      jurisdictions: filteredJurisdictions
        ? [
            {
              label: filteredJurisdictions[1].path.split('.')[1],
              jurisdictionData: filteredJurisdictions[1],
            },
          ]
        : [],
    },
  });
  const { reset } = formContext;

  useEffect(() => {
    if (isUserLREWriter) {
      reset({
        jurisdictions: [
          {
            label: jurisdictionData?.jurisdictions[1].path.split('.')[1],
            jurisdictionData: jurisdictionData?.jurisdictions[1],
          },
        ],
      });
    } else if (filteredJurisdictions) {
      reset({
        jurisdictions: [
          {
            label: filteredJurisdictions[0].path.split('.')[1],
            jurisdictionData: filteredJurisdictions[0],
          },
        ],
      });
    }
  }, [filteredJurisdictions, isUserLREWriter, jurisdictionData, jurisdictionData?.jurisdictions, reset]);

  const { getValues } = formContext;

  const jurisdictionFormValues: SingleSelectGroup[] = getValues('jurisdictions');

  useEffect(() => {
    if (jurisdictionFormValues.length > 0 && jurisdictionFormValues[0]?.jurisdictionData) {
      setJurisdictionPath(jurisdictionFormValues[0].jurisdictionData?.path);
      setSelectedJurisdiction(jurisdictionFormValues[0].jurisdictionData?.path);
    }
  }, [jurisdictionFormValues, setJurisdictionPath, setSelectedJurisdiction]);

  const onViewUserAgreement = () => {
    setLruaModalOpen(true);
  };

  const generateOptions = () =>
    [...new Set(filteredJurisdictions?.map((jurisdiction) => jurisdiction.path.split('.')[1]))]
      .filter((path) => path !== undefined && path !== 'undefined')
      .sort((a, b) => a.localeCompare(b))
      .map((filteredJurisdiction) => {
        let tabValue = t('labor.other');
        const unTabbedOptions = {
          label: filteredJurisdiction.replaceAll('_', ' '),
          jurisdictionData: jurisdictionData?.jurisdictions.find((juris) => {
            const jurisElements = juris.path.split('.');
            if (jurisElements[1] && jurisElements[1] === filteredJurisdiction && jurisElements[2] === undefined) {
              if (juris.siteEuIds.length > 0) tabValue = t('labor.mySites');
              return juris;
            }
            return false;
          }),
        };
        if (isUserLREWriter) {
          return {
            ...unTabbedOptions,
            tab: tabValue,
          };
        }
        return unTabbedOptions;
      });

  return (
    <Box data-testid='labor-rules-main-view'>
      <Grid container alignItems='start' py={2}>
        <Grid
          item
          xs={12}
          sx={{ position: 'sticky', top: '4.5rem', zIndex: 100, backgroundColor: theme.palette.background.default }}
        >
          {isUserLREWriter && (
            <Grid container sx={{ marginBottom: 2 }}>
              {lruaRecordLoading ? (
                <Grid item xs={12}>
                  <Skeleton variant='rounded' height={32} width={400} />
                </Grid>
              ) : (
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <Header title={t(`laborRules.title`)} dataTestId='labor-rules-header' />
                  <Button
                    data-testid='view-user-agreement-button'
                    onClick={onViewUserAgreement}
                    color='secondary'
                    endIcon={<InsertDriveFileIcon />}
                  >
                    {t('laborRules.viewUserAgreement')}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}

          <Grid item xs={2} sx={{ marginBottom: 2 }}>
            {lruaRecordLoading ? (
              <Skeleton variant='rounded' height={48} width={250} />
            ) : (
              <Box>
                <FormContainer formContext={formContext}>
                  <SingleSelect
                    useTabs={isUserLREWriter}
                    controllerName='jurisdictions'
                    options={generateOptions()}
                    jurisdictionData={jurisdictionData || undefined}
                    titleText={t('laborRules.editRuleView.jurisdictionLabel')}
                    errorHandling={{
                      refetch: refetchJurisdictionData,
                      showError: jurisdictionData
                        ? jurisdictionData.missingSites.length > 0 || jurisdictionData.missingPaths.length > 0
                        : false,
                    }}
                    buttonText='Jurisdiction'
                  />
                </FormContainer>
              </Box>
            )}
          </Grid>
          <Grid item>
            {lruaRecordLoading ? (
              <Stack direction='row' sx={{ marginBottom: 2 }}>
                {RULE_GROUPS.map((name) => (
                  <Skeleton key={`${name}-tab-skeleton`} width={110} height={35} sx={{ marginRight: 1 }} />
                ))}
              </Stack>
            ) : (
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                sx={{ marginBottom: 2 }}
                data-testid='labor-rule-group-tabs'
              >
                {renderTabs(RULE_GROUPS, t)}
              </Tabs>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sx={{ marginBottom: 2 }}>
            {lruaRecordLoading || defaultRuleConfigsLoading ? (
              <Skeleton variant='rounded' height={90} />
            ) : (
              <RuleGuidanceDrawer
                activeTab={currentTabName}
                jurisdiction={parseJurisdiction(selectedJurisdiction)}
                defaultRuleConfigs={defaultRuleConfigsByGroup}
              />
            )}
          </Grid>
          <Grid item sx={{ marginBottom: 2 }}>
            {(ruleTypesByGroup[currentTabName] as RuleTypes[])
              .filter(
                (type: RuleTypes) =>
                  ![MinorRuleTypes.SummerVacationRule, MinorRuleTypes.MinorSchoolDaysRule].includes(
                    type as MinorRuleTypes
                  )
              )
              .filter(
                (type: RuleTypes) =>
                  ![
                    SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule,
                    SchedulingRuleTypes.ExtraHoursPredictabilityPayRule,
                    SchedulingRuleTypes.FixedFeePredictabilityPayRule,
                  ].includes(type as SchedulingRuleTypes)
              )
              .map((type: RuleTypes) =>
                lruaRecordLoading || orgRuleConfigsLoading ? (
                  <Skeleton key={`${type}-rule-card-skeleton`} variant='rounded' height={300} sx={{ marginTop: 2 }} />
                ) : (
                  <LaborRuleCard
                    key={`${type}-rule-card`}
                    ruleType={type}
                    ruleGroup={currentTabName}
                    title={type && t(`laborRules.rules.${type}.name`)}
                    data-testid={`${type}-rule-card`}
                    ruleConfigs={getRulesForLaborRuleCard(
                      ruleConfigsByGroup.filter((conf) => filterRuleConfigsForType(conf, type)),
                      type
                    )}
                    sites={sites}
                    jurisdictionPath={jurisdictionPath}
                    refetchConfigs={refetchConfigs}
                    orgRuleConfigsLoading={orgRuleConfigsLoading}
                    defaultRuleConfigs={defaultRuleConfigsByGroup}
                    selectedJurisdiction={selectedJurisdiction}
                  />
                )
              )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
