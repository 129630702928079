import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MutableRefObject, useRef, useState } from 'react';
import { UserProfileOption } from '@ncr-voyix-commerce/react-common-components';
import UserProfileSettingsDrawer from './UserProfileSettingsDrawer';
import UserProfileSettingsMenu from './UserProfileSettingsMenu';
import ProfileIcon from './ProfileIcon';

function MockUserProfile({ inputOptions = [] }: { inputOptions?: UserProfileOption[] }) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);

  const navigationSettingOptions: UserProfileOption[] = [...inputOptions].filter(
    (option: UserProfileOption) => !option.hidden
  );

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };
  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleMenuOpen}
        ref={buttonRef}
        type='button'
        sx={{ borderRadius: '50%', padding: '8px', minWidth: 'unset' }}
        data-element-id='user-menu-nav-button'
      >
        <ProfileIcon />
      </Button>
      {isMobile ? (
        <UserProfileSettingsDrawer
          menuOpen={isMenuOpen}
          options={navigationSettingOptions}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
        />
      ) : (
        <UserProfileSettingsMenu
          options={navigationSettingOptions}
          anchorRef={buttonRef}
          menuOpen={isMenuOpen}
          onMenuClose={handleMenuClose}
        />
      )}
    </>
  );
}

MockUserProfile.defaultProps = {
  inputOptions: [],
};

export default MockUserProfile;
