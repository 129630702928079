/* eslint-disable no-nested-ternary */
import { FormContainer } from 'react-hook-form-mui';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import Ty from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import DialogWrapper from '../../components/Dialog/DialogWrapper';
import useYupValidationResolver from '../../utils/formUtils/yupValidationResolver';
import createFormTextField from '../../components/FormTextFields/FormTextFields';

const CHANGE_CONFIRMATION_TEXT = 'yes';

export default function LaborSettingsScheduleChangeWarning({
  open,
  onCancel,
  onConfirm,
}: {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = yup.object({
    confirmationInput: yup
      .string()
      .required()
      .test(
        'match-confirmation-word',
        t(`laborSettingsForSchedule.changeWarningDialog.confirmationWordMismatchPrompt`),
        (value) => value === CHANGE_CONFIRMATION_TEXT
      ),
  });

  const formContext = useForm({
    defaultValues: { confirmationInput: '' },
    resolver: useYupValidationResolver(validationSchema),
  });

  const { register, reset } = formContext;

  useEffect(() => {
    if (open) {
      reset({ confirmationInput: '' }, { keepErrors: false, keepDirty: false });
      setIsSubmitting(false);
    }
  }, [open, reset]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await onConfirm();
    } finally {
      reset({ confirmationInput: '' }, { keepErrors: false, keepDirty: false });
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <DialogWrapper
        title={t(`laborSettingsForSchedule.changeWarningDialog.dialogTitle`)}
        dialogOptions={{
          open,
          onClose: onCancel,
          maxWidth: 'xs',
          fullWidth: true,
        }}
      >
        <FormContainer formContext={formContext} onSuccess={handleSubmit}>
          <DialogContent data-testid='confirm-dialog'>
            <Ty color='text.secondary'>{t(`laborSettingsForSchedule.changeWarningDialog.changeEffects`)}</Ty>
            <Ty mt={2} color='text.secondary'>
              <Trans
                i18nKey='laborSettingsForSchedule.changeWarningDialog.confirmationTextPrompt'
                values={{ CHANGE_CONFIRMATION_TEXT }}
              />
            </Ty>
            <Box mt={3}>
              {createFormTextField(
                {
                  name: 'confirmationInput',
                  variant: 'standard',
                  required: true,
                  dataTestId: 'confirmation-text-field',
                  label: '',
                  disabled: isSubmitting,
                },
                { t, tKey: 'labor' },
                register
              )}
            </Box>
            <Ty color='text.secondary' mt={1}>
              {t(`laborSettingsForSchedule.changeWarningDialog.cancelPrompt`)}
            </Ty>
          </DialogContent>
          <DialogActions>
            <Button data-testid='cancel-btn' onClick={onCancel} color='secondary' disabled={isSubmitting}>
              {t('Cancel')}
            </Button>
            <Button
              data-testid='confirm-btn'
              color='error'
              variant='contained'
              type='submit'
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={18} color='inherit' /> : null}
            >
              {isSubmitting ? t('processing') : t('Confirm')}
            </Button>
          </DialogActions>
        </FormContainer>
      </DialogWrapper>

      {isSubmitting && (
        <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </>
  );
}
