import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ButtonResetIcon from '../ButtonResetIcon';
import {
  arrowStyling,
  defaultButtonStyling,
  definedFilterStyling,
  selectedButtonStyling,
} from '../GlobalFilterBarStyles';
import { ButtonSize, SingleSelectGroup } from '../types';

interface SingleSelectButtonProps {
  isMobile: boolean;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonText: string;
  selectedLabel: string;
  buttonSize: ButtonSize;
  anchorEl: HTMLButtonElement | null;
  options: SingleSelectGroup[];
  controllerName: string;
  handleReset: () => void;
  predefined: boolean;
  useAsDateComparison: boolean;
  resetValue: boolean;
  useAsJurisdiction?: boolean;
}

function SingleSelectButton(props: SingleSelectButtonProps) {
  const {
    isMobile,
    handleClick,
    buttonText,
    selectedLabel,
    buttonSize,
    anchorEl,
    options,
    controllerName,
    handleReset,
    predefined,
    useAsDateComparison,
    resetValue,
    useAsJurisdiction,
  } = props;

  const theme = useTheme();
  const { t } = useTranslation();
  const isDarkMode = theme.palette.mode === 'dark';

  const form = useFormContext();
  const {
    watch,
    formState: { dirtyFields: dirtiness },
  } = form;
  const watching = watch(controllerName);

  const dirtyValue = Object.keys(dirtiness).includes(controllerName);

  const handleButtonSxPredefined = () => {
    if (
      Boolean(anchorEl) ||
      (options.findIndex((label) => label.label === selectedLabel) !== 0 && selectedLabel !== '')
    ) {
      if (!isDarkMode) {
        return {
          backgroundColor: `${theme.palette.primary.main}${theme.palette.action.hoverOpacity
            .toString(16)
            .substring(2, 4)}`,
        };
      }
      return {
        backgroundColor: '#053570',
        color: theme.palette.text.primary,
      };
    }
    return definedFilterStyling(theme);
  };

  const handleButtonSx = () => {
    if (isDarkMode) {
      if (
        (useAsDateComparison && watching.startDate !== null && watching.endDate !== null) ||
        (dirtyValue && !useAsDateComparison) ||
        useAsJurisdiction
      ) {
        return {
          backgroundColor: '#053570',
          color: theme.palette.text.primary,
        };
      }
      return {
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.text.primary,
      };
    }

    if (
      (useAsDateComparison && watching.startDate !== null && watching.endDate !== null) ||
      (dirtyValue && !useAsDateComparison) ||
      useAsJurisdiction
    ) {
      return selectedButtonStyling(anchorEl, theme);
    }

    return defaultButtonStyling(anchorEl, theme);
  };

  const handleButtonText = () => {
    if (useAsDateComparison && watching.startDate) {
      const startDayjs = dayjs(watching.startDate);
      const endDayjs = dayjs(watching.endDate);

      const formattedStartDate = startDayjs.format('L');
      const formattedEndDate = endDayjs.format('L');

      if (startDayjs.isSame(endDayjs, 'day') || endDayjs === undefined) {
        return `${t('sales.dateRanges.comparedTo')} ${formattedStartDate}`;
      }
      return `${t('sales.dateRanges.comparedTo')} ${formattedStartDate} - ${formattedEndDate}`;
    }
    if (selectedLabel && selectedLabel !== '') return selectedLabel;
    return buttonText;
  };

  const buttonDataTestId = `${controllerName}-single-select-button`;
  const button = (
    <Button
      role='button'
      aria-haspopup='true'
      data-testid={buttonDataTestId}
      sx={handleButtonSx}
      onClick={handleClick}
      size={buttonSize}
      aria-label={useAsDateComparison ? `${handleButtonText()}` : `${buttonText}, ${selectedLabel} selected`}
    >
      {(resetValue && useAsDateComparison) || (dirtyValue && !useAsDateComparison) ? (
        <>
          <Typography
            sx={{
              fontWeight: 500,
              lineHeight: '22px',
              marginRight: '4px',
              marginLeft: '12px',
            }}
            fontSize='inherit'
          >
            {handleButtonText()}
          </Typography>
          {resetValue && dirtyValue ? (
            <ButtonResetIcon handleReset={handleReset} />
          ) : (
            <ArrowDropDownIcon sx={arrowStyling(theme, false)} fontSize='inherit' />
          )}
        </>
      ) : (
        <>
          <Typography
            sx={{
              fontWeight: 500,
              lineHeight: '22px',
              marginRight: '4px',
              marginLeft: '12px',
            }}
            fontSize='inherit'
          >
            {handleButtonText()}
          </Typography>
          {anchorEl ? (
            <ArrowDropUpOutlinedIcon sx={arrowStyling(theme, false)} fontSize='inherit' />
          ) : (
            <ArrowDropDownIcon sx={arrowStyling(theme, false)} fontSize='inherit' />
          )}
        </>
      )}
    </Button>
  );

  const predefinedButton = !isMobile ? (
    <Button
      onClick={handleClick}
      role='button'
      aria-haspopup='true'
      data-testid={buttonDataTestId}
      size='small'
      variant='outlined'
      aria-label={`${buttonText}, ${selectedLabel} selected`}
      startIcon={<SwapVertIcon />}
      sx={handleButtonSxPredefined}
    >
      <Typography
        sx={{
          fontWeight: 500,
          lineHeight: '22px',
        }}
        fontSize='inherit'
      >
        {buttonText}
      </Typography>
    </Button>
  ) : (
    <Button
      data-testid={buttonDataTestId}
      onClick={handleClick}
      sx={handleButtonSxPredefined}
      variant='outlined'
      aria-label={`${buttonText}, ${selectedLabel} selected`}
      size={buttonSize}
    >
      <SwapVertIcon />
    </Button>
  );

  return predefined ? predefinedButton : button;
}

SingleSelectButton.defaultProps = {
  useAsJurisdiction: false,
};

export default SingleSelectButton;
