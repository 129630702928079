/* eslint-disable react/require-default-props */
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers-pro';
import { TextFieldProps } from '@mui/material/TextField';
import { Control, Controller, ControllerProps, FieldError, FieldValues, Path } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { useAccountPreferences } from '../../contexts/accountPreferencesContext';
import { getDateTimeFormat, getDateTimeFormatForDisplay } from '../../utils';
import { getHelperTextForDatePicker } from './DatePickerElement';

export declare type ParseableDate<TDate> = string | number | Date | null | undefined | TDate;

export type DateTimePickerElementProps<T extends FieldValues, TInputDate, TDate = TInputDate> = Omit<
  DateTimePickerProps<Dayjs>,
  'value' | 'onChange' | 'renderInput' | 'onBlur'
> & {
  name: Path<T>;
  required?: boolean;
  isDate?: boolean;
  parseError?: (error: FieldError) => string;
  validation?: ControllerProps['rules'];
  parseDate?: (value: TDate, keyboardInputValue?: string) => TDate;
  control?: Control<FieldValues>;
  inputProps?: TextFieldProps;
  inputFormat?: string;
  disablePlaceholder?: boolean;
  disableMaskedInput?: boolean;
  disableOpenPicker?: boolean;
  helperText?: TextFieldProps['helperText'];
  handleBlur?: () => Promise<void>;
  disabled?: boolean;
};

export default function DateTimePickerElement<TFieldValues extends FieldValues>({
  isDate,
  parseError,
  name,
  required,
  parseDate,
  validation = {},
  inputProps,
  inputFormat,
  disablePlaceholder,
  disableMaskedInput,
  disableOpenPicker,
  control,
  handleBlur,
  disabled,
  ...rest
}: DateTimePickerElementProps<TFieldValues, unknown, unknown>): JSX.Element {
  const { preferences } = useAccountPreferences();

  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DateTimePicker
          {...rest}
          disabled={disabled}
          format={getDateTimeFormat(preferences, inputFormat)}
          disableOpenPicker={disableOpenPicker}
          value={dayjs(value || '')}
          views={['year', 'month', 'day']}
          onChange={(newValue, keyboardInputValue) => {
            if (handleBlur) {
              handleBlur();
            }
            onChange(newValue, keyboardInputValue);
          }}
          slotProps={{
            textField: {
              ...inputProps,
              ...(!value && {
                value: '',
                placeholder: getDateTimeFormatForDisplay(preferences),
              }),
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              'data-testid': `${name}-datetimepicker-text-field`,
              fullWidth: true,
              required: !!required,
              error: !!error,
              helperText: getHelperTextForDatePicker(error, parseError, inputProps?.helperText || rest.helperText),
            },
          }}
        />
      )}
    />
  );
}
