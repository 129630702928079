import { CalendarType, Month, WeekDay, MonthPattern } from '@cbo/shared-library';

interface CalendarTypesConfig {
  [calendarType: string]: {
    id: string;
    label: string;
  };
}

interface MonthsConfig {
  [month: string]: {
    id: number;
    label: string;
  };
}

interface DaysConfig {
  [day: string]: {
    id: number;
    label: string;
  };
}

export interface QuarterStylesConfig {
  [quarterStyle: string]: {
    id: string;
    label: string;
  };
}

export const calendarTypesConfig: CalendarTypesConfig = {
  '13-4': {
    id: CalendarType['13-4'],
    label: '13-4',
  },
  '12 Months': {
    id: CalendarType['12 months'],
    label: '12 Months',
  },
};

export const monthsConfig: MonthsConfig = {
  January: {
    id: Month.JANUARY,
    label: 'January',
  },
  February: {
    id: Month.FEBRUARY,
    label: 'February',
  },
  March: {
    id: Month.MARCH,
    label: 'March',
  },
  April: {
    id: Month.APRIL,
    label: 'April',
  },
  May: {
    id: Month.MAY,
    label: 'May',
  },
  June: {
    id: Month.JUNE,
    label: 'June',
  },
  July: {
    id: Month.JULY,
    label: 'July',
  },
  August: {
    id: Month.AUGUST,
    label: 'August',
  },
  September: {
    id: Month.SEPTEMBER,
    label: 'September',
  },
  October: {
    id: Month.OCTOBER,
    label: 'October',
  },
  November: {
    id: Month.NOVEMBER,
    label: 'November',
  },
  December: {
    id: Month.DECEMBER,
    label: 'December',
  },
};

export const monthsWith31Days = [
  Month.JANUARY,
  Month.MARCH,
  Month.MAY,
  Month.JULY,
  Month.AUGUST,
  Month.OCTOBER,
  Month.DECEMBER,
];

export const monthsWith30Days = [Month.APRIL, Month.JUNE, Month.SEPTEMBER, Month.NOVEMBER];

export const monthsWith28Days = [Month.FEBRUARY];

export const monthValueToStringMap: {
  [monthIndex: number]: string;
} = {
  0: 'january',
  1: 'february',
  2: 'march',
  3: 'april',
  4: 'may',
  5: 'june',
  6: 'july',
  7: 'august',
  8: 'september',
  9: 'october',
  10: 'november',
  11: 'december',
};

export const weekDayValueToStringMap: {
  [dayIndex: number]: string;
} = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};

export const daysConfig: DaysConfig = {
  Sunday: {
    id: WeekDay.SUNDAY,
    label: 'Sunday',
  },
  Monday: {
    id: WeekDay.MONDAY,
    label: 'Monday',
  },
  Tuesday: {
    id: WeekDay.TUESDAY,
    label: 'Tuesday',
  },
  Wednesday: {
    id: WeekDay.WEDNESDAY,
    label: 'Wednesday',
  },
  Thursday: {
    id: WeekDay.THURSDAY,
    label: 'Thursday',
  },
  Friday: {
    id: WeekDay.FRIDAY,
    label: 'Friday',
  },
  Saturday: {
    id: WeekDay.SATURDAY,
    label: 'Saturday',
  },
};

export const quarterStylesConfig = {
  '4-4-5': {
    id: MonthPattern['4-4-5'],
    label: MonthPattern['4-4-5'],
  },
  '4-5-4': {
    id: MonthPattern['4-5-4'],
    label: MonthPattern['4-5-4'],
  },
  '5-4-4': {
    id: MonthPattern['5-4-4'],
    label: MonthPattern['5-4-4'],
  },
};
