import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Ty from '@mui/material/Typography';
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureFlag } from '@cbo/shared-library';
import Divider from '@mui/material/Divider';
import { DynamicButtonInfo } from '../../models/DynamicButtonInfo';
import DataGrid, { BaseDataGridProps } from '../DataGrid/DataGrid';
import { useFeatureFlag } from '../../utils/hooks/useFeatureFlag';
import { outerBox, bar, barBox, rowsSelectedText, buttonText, divider, cancelButton } from './styles';
import { rowFlex } from '../../lib/styles/commonStyles';

export interface BaseGridRow {
  [key: string]: unknown;
  id: GridRowId;
}

export interface BulkActionDataGridProps extends BaseDataGridProps {
  buttons: DynamicButtonInfo[];
  treeCustomSelectionFunction?: (model: GridRowSelectionModel) => GridRowId[];
  customResetActions?: () => void;
}
function BulkActionDataGrid(props: BulkActionDataGridProps) {
  const { t } = useTranslation();
  const {
    buttons,
    rowSelectionModel,
    disableVirtualization,
    onRowSelectionModelChange,
    columns,
    sx,
    onPageChange,
    treeCustomSelectionFunction,
    customResetActions,
  } = props;
  const uiKit = useFeatureFlag(FeatureFlag.restaurantsUiKit);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [selectionModelWrapper, setSelectionModelWrapper] = useState<GridRowId[]>([]);
  const [columnsWrapper, setColumnsWrapper] = useState(columns);
  const [actionMenu, setActionMenu] = useState<null | HTMLElement>(null);
  const BUTTONS_LIMIT = 5;
  const isMenuOpen = Boolean(actionMenu);
  const customOnSelectionModelChange = (model: GridRowSelectionModel) => {
    let treeModelLength = true;

    if (treeCustomSelectionFunction) {
      const treeModel = treeCustomSelectionFunction(model);
      setSelectedRowsCount(treeModel.length);
      if (treeModel.length === 0) {
        treeModelLength = false;
      }
    } else {
      setSelectedRowsCount(model.length);
      setSelectionModelWrapper(model as GridRowId[]);
    }

    if (model.length > 0 && treeModelLength) {
      const actionsColumn = columns.find((column) => column.field === 'actions');
      if (actionsColumn) {
        const columnsWithoutActions = columns.filter((c) => c.field !== 'actions');
        setColumnsWrapper(columnsWithoutActions);
      }
    } else {
      setColumnsWrapper(columns);
    }
    if (onRowSelectionModelChange) {
      onRowSelectionModelChange(model);
    }
  };

  const resetBulkActionState = () => {
    setSelectionModelWrapper([]);
    setSelectedRowsCount(0);
    setColumnsWrapper(columns);
    if (customResetActions) {
      customResetActions();
    }
  };

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setActionMenu(null);
  };

  return (
    <Box component={uiKit ? undefined : Paper} sx={outerBox(uiKit, theme)}>
      {/* Bulk action bar */}
      <Box data-testid='bulk-action-header' sx={bar(selectedRowsCount, theme)}>
        {selectedRowsCount > 0 ? (
          <Box sx={barBox}>
            <Ty sx={rowsSelectedText} variant={isMobile ? 'caption' : 'body2'}>{`${selectedRowsCount} ${t(
              'global.selected'
            )}`}</Ty>

            <Box sx={rowFlex}>
              {isMobile && buttons.length > 1 ? (
                <>
                  <Button
                    variant='text'
                    size={isMobile ? 'small' : 'medium'}
                    endIcon={<ArrowDropDownIcon />}
                    sx={buttonText(theme)}
                    onClick={showMenu}
                  >
                    {t('buttonText.actions')}
                  </Button>
                  <Menu
                    open={isMenuOpen}
                    anchorEl={actionMenu}
                    onClose={closeMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    data-testid='bulk-action-mobile-menu'
                  >
                    {buttons.map((currentButton) => (
                      <MenuItem
                        dense
                        key={currentButton.text}
                        onClick={() => {
                          if (rowSelectionModel) {
                            currentButton.functionality(rowSelectionModel as GridRowId[]);
                          } else {
                            currentButton.functionality(selectionModelWrapper);
                          }

                          if (currentButton.resetStateAfterAction) {
                            resetBulkActionState();
                          }
                          closeMenu();
                        }}
                        data-testid={currentButton.dataTestId}
                      >
                        <ListItemIcon>{currentButton.icon}</ListItemIcon>
                        <ListItemText>{currentButton.text}</ListItemText>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                buttons.slice(0, BUTTONS_LIMIT).map((currentButton) => (
                  <Button
                    key={currentButton.text}
                    variant='text'
                    size={isMobile ? 'small' : 'medium'}
                    startIcon={currentButton.icon}
                    sx={buttonText(theme)}
                    data-testid={currentButton.dataTestId}
                    onClick={() => {
                      if (rowSelectionModel) {
                        currentButton.functionality(rowSelectionModel as GridRowId[]);
                      } else {
                        currentButton.functionality(selectionModelWrapper);
                      }
                      if (currentButton.resetStateAfterAction) {
                        resetBulkActionState();
                      }
                    }}
                  >
                    {`${currentButton.text}`}
                  </Button>
                ))
              )}
              <Divider orientation='vertical' sx={divider(theme)} />
              <Button
                variant='text'
                size={isMobile ? 'small' : 'medium'}
                sx={cancelButton(theme)}
                data-testid='bulk-action-cancel-button'
                onClick={resetBulkActionState}
              >
                {t('buttonText.cancel')}
              </Button>
            </Box>
          </Box>
        ) : (
          <Ty sx={rowsSelectedText} variant={isMobile ? 'caption' : 'body2'}>{`${selectedRowsCount} ${t(
            'global.selected'
          )}`}</Ty>
        )}
      </Box>
      <DataGrid
        {...props}
        disableVirtualization={disableVirtualization}
        onRowSelectionModelChange={customOnSelectionModelChange}
        rowSelectionModel={rowSelectionModel ?? selectionModelWrapper}
        columns={columnsWrapper}
        onPageChange={onPageChange}
        sx={{
          ...sx,
          border: 'none',
        }}
        hideFooterSelectedRowCount
      />
    </Box>
  );
}

BulkActionDataGrid.defaultProps = {
  treeCustomSelectionFunction: undefined,
  customResetActions: undefined,
};

export default BulkActionDataGrid;
