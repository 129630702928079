import { SiteInfo } from '@cbo/shared-library';
import { useQueryClient } from '@tanstack/react-query';
import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useLaborQueryKeys from '../labor/requests/queryKeys';
import ContextProviderProps from '../models/ContextProviderProps';
import siteSort from '../utils/siteUtils/siteSort';
import { useUsers } from './userContext';

const defaultSite: SiteInfo = {
  enterpriseUnitId: '',
  name: '',
  referenceId: '',
  timeZone: '',
};

export type TSiteContext = {
  sortedSites: SiteInfo[];
  selectedSite: SiteInfo;
  updateSelectedSite: (site: SiteInfo) => void;
  multiSelectedSites: SiteInfo[];
  updateMultiSelectedSites: Dispatch<SetStateAction<SiteInfo[]>>;
  isSiteFiltering: boolean;
  updateIsSiteFiltering: Dispatch<SetStateAction<boolean>>;
};

export const SitesContext = createContext<TSiteContext>({
  sortedSites: [],
  selectedSite: defaultSite,
  updateSelectedSite: (site) => {
    defaultSite.name = site.name;
    defaultSite.enterpriseUnitId = site.enterpriseUnitId;
    defaultSite.referenceId = site.referenceId;
    defaultSite.timeZone = site.timeZone;
  },
  multiSelectedSites: [],
  updateMultiSelectedSites: (_sites) => null,
  isSiteFiltering: false,
  updateIsSiteFiltering: () => null,
});

export const useSites = () => useContext(SitesContext);

export function SitesContextProvider({ children }: ContextProviderProps) {
  const users = useUsers();
  const [sortedSites, setSortedSites] = useState<SiteInfo[]>([defaultSite]);
  const [selectedSite, setSelectedSite] = useState<SiteInfo>(defaultSite);
  const [multiSelectedSites, updateMultiSelectedSites] = useState<SiteInfo[]>([]);
  const [isSiteFiltering, updateIsSiteFiltering] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const updateSelectedSite = useCallback(
    (site: SiteInfo) => {
      if (site && users.org?.bslId) {
        setSelectedSite(site);
        updateMultiSelectedSites([site]);
        if (site !== defaultSite)
          localStorage.setItem(`${users.id}-${users.org.bslId}-selectedSite`, JSON.stringify(site));
      }
    },
    [setSelectedSite, users.id, users.org?.bslId]
  );

  const querykey = useLaborQueryKeys().employee.listByFilters({ enterpriseUnit: selectedSite.enterpriseUnitId });
  useEffect(() => {
    // we need to invalidate the employees api
    queryClient.invalidateQueries({
      queryKey: querykey,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querykey]);
  const providerValue = useMemo(
    () => ({
      sortedSites,
      selectedSite,
      updateSelectedSite,
      multiSelectedSites,
      updateMultiSelectedSites,
      isSiteFiltering,
      updateIsSiteFiltering,
    }),
    [sortedSites, selectedSite, updateSelectedSite, multiSelectedSites, isSiteFiltering]
  );

  useEffect(() => {
    if (users.isOrgSwitching) {
      updateSelectedSite(defaultSite);
    }
    if (users.bslAuth?.sites && !users.isOrgSwitching) {
      setSortedSites(siteSort(Object.values(users.bslAuth.sites)));
      const currentSite = localStorage.getItem(`${users.id}-${users.org?.bslId}-selectedSite`);
      if (currentSite) {
        const siteData: SiteInfo = JSON.parse(currentSite);
        if (
          siteData.enterpriseUnitId !== selectedSite.enterpriseUnitId &&
          Object.values(users.bslAuth.sites).some((site) => site.enterpriseUnitId === siteData.enterpriseUnitId)
        ) {
          updateSelectedSite(siteData);
        }
      } else {
        updateSelectedSite(siteSort(users.bslAuth.sites)[0]);
      }
    }
  }, [users.bslAuth?.sites, selectedSite, updateSelectedSite, users.id, users.org?.bslId, users.isOrgSwitching]);

  return <SitesContext.Provider value={providerValue}>{children}</SitesContext.Provider>;
}

export default SitesContextProvider;
