import { createContext, useState, useEffect, useContext } from 'react';
import { CboRole, Res } from '@cbo/shared-library';
import dayjs from 'dayjs';
import { IPeriodFilter } from '@cbo/shared-library/response/calendar.response';
import ContextProviderProps from '../models/ContextProviderProps';
import { useSites } from './siteContext';
import { usePayrollCalendarDataQuery, useFiscalCalendarDataQuery } from '../admin/requests/queries';
import isMessageResponse from '../utils/objectUtils/messageResponse';
import { useUsers } from './userContext';
import isPermitted from '../lib/permissions';
import CalendarUtilities from '../admin/utils/calendarUtils';

interface FiscalCalendarFilters {
  periods: IPeriodFilter[];
}
interface FilterContextType {
  fiscalCalendarFilters: FiscalCalendarFilters | undefined;
  payrollCalendarFilters: Res.Calendar.PayrollCalendarFilters | undefined;
}

const initialFilterContext: FilterContextType = {
  fiscalCalendarFilters: undefined,
  payrollCalendarFilters: undefined,
};

export const FilterContext = createContext<FilterContextType>(initialFilterContext);

export const useFilters = () => useContext(FilterContext);

function FilterContextProvider({ children }: ContextProviderProps) {
  const [filters, setFilters] = useState<FilterContextType>(initialFilterContext);
  const { selectedSite } = useSites();
  const user = useUsers();

  const { data, isSuccess } = usePayrollCalendarDataQuery(
    user.fullyAuthenticated === 'authenticated' && isPermitted(user, [CboRole.PAYROLL_CALENDAR_VIEW]),
    selectedSite.enterpriseUnitId
  );
  const { data: fiscalCalendar, isSuccess: isFiscalCalenderSuccess } = useFiscalCalendarDataQuery(
    user.fullyAuthenticated === 'authenticated' && isPermitted(user, [CboRole.FISCAL_CALENDAR_VIEW]),
    user.id ?? '',
    user.org?.bslId
  );

  useEffect(() => {
    if (fiscalCalendar && isFiscalCalenderSuccess) {
      const periods: IPeriodFilter[] = CalendarUtilities.getFiscalCalendarPeriodsFilters(fiscalCalendar);
      const fiscalCalendarFilters: FiscalCalendarFilters = {
        periods,
      };
      setFilters((prev: FilterContextType) => ({ ...prev, fiscalCalendarFilters }));
    }
  }, [fiscalCalendar, isFiscalCalenderSuccess]);

  useEffect(() => {
    if (data && isSuccess && !isMessageResponse(data)) {
      const currentDate = dayjs().format('YYYY-MM-DD');
      const payrollCalendarFiltersData = CalendarUtilities.getPayrollCalendarFilters(
        selectedSite.enterpriseUnitId,
        data.startDate,
        data.frequency,
        currentDate
      );
      setFilters((prev) => ({ ...prev, payrollCalendarFilters: payrollCalendarFiltersData }));
    }
  }, [data, isSuccess, selectedSite.enterpriseUnitId]);

  return <FilterContext.Provider value={filters}>{children}</FilterContext.Provider>;
}

export default FilterContextProvider;
