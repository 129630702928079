import { useEffect, useMemo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Pagination from '@mui/material/Pagination';
import Ty from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import DialogWrapper from '../../components/Dialog/DialogWrapper';
import NotificationCard from '../../components/RightPanel/Notifications/NotificationCard/NotificationCard';
import { isValidNotificationRes } from '../../utils/notificationsUtils/notificationsHelper';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { useNotificationsDataQuery } from '../requests/queries';
import { useUsers } from '../../contexts/userContext';
import { NotificationDetails, useAdminRequests } from '../requests/index';

export interface NotificationsDialogProps {
  open: boolean;
  isFullScreen: boolean;
  notificationsToRender: number;
  notificationBatchSize: number;
  handleNotificationsDialogClose: () => void;
  handleNotificationsTotalUnread: (totalUnread: number) => void;
}

interface NotificationBatch {
  batchNumber: number;
  notifications: NotificationDetails | null;
}

interface NotificationDialogTitleProps {
  title: string;
  unreadNotifications: number;
  handleClose: () => void;
  handleRefetchNotifications: () => void;
}

function getStartEnd(currentPage: number, notificationBatchSize: number, notificationPageSize: number) {
  const tempCurrentPage = ((currentPage - 1) % notificationBatchSize) + 1;
  const start = (tempCurrentPage - 1) * notificationPageSize;
  const end = start + notificationPageSize;

  return { start, end };
}

function getBatchNumber(currentPage: number, notificationBatchSize: number) {
  const batchNumber = Math.floor((currentPage - 1) / notificationBatchSize);

  return batchNumber;
}

// Dialog title component
function NotificationsDialogTitle(props: NotificationDialogTitleProps) {
  const { t } = useTranslation();
  const { markAllNotificationAsRead } = useAdminRequests();
  const { title, unreadNotifications, handleClose, handleRefetchNotifications } = props;

  async function markAllAsRead() {
    await markAllNotificationAsRead();
    handleRefetchNotifications();
  }

  return (
    <Box>
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Ty data-testid='notifications-dialog-title' variant='h6'>
          {title}
        </Ty>
        <IconButton
          data-testid='notifications-dialog-close-btn'
          aria-label={t('buttonText.close')}
          onClick={handleClose}
          sx={{
            position: 'relative',
            top: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {/* sub header */}
      <Box
        sx={{
          paddingTop: 3.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Ty data-testid='new-notifications-indicator' variant='caption' color='text.secondary'>
          {t('admin.allNotificationsDialog.newIndicator', {
            indicatorNumber: unreadNotifications,
          })}
        </Ty>
        <Button
          data-testid='mark-all-as-read'
          size='large'
          onClick={() => markAllAsRead()}
          disabled={unreadNotifications <= 0}
        >
          {t('admin.allNotificationsDialog.markAllAsRead')}
        </Button>
      </Box>
    </Box>
  );
}

function NotificationsDialog(props: NotificationsDialogProps) {
  const { t } = useTranslation();
  const user = useUsers();
  const { setSnackbarState } = useSnackbar();
  const {
    open,
    isFullScreen,
    notificationsToRender,
    notificationBatchSize,
    handleNotificationsDialogClose,
    handleNotificationsTotalUnread,
  } = props;
  const [paginationPadding, setPaginationPadding] = useState('0px');
  const [currentBatch, setCurrentBatch] = useState<NotificationBatch>({
    batchNumber: 0,
    notifications: null,
  });
  const [notificationPage, setNotificationPage] = useState(1);
  const [batchNumber, setBatchNumber] = useState(0);

  const {
    data: notifications,
    isError: isGetNotificationError,
    refetch: notificationsRefetch,
    isFetched: notificationsIsFetched,
    isLoading,
  } = useNotificationsDataQuery(
    user.fullyAuthenticated === 'authenticated' && notificationsToRender !== 0,
    batchNumber,
    notificationsToRender * notificationBatchSize,
    ['notificationsDialogData', batchNumber.toString()]
  );

  useEffect(() => {
    if (notifications && isValidNotificationRes(notifications)) {
      notifications.data = notifications.data.map((item) => ({ ...item, createdAt: new Date(item.createdAt) }));
      setCurrentBatch({
        batchNumber: notifications.pageNumber,
        notifications: {
          data: notifications.data,
          totalPages: Math.ceil(notifications.totalResults / notificationsToRender),
          totalResults: notifications.totalResults,
          totalUnread: notifications.totalUnread,
        },
      });
      handleNotificationsTotalUnread(notifications.totalUnread);
    }
  }, [handleNotificationsTotalUnread, notifications, notificationsToRender]);

  useEffect(() => {
    if (isGetNotificationError) {
      setSnackbarState({
        open: true,
        color: 'error',
        message: t('admin.allNotificationsDialog.errorFetchingNotification'),
      });
    }
  }, [isGetNotificationError, setSnackbarState, t]);

  const handleRefetchNotifications = () => {
    notificationsRefetch();
  };

  const calculatePaginationPadding = () => {
    if (isFullScreen) {
      return '24px 0px 16px 0px';
    }

    if (notificationsToRender < 5) {
      return '16px 0px';
    }

    return '24px 0px 50px 0px';
  };

  const handleNotificationsPageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    if (notificationsIsFetched) {
      setNotificationPage(value);
      const tempBatchNumber = getBatchNumber(value, notificationBatchSize);
      if (tempBatchNumber !== currentBatch.batchNumber) {
        setBatchNumber(tempBatchNumber);
      }
    }
  };

  useEffect(() => {
    setPaginationPadding(calculatePaginationPadding());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsToRender]);

  const notificationsList = useMemo(() => {
    const tempBatchNumber = getBatchNumber(notificationPage, notificationBatchSize);
    let startEnd = getStartEnd(notificationPage, notificationBatchSize, notificationsToRender);
    if (!isLoading && tempBatchNumber === currentBatch.batchNumber && currentBatch.notifications?.data) {
      if (startEnd.start >= currentBatch.notifications.data.length) {
        startEnd = getStartEnd(
          currentBatch.notifications?.totalPages !== undefined ? currentBatch.notifications.totalPages : 1,
          notificationBatchSize,
          notificationsToRender
        );
        setNotificationPage(
          currentBatch.notifications?.totalPages !== undefined ? currentBatch.notifications.totalPages : 1
        );
      }
      return currentBatch.notifications.data.slice(startEnd.start, startEnd.end);
    }
    if (currentBatch?.notifications && currentBatch.notifications.data.length === 0 && currentBatch.batchNumber > 0) {
      setBatchNumber(currentBatch.batchNumber - 1);
    }
    return [];
  }, [
    currentBatch.batchNumber,
    currentBatch.notifications,
    isLoading,
    notificationBatchSize,
    notificationPage,
    notificationsToRender,
  ]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = notificationsToRender - notificationsList.length;
  const minHeight = isFullScreen ? 131 : 108;

  return (
    <DialogWrapper
      title={
        <NotificationsDialogTitle
          title={t('admin.allNotificationsDialog.title')}
          unreadNotifications={currentBatch?.notifications?.totalUnread ? currentBatch.notifications.totalUnread : 0}
          handleClose={handleNotificationsDialogClose}
          handleRefetchNotifications={handleRefetchNotifications}
        />
      }
      dialogOptions={{
        open,
        onClose: handleNotificationsDialogClose,
        maxWidth: 'xl',
        fullWidth: true,
        fullScreen: isFullScreen,
        sx: {
          '& .MuiDialogTitle-root': {
            padding: isFullScreen ? '28px 16px 0px 16px' : '16px 32px 0px 32px',
          },
          '& .MuiDialogContent-root': {
            padding: isFullScreen ? '0px 0px 16px 0px' : '0px 32px 20px 32px',
          },
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Notification list */}
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Divider />
            <List data-testid='all-notifications-list' disablePadding>
              {notificationsList.map((notification, index) => (
                <Box key={`notification-card-${notification.id}`}>
                  <ListItem data-testid={`notification-card-${index}`} disablePadding>
                    <NotificationCard
                      handleRefetchNotifications={handleRefetchNotifications}
                      notification={notification}
                      renderDelete
                      minHeightInPx={`${minHeight}px`}
                    />
                  </ListItem>

                  <Divider />
                </Box>
              ))}
            </List>

            {emptyRows > 0 && (
              <Box
                data-testid='notifications-empty-rows'
                sx={{
                  height: minHeight * emptyRows,
                }}
              />
            )}
            {isLoading && (
              <Grid
                container
                alignItems='center'
                justifyContent='center'
                data-testid='spinner-container'
                sx={{
                  position: 'absolute',
                  top: '5%',
                }}
              >
                <CircularProgress />
              </Grid>
            )}
          </Box>

          {/* Pagination control */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: paginationPadding,
            }}
          >
            <Pagination
              data-testid='notifications-pagination'
              count={currentBatch.notifications?.totalPages ? currentBatch.notifications.totalPages : 0}
              page={notificationPage}
              onChange={handleNotificationsPageChange}
              defaultPage={1}
              size={isFullScreen ? 'medium' : 'large'}
            />
          </Box>
        </Box>
      </DialogContent>
    </DialogWrapper>
  );
}

export default NotificationsDialog;
