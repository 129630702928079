import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { Theme, useTheme } from '@mui/material/styles';
import { UserProfileOption } from '@ncr-voyix-commerce/react-common-components';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUserProfileContextInternal } from '../CommonComponentWrapper';
import SwipeableDrawerHeader from './SwipeableDrawerHeader';
import ProfileIcon from './ProfileIcon';
import SwipeableDrawerFooter from './SwipeableDrawerFooter';
import LogoutButton from './LogoutButton';

function DrawerIconOption({
  option,
  selected,
  onClick,
}: {
  option: UserProfileOption;
  selected: boolean;
  onClick: () => void;
}) {
  const { linkProps = {}, onClick: onClickOption, icon, url, label } = option;

  const listButton = (
    <ListItemButton
      onClick={() => {
        if (onClickOption) onClickOption();
        onClick();
      }}
      sx={{
        padding: '12px 16px',
      }}
      selected={selected}
    >
      {icon && (
        <Box display='flex' lineHeight={1} color={(theme: Theme) => theme.palette.action.active} paddingRight='8px'>
          {icon}
        </Box>
      )}
      <Typography variant='body1' textAlign='center'>
        {label}
      </Typography>
    </ListItemButton>
  );

  return (
    <ListItem
      disablePadding
      sx={{
        a: {
          textDecoration: 'none',
          width: '100%',
          color: 'inherit',
        },
      }}
      key={url}
    >
      {url !== undefined ? (
        <Link {...linkProps} to={url}>
          {listButton}
        </Link>
      ) : (
        listButton
      )}
    </ListItem>
  );
}

function UserProfileSettingsDrawer({
  menuOpen,
  onMenuOpen,
  onMenuClose,
  options,
}: {
  menuOpen: boolean;
  onMenuOpen: () => void;
  onMenuClose: () => void;
  options: UserProfileOption[];
}) {
  const { userProfile } = useUserProfileContextInternal();
  const theme = useTheme();
  const currentLocation = useLocation();
  const [hasUserName, setHasUserName] = useState<boolean>(false);

  useEffect(() => {
    setHasUserName(Boolean(userProfile && userProfile.displayName));
  }, [userProfile]);

  return (
    <SwipeableDrawer
      disableDiscovery
      disableSwipeToOpen
      anchor='bottom'
      open={menuOpen}
      onClose={onMenuClose}
      onOpen={onMenuOpen}
    >
      <SwipeableDrawerHeader>
        {hasUserName && (
          <>
            <Box
              sx={{
                padding: '12px 8px',
              }}
              display='flex'
              alignItems='center'
            >
              <Box paddingRight='16px'>
                <ProfileIcon />
              </Box>
              <Typography variant='body1'>{userProfile?.displayName ?? userProfile?.fullName}</Typography>
            </Box>
            <Divider />
          </>
        )}
      </SwipeableDrawerHeader>
      <List data-element-id='user-menu-list'>
        {options.map((option: UserProfileOption) => (
          <DrawerIconOption
            key={option.label}
            option={option}
            selected={option?.url === currentLocation?.pathname}
            onClick={onMenuClose}
          />
        ))}
      </List>
      <SwipeableDrawerFooter
        sx={{
          padding: 0,
        }}
      >
        <Box sx={{ px: '8px' }}>
          <Divider />
        </Box>
        <LogoutButton
          size='large'
          sx={{
            width: '100%',
            justifyContent: 'flex-start',
            textTransform: 'none',
            padding: '12px 16px',
            color: theme.palette.text.primary,
            fontWeight: theme.typography.body1.fontWeight,
            fontSize: theme.typography.body1.fontSize,
          }}
        />
      </SwipeableDrawerFooter>
    </SwipeableDrawer>
  );
}

export default UserProfileSettingsDrawer;
