const colors = {
  borderOnEmpty: '#B8B7B7',
  surfaceBorder: {
    dark: '#3D3D3D',
    light: '#E8E8E8',
  },
  surface: { light: '#FAFAFA', dark: '#171717' },
  graphColors: {
    gradient1: '#069887',
    gradient2: '#BCEBE5',
    gradient3: '#D9F2EF',
  },
  tooltip: { light: '#323232', dark: '#F1F0F4' },
};

export default colors;
