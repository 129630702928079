/* eslint-disable no-unused-expressions */
import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';
import _, { Dictionary, chain } from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import Edit from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {
  BreakRuleTypes,
  CustomLaborRuleConfigForm,
  LaborRule,
  LaborRuleGroup,
  RuleFormValues,
  SchedulingRuleTypes,
  MinimumWageRule,
  WagesRuleTypes,
  LaborRuleConfigData,
  BreakRule,
  CreateChangeSetRequest,
  ConfigurationTypes,
  RuleChangeReason,
  MinorRuleTypes,
  OvertimeRuleTypes,
  MinorProhibitedHoursRule,
  SplitShiftRule,
  EnterpriseGroupResponse,
  PredictabilityPayRule,
  AdjustedRateRemainingTimePredictabilityPayRule,
  ExtraHoursPredictabilityPayRule,
  FixedFeePredictabilityPayRule,
  HoursChange,
  CompensationTypes,
  CompensationType,
  RightToRestRule,
  RightToRestPaymentType,
  rightToRestWithPeriodLocalities,
  RightToRestTypePeriod,
  ChangeType,
  TransStates,
  BOOLEAN_FIELDS,
  PAY_HOURS_TRANS_STATES,
} from '../models/LaborRuleConfiguration';
import { JurisdictionSitesRow } from '../JurisdictionSitesDataGrid/JurisdictionSitesDataGrid';

const formatSummerDate = (date: string) => date.split('--')[1].split('-').join('/');

export const to12hrTimeString = (timeString: string) =>
  new Date(`1970-01-01T${timeString}Z`).toLocaleTimeString('en-US', {
    timeZone: 'UTC',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  });

export const convertMinutesToHours = (minutes: number) => {
  const hours = minutes / 60;
  return hours % 1 !== 0 ? Number(hours.toFixed(2)) : hours;
};

export const convertHoursToMinutes = (hours: number) => Math.round(hours * 60);

export const valueFormatter = (
  value: string | number | boolean,
  propertyName: string,
  type: string,
  t: TFunction<'translation', undefined>
) => {
  let formatted = value;
  switch (propertyName) {
    case 'permittedStart':
    case 'permittedEnd':
      formatted = to12hrTimeString(value.toString()).toLowerCase();
      break;
    case 'splitThresholdSeconds':
      formatted = Math.round(Number(value) / 60);
      break;
    case 'amount':
      formatted = `$${Number(value).toFixed(2)}`;
      break;
    case 'workDayType':
      formatted = t(`laborRules.selectValues.workDayType.${value}`);
      break;
    case 'period':
      formatted = t(`laborRules.rulesGuidance.${value}`);
      break;
    case 'start':
    case 'end':
      formatted = formatSummerDate(value.toString());
      break;
    case 'isPaid':
      formatted = value ? t(`laborRules.rulesGuidance.paid`) : t(`laborRules.rulesGuidance.unpaid`);
      break;
    case 'optIsPaid':
      formatted = value ? t(`laborRules.rulesGuidance.paid`) : t(`laborRules.rulesGuidance.unpaid`);
      break;
    case 'isSummer':
      formatted = value ? t(`laborRules.rulesGuidance.isSummer`) : t(`laborRules.rulesGuidance.isNotSummer`);
      break;
    case 'isSubject':
      formatted = value ? t(`laborRules.rulesGuidance.isSubject`) : t(`laborRules.rulesGuidance.isNotSubject`);
      break;
    case 'schoolInSession':
      formatted = value
        ? t(`laborRules.rulesGuidance.isSchoolSession`)
        : t(`laborRules.rulesGuidance.isNotSchoolSession`);
      break;
    case 'effectiveRangeUpperBound':
      formatted = t(`laborRules.selectValues.effectiveRangeUpperBound.${(Number(value) / 86400).toString()}`);
      break;
    case 'hoursChange':
      formatted = t(`laborRules.selectValues.hoursChange.${value}`);
      break;
    case 'threshold':
      if (
        [
          SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule,
          SchedulingRuleTypes.ExtraHoursPredictabilityPayRule,
          SchedulingRuleTypes.FixedFeePredictabilityPayRule,
          SchedulingRuleTypes.PredictabilityPayRule,
        ].includes(type as SchedulingRuleTypes)
      )
        formatted = Number(value) / 60;
      break;
    case 'type':
      formatted = t(`laborRules.selectValues.type.${value}`);
      break;
    case 'compensationType':
      formatted = t(`laborRules.selectValues.compensationType.${(value as unknown as CompensationType).type}`);
      break;
    case 'paymentType.type':
      formatted = t(`laborRules.rulesGuidance.${value}`);
      break;
    case 'paymentType.period':
      formatted = t(`laborRules.rulesGuidance.${value}`);
      break;
    case 'feeAmount':
      formatted = `$${Number(value).toFixed(2)}`;
      break;
    case 'lowerLimit':
      formatted = `$${Number(value).toFixed(2)}`;
      break;
    case 'effectivePayRate':
      formatted = `$${Number(value).toFixed(2)}`;
      break;
    default:
      break;
  }
  return formatted;
};

export const buildTippedMinimumWageRuleConfig = (ruleConfigs: LaborRuleConfigData[]): LaborRuleConfigData => {
  /**
   * the "TipMakeupPayRule" is just the MinimumWageRule with isTipped = true
   */
  const tippedRuleConfig = ruleConfigs.filter(
    (ruleConfig) =>
      ruleConfig?.rule.type === WagesRuleTypes.MinimumWageRule && (ruleConfig?.rule as MinimumWageRule).isTipped
  )[0];
  const nonTippedRule = ruleConfigs.filter(
    (ruleConfig) =>
      ruleConfig?.rule.type === WagesRuleTypes.MinimumWageRule && !(ruleConfig?.rule as MinimumWageRule).isTipped
  )[0]?.rule;

  /**
   * tippedRuleConfig is also used by Minimum wage.
   * Clone this object and add stdMinWageAmount so we don't impact Minimum wage.
   *  */
  const res = { ...tippedRuleConfig };
  res.rule = {
    ...tippedRuleConfig?.rule,
    stdMinWageAmount: (nonTippedRule as MinimumWageRule)?.amount,
  };

  return res;
};

export const buildSchoolDayDefRuleConfig = (ruleConfigs: LaborRuleConfigData[]): LaborRuleConfigData => {
  const summerVacationConfig = ruleConfigs.filter(
    (ruleConfig) => ruleConfig?.rule.type === MinorRuleTypes.SummerVacationRule
  )[0];
  const summerVacationRule = summerVacationConfig.rule as MinorProhibitedHoursRule;
  const schoolDaysConfig = ruleConfigs.filter(
    (ruleConfig) => ruleConfig?.rule.type === MinorRuleTypes.MinorSchoolDaysRule
  )[0];
  const schoolDaysRule = schoolDaysConfig.rule as MinorProhibitedHoursRule;

  const res = {
    ...summerVacationConfig,
    ruleName: MinorRuleTypes.MinorSchoolDayDefinitionRule,
  };

  res.rule = {
    type: MinorRuleTypes.MinorSchoolDayDefinitionRule,
    end: summerVacationRule.end,
    start: summerVacationRule.start,
    schoolDays: schoolDaysRule.schoolDays,
    group: LaborRuleGroup.MINORS,
  } as unknown as LaborRule;

  return res;
};

export const getRuleTokens = (string: string) => {
  // This regex parses out parameters formatted like {{parameter}} from a string.
  const reg = /{{([^}]+)}}|[^{]+/g;
  return Array.from(string.matchAll(reg));
};

export const getDefaultPredPay = (): CustomLaborRuleConfigForm => ({
  rule: {
    type: SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule,
    rateAdjustment: null,
    hoursChange: null,
    // denesting this to help display logic, renested before sending to BE
    compensationType: CompensationTypes.RegularPayRate,
    threshold: null,
    effectiveRangeUpperBound: null,
    effectiveRangeLowerBound: 0,
    exemptions: [],
    group: LaborRuleGroup.SCHEDULING,
  } as unknown as LaborRule,
});

export const getDefaultTippedMinWage = (): CustomLaborRuleConfigForm => ({
  rule: {
    type: WagesRuleTypes.TippedMinimumWageRule,
    isTipped: true,
    group: LaborRuleGroup.WAGES_AND_TIPS,
  } as unknown as LaborRule,
});

export const getDefaultMinorSchoolDayDef = (): CustomLaborRuleConfigForm => ({
  rule: {
    type: MinorRuleTypes.MinorSchoolDayDefinitionRule,
    end: null,
    start: null,
    schoolDays: [],
    group: LaborRuleGroup.MINORS,
  } as unknown as LaborRule,
});

const predictabilityRules: Array<string> = [
  SchedulingRuleTypes.PredictabilityPayRule,
  SchedulingRuleTypes.ExtraHoursPredictabilityPayRule,
  SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule,
  SchedulingRuleTypes.FixedFeePredictabilityPayRule,
];

const minorSchoolDaysRules: Array<string> = [MinorRuleTypes.MinorSchoolDaysRule, MinorRuleTypes.SummerVacationRule];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildRightToRestEmptyRuleConfig = (ruleObj: any) => {
  const tempObj = ruleObj;
  tempObj.threshold = '';
  tempObj.paymentType = {
    type: RightToRestPaymentType.FixedAmount,
    amount: '',
  };
};

// Logic here to generate an empty rule config base on rule group and rule type
export const getEmptyRuleConfig = (
  template: string,
  rGroup: LaborRuleGroup | undefined,
  rType: string
): CustomLaborRuleConfigForm => {
  if (predictabilityRules.includes(rType)) {
    return getDefaultPredPay();
  }

  if (rType === WagesRuleTypes.TippedMinimumWageRule) {
    return getDefaultTippedMinWage();
  }

  if (minorSchoolDaysRules.includes(rType)) {
    return getDefaultMinorSchoolDayDef();
  }

  const ruleProperties = getRuleTokens(template)
    .map((match, i) => match[1])
    .filter((x) => !!x);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ruleObj: any = {
    type: rType,
    group: rGroup,
  };

  if (rType === SchedulingRuleTypes.RightToRestRule) {
    buildRightToRestEmptyRuleConfig(ruleObj);
  } else {
    ruleProperties.forEach((propertyName) => {
      ruleObj[propertyName] = '';
    });
  }
  return {
    rule: ruleObj,
  } as CustomLaborRuleConfigForm;
};

export const getJurisdictionRules = (
  scopeOrganizedRulesMap: Map<string, LaborRuleConfigData[]>,
  jurisdiction: string
) => [
  ...(scopeOrganizedRulesMap.get(jurisdiction) || []),
  ...(scopeOrganizedRulesMap.get(jurisdiction.split('.')[0]) || []),
];

export const getCurrentDateString = () => new Date().toISOString().split('T')[0]; // yyyy-mm-dd
export const getDateString = (date: string) => new Date(date).toISOString().split('T')[0];
export const getDateWithAddedDays = (days: number) => {
  const d = new Date();
  d.setDate(d.getDate() + days);
  return d;
};

const capitalizeFirstLetter = (input: string) => input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
const normalizeEuid = (euid: string | null | undefined) => euid && euid.replaceAll('-', '');
const isEffective = (config: LaborRuleConfigData) => config.effectiveDate <= getCurrentDateString();
export const getChangeReason = (matchingConfig: LaborRuleConfigData) =>
  matchingConfig?.changeReason?.toString() === RuleChangeReason.INITIALIZATION
    ? ''
    : matchingConfig?.changeReason || '';

export const getAllUpcomingRuleChangesetIds = (configs: LaborRuleConfigData[]) =>
  Array.from(
    new Set(
      configs
        .filter((config) => getDateString(config.effectiveDate) > getCurrentDateString())
        .map((config) => config.changesetId)
    )
  );

export const buildConfigsForTippedRules = (ruleConfigs: LaborRuleConfigData[]) => {
  // When dealing with TippedMinimumWage views, there must be a corresponding MinimumWageRule object where isTipped === false.
  const final: LaborRuleConfigData[] = [];
  const currentActiveNonTippedRule = ruleConfigs.find(
    (conf) => (conf.rule as MinimumWageRule).isTipped === false && conf.effectiveDate <= getCurrentDateString()
  );
  const allTippedMinWageRules = ruleConfigs.filter((x) => (x.rule as MinimumWageRule).isTipped === true);
  if (currentActiveNonTippedRule) {
    allTippedMinWageRules.forEach((conf) => {
      final.push(conf);
      final.push({ ...currentActiveNonTippedRule, effectiveDate: conf.effectiveDate, changesetId: conf.changesetId });
    });
  }
  return final;
};

export const groupRuleConfigsByEffectiveDate = (
  ruleConfigs: LaborRuleConfigData[]
): Dictionary<LaborRuleConfigData[]> => {
  const changeSets = chain(ruleConfigs).sortBy('effectiveDate').groupBy('effectiveDate').value();
  return changeSets;
};

export const organizeRuleConfigsByScope = (ruleConfigs: LaborRuleConfigData[]): Map<string, LaborRuleConfigData[]> => {
  const ruleConfigsMap = new Map();
  ruleConfigs.forEach((config) => {
    if (config.enterpriseUnitId) {
      const euidWithoutDash = config.enterpriseUnitId.replaceAll('-', '');
      // Site-specific rules
      ruleConfigsMap.get(euidWithoutDash)
        ? ruleConfigsMap.set(euidWithoutDash, [...ruleConfigsMap.get(euidWithoutDash), config])
        : ruleConfigsMap.set(euidWithoutDash, [config]);
    } else {
      // jurisdiction or locality level rule
      ruleConfigsMap.get(config.path)
        ? ruleConfigsMap.set(config.path, [...ruleConfigsMap.get(config.path), config])
        : ruleConfigsMap.set(config.path, [config]);
    }
  });
  return ruleConfigsMap;
};

export const organizeSites = (
  sites: JurisdictionSitesRow[],
  configs: LaborRuleConfigData[],
  jurisdiction: string,
  localityPath?: string
): Map<string, JurisdictionSitesRow[]> => {
  const sitesMap = new Map();
  sites.forEach((site) => {
    const customConfig = configs.filter(
      (rConfig) => rConfig.enterpriseUnitId?.replaceAll('-', '') === site.id && isEffective(rConfig)
    );
    if (customConfig.length) {
      // eslint-disable-next-line no-unused-expressions
      sitesMap.get('excluded')
        ? sitesMap.set('excluded', [...sitesMap.get('excluded'), { ...site, state: jurisdiction }])
        : sitesMap.set('excluded', [{ ...site, state: jurisdiction }]);
    } else if (localityPath && site.hierarchy[0] !== localityPath.split('.').at(-1)?.replaceAll('_', ' ')) {
      sitesMap.get('excluded')
        ? sitesMap.set('excluded', [...sitesMap.get('excluded'), { ...site, state: jurisdiction }])
        : sitesMap.set('excluded', [{ ...site, state: jurisdiction }]);
    } else {
      // eslint-disable-next-line no-unused-expressions
      sitesMap.get('affected')
        ? sitesMap.set('affected', [...sitesMap.get('affected'), { ...site, state: jurisdiction }])
        : sitesMap.set('affected', [{ ...site, state: jurisdiction }]);
    }
  });
  return sitesMap;
};

export const filterRuleConfigsForType = (conf: LaborRuleConfigData, type: string) => {
  if (type === WagesRuleTypes.TippedMinimumWageRule) {
    // for TippedMinimumWageRule, we use the same data from MinimumWageRule
    return conf.rule.type === WagesRuleTypes.MinimumWageRule;
    // eslint-disable-next-line no-else-return
  } else if (type === WagesRuleTypes.MinimumWageRule) {
    return conf.rule.type === WagesRuleTypes.MinimumWageRule && !(conf.rule as MinimumWageRule).isTipped;
  } else if (type === MinorRuleTypes.MinorSchoolDayDefinitionRule) {
    return [MinorRuleTypes.SummerVacationRule, MinorRuleTypes.MinorSchoolDaysRule].includes(
      conf.rule.type as MinorRuleTypes
    );
  } else if (type === SchedulingRuleTypes.PredictabilityPayRule) {
    return [
      SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule,
      SchedulingRuleTypes.ExtraHoursPredictabilityPayRule,
      SchedulingRuleTypes.FixedFeePredictabilityPayRule,
      SchedulingRuleTypes.PredictabilityPayRule,
    ].includes(conf.rule.type as SchedulingRuleTypes);
  }
  return conf.rule?.type === type;
};

export const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New_Hampshire',
  'New_Jersey',
  'New_Mexico',
  'New_York',
  'North_Carolina',
  'North_Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode_Island',
  'South_Carolina',
  'South_Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'Washington_DC',
  'West_Virginia',
  'Wisconsin',
  'Wyoming',
  'Seattle',
  'Indiana',
];

export const STATESTOTRANSFORM: string[] = ['Colorado', 'New_York', 'Rhode_Island'];

export const getLocalityUUID = (locality: string | undefined) =>
  (locality &&
    {
      United_States_of_America: `4cc1d061-5f35-56b5-9088-f15e26f78190`,
      Alabama: `51f43d9e-ec7a-5da2-85d0-69fd6013232c`,
      Alaska: `efe06037-98b1-5653-9df0-4957789d0934`,
      Arizona: `43fb308d-2fb3-5c52-b043-92924981802e`,
      Arkansas: `3c4c8a6e-5846-5d07-a717-6688e39b287d`,
      California: `a1e8e7e4-f914-52ef-827b-d9bad14d397e`,
      Colorado: `569ca23a-065c-5994-99a9-322a43033641`,
      Connecticut: `046e5fa1-e90f-5c47-9243-e0bc226ee2a7`,
      Delaware: `e5600f1c-7296-50fa-9a34-66cd7a8cba7e`,
      Florida: `28a3eedf-99c3-54a6-89de-2dcda04cfbe0`,
      Georgia: `ebba96bf-eae2-5c07-befa-5e466ce3ec33`,
      Hawaii: `ec427d0b-bd60-510d-88d8-416a437723f8`,
      Idaho: `cfa5f939-6e99-5bdf-90d4-6be37dca020b`,
      Illinois: `fa7834c6-4a6f-5123-bb65-5dc65cc35e3f`,
      Iowa: `dd8a4fee-e194-5f30-bfa8-78269152661a`,
      Kansas: `1a5f5ec7-6734-5995-9965-1fa723320be2`,
      Kentucky: `d88db52a-187c-50d4-bb24-3b39e8d550b8`,
      Louisiana: `2976409f-8b78-5b54-accc-c3b5f085dde3`,
      Maine: `e99de556-a46e-5f0c-936a-111cd9757ef5`,
      Maryland: `942059e2-2421-5370-bccc-ac17bba85df9`,
      Massachusetts: `35ee9abe-0597-5e13-ab7a-7c52d4963b67`,
      Michigan: `442cd6d4-d42e-5d53-94eb-8572e419aef2`,
      Minnesota: `08a60973-ee05-5468-b2e9-4f569cf0efad`,
      Mississippi: `858edf36-9d07-507f-abf2-e0dd6eab5f50`,
      Missouri: `17efb374-586e-5696-af44-491bac9ee244`,
      Montana: `5a421c0d-9475-525b-ba4d-9778c1ca6da1`,
      Nebraska: `3d003cf5-d852-5412-a7c5-90c40c3cf655`,
      Nevada: `317f8476-56cd-535c-85c6-32be0484234c`,
      New_Hampshire: `f5dbc5d8-49b6-5af7-bc65-1b9f9bd0fa12`,
      New_Jersey: `747bb108-c98a-59a4-b7c5-01b4000c8a61`,
      New_Mexico: `24433490-a511-50b6-b3e4-f168860739c0`,
      New_York: `89f8f8b1-f845-5dd3-a41f-dfe055f9d1be`,
      North_Carolina: `b6220efb-19a4-513d-8b65-5b1425232c51`,
      North_Dakota: `f02ad858-613d-52be-9152-3bfb0c8fe8e3`,
      Ohio: `8af021ce-3f93-53c8-8d60-1964d612db48`,
      Oklahoma: `2f02cccb-56f6-57fb-8ee6-401334241e3a`,
      Oregon: `05dd3a53-1ed8-5233-a799-192d71510ad6`,
      Pennsylvania: `8eb152d0-84b9-582d-b10a-0ab09d987b33`,
      Rhode_Island: `3e2342da-fb65-58ea-a5fe-28c17183fca2`,
      South_Carolina: `5774d851-50eb-57d4-9660-e9618a4fc7f8`,
      South_Dakota: `0b53096a-1c0e-59b9-b64b-05e7f8a233e0`,
      Tennessee: `70387f01-e532-5f89-bf53-c0dac472239a`,
      Texas: `035a69e1-1c08-578f-87b5-9dbb817a0a8e`,
      Utah: `7fe1aca9-9ed4-526f-a89a-9995f2bd6bbd`,
      Vermont: `bf1b9b38-d01b-5e6e-a8c8-db445d2df7ba`,
      Virginia: `5316f4a2-5b3c-5110-9d82-3f0c9a30c700`,
      Washington: `988f94a9-9166-555a-87b9-64a41826ffc0`,
      District_of_Columbia: `3095958e-c4ab-53c4-a2a6-71f67dd1f2c5`,
      West_Virginia: `a0bfe7e8-ecd3-5887-a444-1d789ebef5a3`,
      Wisconsin: `6410f9d7-c567-54b3-a97c-77a9e01253dd`,
      Wyoming: `b6187a99-ca5c-57cd-ba99-84a62f54e040`,
      Seattle: `6c70401e-41cd-558c-a466-a6895afd68ed`,
      Indiana: `f0470cf7-04d1-592a-9f1a-245d881b600e`,
      Flagstaff: `58191d07-3519-5798-836e-e71da7ec4060`,
      Tucson: `e51cfc74-49c4-5918-a5f5-7d3ff14f3407`,
      Alameda: `dd0b218d-fee6-5246-8e64-91b7184a4f5d`,
      Belmo: `ed063b8c-a9e8-58b4-88f7-f41d8e2b89f2`,
      Berkley: `cbe61673-3478-568f-9483-61df228b307c`,
      Burlingame: `11117072-f338-5866-b551-e4f45677402b`,
      Cupertino: `6405cffc-3c70-50ba-8463-1eb9fb8f3fa3`,
      Daly_City: `b2fabe60-f032-5919-b08b-8efcfa996791`,
      El_Cerrito: `d8e06d89-caa0-515b-9637-05774281f3ce`,
      Emeryville: `38f9fbc0-627c-516c-b7bf-5ecf686949c0`,
      Fremont: `f886f2fd-d874-5bfe-a88c-ce1053930490`,
      Foster_City: `6fbe2e24-4fd9-5285-bb0f-928704601d02`,
      Los_Altos: `8624eea7-241a-56f8-8ff3-532ab36d8870`,
      Los_Angeles: `f4283cb2-b9de-5301-bd1a-ea01adedf0ce`,
      Malibu: `f09a9a78-31a2-55ac-bdc4-d85bb3fe306b`,
      Menlo_Park: `29e9c4a6-7769-58a0-b555-04e4f7995485`,
      Miltipas: `6d79b7a3-2cd7-5e4e-ad0b-899d8880a5bc`,
      Mountain_View: `cbbcb5dd-fa3e-5871-afb8-a69806a49e63`,
      Oakland: `cd364c34-3f6c-584c-bc3e-1bdbc9c418fb`,
      Palo_Alto: `b55874af-8705-55e8-a50e-4f0bd1a2460f`,
      Pasadena: `22e29408-86a0-53c0-bcf4-e6cf6313db9d`,
      Redwood_City: `7d8e28ab-422d-5a4e-8d96-b0a140023b3e`,
      Richmond: `fe1522e7-ec24-5d5a-9915-32b4e7a289c7`,
      San_Carlos: `b15b71f0-0c7f-59c4-9bc6-f3f2d6bc73e7`,
      San_Diego: `872d0916-28b3-5ea2-be8f-72f77acd9fe0`,
      San_Fransisco: `f2238087-7d62-5374-b333-081e2bd3cae0`,
      San_Jose: `8499dcb4-4612-59ce-847d-8eb6656c0035`,
      San_Leandro: `667da98e-7942-5f54-8cbe-139cae9545dc`,
      San_Mateo: `74eef1cd-ae7c-56e1-a0e0-13ce90e79db7`,
      Santa_Clara: `77a0313f-04b2-5d73-be95-59bab15b7d0b`,
      Santa_Monica: `0df4edf1-6506-5e2f-ac7b-14423665c50c`,
      South_San_Fransisco: `e2735687-60e6-5897-a6e3-aaffde8b3b29`,
      Sunnyvale: `7982ae6d-5fb9-515e-a265-710cefaac721`,
      West_Hollywood: `dc4a5586-8a3a-5199-b3c3-880ad816c59e`,
      Chicago: `770c16a1-09fc-595b-bff7-cc5a9e9d495c`,
      Cook_County: `fc993c4d-d78e-5727-b0c1-c67c667525e5`,
      Portland: `97905808-5509-5327-a0de-eca59875f218`,
      Rockland: `973341cd-d110-536c-a8be-a6fa215e6002`,
      Montgomery_County: `dab8bc4c-5618-59a0-ac57-2a0673788931`,
      Minneapolis: `0af01c20-af89-566a-87e6-83287e0b94b3`,
      St_Paul: `c18229f7-da1a-5f31-9132-9588fe9744ae`,
      Santa_Fe: `d5516b10-f136-58c0-ba99-80652712cba6`,
      Santa_Fe_County: `86f8deef-5242-5619-a652-1ca84bca06c5`,
      Nassau_County: `9b0e4f69-9586-517f-8b89-f0853cd0d3ff`,
      New_York_City: `6dd7e498-9b9b-5720-b07e-e1eb9cbc6771`,
      Long_Island: `a4f91e33-8ed1-5d73-8880-36f756a414fe`,
      Westchester_County: `6f9b35f3-ff65-5440-afba-642827f876d8`,
      SeaTac: `baa3dcd5-7abd-5086-a6fa-78dbdba9f2df`,
      Puerto_Rico: `9822003c-e0d6-5e88-a5b8-5652d67f717a`,
      Guam: `43f35824-d7be-5df0-8435-977a7eebe6d5`,
      Philadelphia: `bf8bfa8d-47aa-5b38-99e0-bf510b9eb26c`,
    }[locality]) ||
  '';

export const getLocalityIdByState = (state: string) =>
  ({
    Arizona: ['Flagstaff', 'Tucson'],
    Virginia: ['Richmond'],
    California: [
      'Alameda',
      'Belmont',
      'Berkley',
      'Burlingame',
      'Cupertino',
      'Daly_City',
      'El_Cerrito',
      'Emeryville',
      'Fremont',
      'Foster_City',
      'Los_Altos',
      'Los_Angeles',
      'Malibu',
      'Menlo_Park',
      'Miltipas',
      'Mountain_View',
      'Oakland',
      'Palo_Alto',
      'Pasadena',
      'Redwood_City',
      'Richmond',
      'San_Carlos',
      'San_Diego',
      'San_Fransisco',
      'San_Jose',
      'San_Leandro',
      'San_Mateo',
      'Santa_Clara',
      'Santa_Monica',
      'South_San_Fransisco',
      'Sunnyvale',
      'West_Hollywood',
    ],
    Illinois: ['Chicago', 'Cook_County'],
    Maine: ['Rockland'],
    Maryland: ['Montgomery_County'],
    Minnesota: ['Minneapolis', 'St_Paul'],
    New_Mexico: ['Santa_Fe', 'Santa_Fe_County'],
    New_York: ['Nassau_County', 'New_York_City', 'Long_Island', 'Westchester_County'],
    Oregon: ['Portland'],
    Washington: ['SeaTac', 'Seattle'],
    Pennsylvania: ['Philadelphia'],
  }[state] || []);

export const flattenSites = (
  sitesByUnitId:
    | {
        [x: string]: JurisdictionSitesRow[];
      }
    | undefined
) => sitesByUnitId && Object.keys(sitesByUnitId).flatMap((id) => sitesByUnitId[id]);

export function isBreakRule(rule: LaborRule): rule is BreakRule {
  return (rule as BreakRule).isPaid !== undefined;
}

export function isSplitShiftRule(rule: LaborRule): rule is SplitShiftRule {
  return (rule as SplitShiftRule).type === SchedulingRuleTypes.SplitShiftRule;
}

export function isTippedMinWageRule(rule: LaborRule): rule is MinimumWageRule {
  return !!(rule as MinimumWageRule).isTipped;
}

export function isAdjustedRateRemainingTimePredictabilityPayRule(
  rule: LaborRule
): rule is AdjustedRateRemainingTimePredictabilityPayRule {
  return (
    (rule as AdjustedRateRemainingTimePredictabilityPayRule)?.type ===
    SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule
  );
}

export function isExtraHoursPredictabilityPayRule(rule: LaborRule): rule is ExtraHoursPredictabilityPayRule {
  return (rule as ExtraHoursPredictabilityPayRule)?.type === SchedulingRuleTypes.ExtraHoursPredictabilityPayRule;
}

export function isFixedFeePredictabilityPayRule(rule: LaborRule): rule is FixedFeePredictabilityPayRule {
  return (rule as FixedFeePredictabilityPayRule)?.type === SchedulingRuleTypes.FixedFeePredictabilityPayRule;
}

export function isPredictabilityPayRule(rule: LaborRule): rule is PredictabilityPayRule {
  return (
    isAdjustedRateRemainingTimePredictabilityPayRule(rule) ||
    isExtraHoursPredictabilityPayRule(rule) ||
    isFixedFeePredictabilityPayRule(rule)
  );
}

export function isRightToRestRule(rule: LaborRule): rule is RightToRestRule {
  return (rule as RightToRestRule).type === SchedulingRuleTypes.RightToRestRule;
}

export function isMinorProhibitedHoursRule(rule: LaborRule): rule is MinorProhibitedHoursRule {
  return (rule as MinorProhibitedHoursRule).type === MinorRuleTypes.MinorProhibitedHoursRule;
}

export function isMinorSchoolDaysRule(rule: LaborRule): rule is MinorProhibitedHoursRule {
  return (rule as MinorProhibitedHoursRule).type === MinorRuleTypes.MinorSchoolDaysRule;
}

export function isMinorSummerVacationRule(rule: LaborRule): rule is MinorProhibitedHoursRule {
  return (rule as MinorProhibitedHoursRule).type === MinorRuleTypes.SummerVacationRule;
}

export function isMinorSchoolDayDefRule(rule: LaborRule): rule is MinorProhibitedHoursRule {
  return (rule as MinorProhibitedHoursRule).type === MinorRuleTypes.MinorSchoolDayDefinitionRule;
}

export function isMinorDefinedSchoolDaysRule(rule: LaborRule): rule is MinorProhibitedHoursRule {
  return isMinorSchoolDaysRule(rule) || isMinorSummerVacationRule(rule) || isMinorSchoolDayDefRule(rule);
}

export function isEnterpriseGroupResponse(response: void | unknown): response is EnterpriseGroupResponse {
  return !!(response as EnterpriseGroupResponse)?.enterpriseUnits;
}

export const getPredictabilityPayTemplates = (rule: PredictabilityPayRule, t: TFunction<'translation', undefined>) => {
  const { type } = rule;
  const ifTemplate = `laborRules.rules.${type}.template.if.${
    rule.hoursChange === HoursChange.UNCHANGED ? 'date' : 'minutes'
  }`;
  let thenTemplate = '';
  switch (type) {
    case SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule:
    case SchedulingRuleTypes.ExtraHoursPredictabilityPayRule:
      if (isExtraHoursPredictabilityPayRule(rule) || isAdjustedRateRemainingTimePredictabilityPayRule(rule)) {
        const compensationType = rule?.compensationType?.type || rule?.compensationType;
        thenTemplate = `laborRules.rules.${type}.template.then.${
          compensationType === CompensationTypes.ThresholdedPayRate ? 'withWageThreshold' : 'withoutWageThreshold'
        }`;
      }
      break;
    case SchedulingRuleTypes.FixedFeePredictabilityPayRule:
      thenTemplate = `laborRules.rules.${rule.type}.template.then`;
      break;
    default:
      thenTemplate = `laborRules.rules.${type}.template.then.${
        (rule as AdjustedRateRemainingTimePredictabilityPayRule)?.compensationType?.type ===
        CompensationTypes.ThresholdedPayRate
          ? 'withWageThreshold'
          : 'withoutWageThreshold'
      }`;
      break;
  }
  return ifTemplate && thenTemplate ? [t(ifTemplate), t(thenTemplate)] : ['', ''];
};

export const getTemplateString = (
  rule: LaborRule,
  t: TFunction<'translation', undefined>,
  ruleGuidance?: boolean,
  locality?: string,
  jurisdictionPath?: string,
  isStateGuidance?: boolean
) => {
  let template = '';

  const localityName = locality?.split('.')[1] ?? jurisdictionPath?.split('.')[1] ?? '';

  const ruleTemplateRestBreakJurisdictions = ['California', 'Colorado', 'Minnesota', 'Washington'];
  const ruleTemplateMealBreakJurisdictions = ['Illinois', 'Vermont', 'Wisconsin', 'New_York', 'Rhode_Island'];
  const isRuleTemplateStateGuidance = isStateGuidance && ['Wisconsin', 'Washington'].includes(localityName);

  const ruleSubject = rule.isSubject ? 'isSubject' : 'isNotSubject';

  if (rule?.type) {
    const { type } = rule;
    switch (type) {
      case WagesRuleTypes.MinimumWageRule:
        template = ruleGuidance
          ? t(`laborRules.rules.${WagesRuleTypes.TippedMinimumWageRule}.ruleGuidance.template`)
          : t(`laborRules.rules.${type}.template`);
        break;

      case BreakRuleTypes.RestBreakRule: {
        template = t(`laborRules.rules.${type}.template.${ruleSubject}`);
        if (ruleTemplateRestBreakJurisdictions.includes(localityName))
          template = t(`laborRules.rules.${type}.template.${localityName.toLocaleLowerCase()}.${ruleSubject}`);
        if (localityName === 'Minnesota' && !isStateGuidance)
          template = t(`laborRules.rules.${type}.template.${ruleSubject}`);
        if (localityName === 'California' && isStateGuidance)
          template = t(`laborRules.rules.${type}.template.${localityName.toLocaleLowerCase()}.stateGuidance`);
        break;
      }
      case BreakRuleTypes.MealBreakRule: {
        template = t(`laborRules.rules.${type}.template.${ruleSubject}`);
        if (ruleTemplateMealBreakJurisdictions.includes(localityName))
          template = t(`laborRules.rules.${type}.template.${localityName.toLocaleLowerCase()}.${ruleSubject}`);
        if (isRuleTemplateStateGuidance)
          template = t(`laborRules.rules.${type}.template.${localityName.toLocaleLowerCase()}.stateGuidance`);
        if (localityName === 'Vermont')
          template = t(`laborRules.rules.${type}.template.${localityName.toLocaleLowerCase()}`);
        break;
      }
      // This formatting for these is only for the ruleGuidance drawer, use the ConditionalTemplate to render them otherwise
      case SchedulingRuleTypes.PredictabilityPayRule:
      case SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule:
      case SchedulingRuleTypes.ExtraHoursPredictabilityPayRule:
      case SchedulingRuleTypes.FixedFeePredictabilityPayRule: {
        if (isPredictabilityPayRule(rule)) {
          const [ifTemplate, thenTemplate] = getPredictabilityPayTemplates(rule, t);
          template = `${t('laborRules.rulesGuidance.if')} ${ifTemplate} ${t(
            'laborRules.rulesGuidance.then'
          )} ${thenTemplate}`;
        }
        break;
      }
      case SchedulingRuleTypes.SplitShiftRule:
        template = `${t(`laborRules.rulesGuidance.if`)} ${t(`laborRules.rules.SplitShiftRule.template.if`)} ${t(
          `laborRules.rulesGuidance.then`
        )} ${t(`laborRules.rules.SplitShiftRule.template.then`)}`;
        break;
      case SchedulingRuleTypes.RightToRestRule: {
        /* eslint-disable no-case-declarations */
        const rightToRestRule = rule as RightToRestRule;
        let key = 'fixedAmount';
        if (rightToRestRule.paymentType?.type) {
          if (
            locality &&
            rightToRestWithPeriodLocalities.includes(locality) &&
            rightToRestRule.paymentType.type === RightToRestPaymentType.PayRateAdjustment
          ) {
            key = 'rateAdjustmentWithPeriod';
          } else {
            key =
              rightToRestRule.paymentType.type === RightToRestPaymentType.PayRateAdjustment
                ? 'rateAdjustment'
                : 'fixedAmount';
          }
        }
        template = t(`laborRules.rules.RightToRestRule.template.${key}`);
        break;
      }
      default:
        template = t(`laborRules.rules.${type}.template`);
    }
  }
  return template;
};

getTemplateString.defaultProps = {
  locality: undefined,
  isStateGuidance: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformBooleanFormValues = (config: any) => {
  if (Array.isArray(config) && config.length > 0) {
    config.forEach((item) => {
      const { rule, id } = item;
      if (id) {
        BOOLEAN_FIELDS.forEach((field) => {
          if (Object.hasOwn(rule, field) && typeof rule[field] === 'boolean') {
            rule[field] = rule[field] ? 1 : 0;
          }
          if (Object.hasOwn(rule.optionalRule ?? {}, field) && typeof rule?.optionalRule[field] !== 'number') {
            rule.optionalRule[field] = rule.optionalRule[field] ? 1 : 0;
          }
        });
      }
    });
  }

  return config;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformPayInHours = (config: any) => {
  const { rule, path } = config;

  const state = path?.split('.')[1];
  if (!rule?.isPayInHoursTransformed && rule?.makeupPayInHours && PAY_HOURS_TRANS_STATES.includes(state)) {
    rule.makeupPayInHours = convertHoursToMinutes(rule.makeupPayInHours);
    rule.isPayInHoursTransformed = true;
  }

  return config;
};
// === COMMON DATA TRANSFORMATION UTILS ===
// Any custom logic needed to update rule configurations to work with a labor rule form
// This also adds fields needed for conditional rendering of templates in rule guidance and labor card
export const mutateConfigsForRuleForm = (configs: LaborRuleConfigData[]) =>
  configs.map((config) => {
    const { rule } = transformPayInHours(config);
    if (isBreakRule(rule)) {
      rule.isSubject = rule.makeupPayInHours > 0;
      if (dayjs(rule.startTime, 'HH:mm:ss', true).isValid()) {
        rule.startTime = dayjs(rule.startTime, 'HH:mm:ss').format('HH:mm');
        rule.endTime = dayjs(rule.endTime, 'HH:mm:ss').format('HH:mm');
      }
      if (rule.optionalRule) {
        rule.optIsPaid = rule.optionalRule[0].isPaid;
        rule.optThreshold = rule.optionalRule[0].threshold;
        rule.optDuration = rule.optionalRule[0].duration;
        rule.optionalRule = undefined;
      }
    } else if (isMinorProhibitedHoursRule(rule)) {
      rule.schoolInSession = !rule.isSummer;
    }
    return config;
  });

// Any custom logic needed to update rule configurations to be used for rendering into LaborRuleTemplates
export const preProcessRuleConfigsForTemplates = (configs: LaborRuleConfigData[], ruleType: string) => {
  let configsToBuildTemplatesFrom = configs;
  // The Tipped Minimum Wage rule needs both MinimumWage and MinimumWage.Tipped to render a complete labor rule template.
  // Checks that 'configs' is a list of WagesRuleType rules (aka both MinimumWage and MinimumWage.Tipped) when processing for MinimumWage.Tipped
  if (configs && configs.length > 1 && ruleType === WagesRuleTypes.TippedMinimumWageRule) {
    configsToBuildTemplatesFrom = [buildTippedMinimumWageRuleConfig(configs)];
  }
  if (configs && configs.length > 1 && ruleType === MinorRuleTypes.MinorSchoolDayDefinitionRule) {
    configsToBuildTemplatesFrom = [buildSchoolDayDefRuleConfig(configs)];
  }
  return configsToBuildTemplatesFrom;
};

// Logic to transform rules into the expected values for the Labor Rules Service
export const mutateRuleFormData = (ruleForms: CustomLaborRuleConfigForm[]): CustomLaborRuleConfigForm[] =>
  ruleForms.map((form) => {
    const { rule } = form;
    // evaluating makeupPayInHours based on FE only property isSubject.
    if (isBreakRule(rule)) {
      if (!rule.isSubject) {
        rule.makeupPayInHours = 0;
      }
      // we don't want to send this to the backend
      delete rule.isSubject;
    } else if (isMinorProhibitedHoursRule(rule)) {
      rule.isSummer = !rule.schoolInSession;
      delete rule.schoolInSession;
    } else if (isSplitShiftRule(rule)) {
      // FE uses minutes. BE uses seconds.
      rule.splitThresholdSeconds *= 60;
    } else if (isPredictabilityPayRule(rule)) {
      rule.threshold *= 60;
      if (isAdjustedRateRemainingTimePredictabilityPayRule(rule) || isExtraHoursPredictabilityPayRule(rule)) {
        if (!rule.compensationType.type)
          rule.compensationType = {
            type: rule.compensationType as unknown as CompensationTypes,
            lowerLimit: rule.lowerLimit,
            effectivePayRate: rule.effectivePayRate,
          };
      }
    } else if (isRightToRestRule(rule) && rule.paymentType.type === RightToRestPaymentType.FixedAmount) {
      delete rule.paymentType.period;
      delete rule.paymentType.rateAdjustment;
    } else if (isRightToRestRule(rule) && rule.paymentType.type === RightToRestPaymentType.PayRateAdjustment) {
      delete rule.paymentType.amount;

      // Right to rest rules with no period dropdown imply that period is 'Overlap'
      rule.paymentType.period = rule.paymentType.period ?? RightToRestTypePeriod.Overlap;
    }
    return form;
  });

// Logic to transform changeset form into the expected shape and values for the POST endpoint request body
export const mutateData = (
  data: RuleFormValues,
  effectiveDate: string,
  changeReason: RuleChangeReason,
  defaultRuleId: number | null
): CreateChangeSetRequest => {
  const mutatedRuleForms = mutateRuleFormData(data.rules);
  return {
    ...data,
    rules: mutatedRuleForms.map((form) => form.rule),
    effectiveDate,
    changeReason,
    defaultRuleId,
  };
};

// ==== Build Form Context Default Values ====
export const getRuleFormValues = (
  template: string,
  configs: LaborRuleConfigData[],
  ruleGroup: LaborRuleGroup,
  ruleType: string
): CustomLaborRuleConfigForm[] => {
  let createdConfigs;
  if (configs.length > 1 && ruleType === WagesRuleTypes.TippedMinimumWageRule) {
    const { id, rule } = buildTippedMinimumWageRuleConfig(configs);
    const customLaborRuleConfigForm: CustomLaborRuleConfigForm = {
      id,
      rule,
    };
    createdConfigs = [customLaborRuleConfigForm];
  } else if (configs.length > 1 && ruleType === MinorRuleTypes.MinorSchoolDayDefinitionRule) {
    const { id, rule } = buildSchoolDayDefRuleConfig(configs);
    const customLaborRuleConfigForm: CustomLaborRuleConfigForm = {
      id,
      rule,
    };
    createdConfigs = [customLaborRuleConfigForm];
  } else if (configs.length > 0) {
    createdConfigs = configs.map((config) => {
      /**
       * This function is called multiple times due to re-rendering
       * Cloning config.rule to prevent rule.splitThresholdSeconds from being divided multiple times
       */
      const res = {
        id: config.id,
        rule: { ...config.rule },
      };

      // FE uses minutes. BE uses seconds.
      if (ruleType === SchedulingRuleTypes.SplitShiftRule) {
        (res.rule as SplitShiftRule).splitThresholdSeconds = Math.round(
          (config.rule as SplitShiftRule).splitThresholdSeconds / 60
        );
      }
      if (isPredictabilityPayRule(config.rule)) {
        (res.rule as PredictabilityPayRule).threshold = Math.round(config.rule.threshold / 60);
        if (
          isAdjustedRateRemainingTimePredictabilityPayRule(config.rule) ||
          isExtraHoursPredictabilityPayRule(config.rule)
        ) {
          const rule = res.rule as AdjustedRateRemainingTimePredictabilityPayRule;
          res.rule = {
            ...rule,
            compensationType: rule.compensationType.type,
            lowerLimit: rule.compensationType.lowerLimit,
            effectivePayRate: rule.compensationType.effectivePayRate,
          } as LaborRule;
        }
      }
      return res;
    });
  } else {
    createdConfigs = [getEmptyRuleConfig(template, ruleGroup, ruleType)];
  }
  return mutateConfigsForRuleForm(createdConfigs as LaborRuleConfigData[]);
};

// ==== Sites Table ====
export const enrichSiteRows = (
  sites: JurisdictionSitesRow[] | undefined,
  ruleConfigs: LaborRuleConfigData[] | undefined,
  jurisdictionPath: string,
  t: TFunction<'translation', undefined>,
  isUserLREWriter: boolean,
  onEditRule?: (stateOverrides?: Record<string, unknown>) => void
) =>
  sites?.map((site) => {
    // 1 - federal, 2 - state, 3 - locality
    const getRuleConfigHierarchyLevel = (rule: LaborRuleConfigData): number => rule.path.split('.').length;

    const effectiveRuleConfigsWithHierarchyLevel = ruleConfigs?.filter(isEffective).map((rule) => ({
      ...rule,
      hierarchyLevel: getRuleConfigHierarchyLevel(rule),
    }));

    const getMostRecentRule = (rules: LaborRuleConfigData[] | undefined): LaborRuleConfigData | undefined => {
      let mostRecentRule: LaborRuleConfigData | undefined;
      rules?.forEach((rule) => {
        if (!mostRecentRule || rule.effectiveDate > mostRecentRule.effectiveDate) {
          mostRecentRule = rule;
        }
      });
      return mostRecentRule;
    };

    const customConfig = getMostRecentRule(
      effectiveRuleConfigsWithHierarchyLevel?.filter(
        (config) => normalizeEuid(config.enterpriseUnitId) === normalizeEuid(site.id)
      )
    );
    const countryConfig = getMostRecentRule(
      effectiveRuleConfigsWithHierarchyLevel?.filter(
        (config) => config.hierarchyLevel === 1 && !config.enterpriseUnitId
      )
    );
    const stateConfig = getMostRecentRule(
      effectiveRuleConfigsWithHierarchyLevel?.filter(
        (config) => config.hierarchyLevel === 2 && !config.enterpriseUnitId
      )
    );
    const localityConfig = getMostRecentRule(
      effectiveRuleConfigsWithHierarchyLevel?.filter(
        (config) => config.hierarchyLevel === 3 && config.path === jurisdictionPath
      )
    );
    const [siteRuleConfig, configuration] =
      (customConfig && [customConfig, ConfigurationTypes.CUSTOM]) ||
      (localityConfig && [localityConfig, ConfigurationTypes.LOCALITY]) ||
      (stateConfig && [stateConfig, ConfigurationTypes.STATE]) ||
      (countryConfig && [countryConfig, ConfigurationTypes.FEDERAL]) ||
      [];

    return {
      ...siteRuleConfig,
      ...site,
      configuration: capitalizeFirstLetter(configuration || ConfigurationTypes.FEDERAL),
      state: jurisdictionPath.split('.').at(-1)?.replaceAll('_', ' '),
      changeReason: siteRuleConfig ? getChangeReason(siteRuleConfig) : '',
      action: (
        <Tooltip title={t('laborRules.tooltips.editSite')}>
          <span>
            <Button
              disabled={isUserLREWriter}
              data-testid={`sites-table-edit-button-${site.id}`}
              onClick={
                onEditRule
                  ? () => onEditRule({ referenceId: site.referenceId, siteName: site.siteName, siteId: site.id })
                  : undefined
              }
            >
              <Edit sx={{ m: 'auto' }} />
            </Button>
          </span>
        </Tooltip>
      ),
    };
  }) || [];

export const getNumbers = (from: number, to: number) => {
  const res = [];
  // eslint-disable-next-line no-plusplus
  for (let i = from; i <= to; i++) res.push(i);

  return res;
};

export const getRuleGroup = (ruleType: string): LaborRuleGroup | undefined => {
  if (Object.values(OvertimeRuleTypes).includes(ruleType as OvertimeRuleTypes)) return LaborRuleGroup.OVERTIME;
  if (Object.values(MinorRuleTypes).includes(ruleType as MinorRuleTypes)) return LaborRuleGroup.MINORS;
  if (Object.values(BreakRuleTypes).includes(ruleType as BreakRuleTypes)) return LaborRuleGroup.BREAKS;
  if (Object.values(WagesRuleTypes).includes(ruleType as WagesRuleTypes)) return LaborRuleGroup.WAGES_AND_TIPS;
  if (Object.values(SchedulingRuleTypes).includes(ruleType as SchedulingRuleTypes)) return LaborRuleGroup.SCHEDULING;
  return undefined;
};

export const getSplitShiftRuleDescription = (t: TFunction<'translation', undefined>) => {
  const splitShiftRuleLink = 'https://www.dir.ca.gov/dlse/split_shift.htm';
  return (
    <>
      <Typography component='span'>{t('laborRules.rules.SplitShiftRule.editViewDescription')}</Typography>{' '}
      <Link href={splitShiftRuleLink}>{splitShiftRuleLink}</Link>
    </>
  );
};

export const isChangesSetCustomRules = (configs: LaborRuleConfigData[]) => {
  const defaultFound = configs.find((config) => config.defaultRuleId != null);
  if (defaultFound) return false;
  return true;
};

export const areDefaultRules = (
  defaultRuleConfigs: LaborRuleConfigData[],
  ruleConfigsToCheck: LaborRuleConfigData[]
) => {
  if (defaultRuleConfigs && defaultRuleConfigs.length > 0) {
    return defaultRuleConfigs.some((ruleConfig) =>
      ruleConfigsToCheck.some((customRuleConfig) => _.isEqual(ruleConfig.rule, customRuleConfig.rule))
    );
  }

  return false;
};

export const getDefaultConfigsByRuleTypeAndJurisdictionPath = (
  listOfDefaultRuleConfigs: LaborRuleConfigData[],
  ruleType: string,
  jurisdictionPath: string
) => {
  const defaultRuleConfigs = preProcessRuleConfigsForTemplates(
    listOfDefaultRuleConfigs.filter(
      (ruleConfig) =>
        ruleConfig.rule?.type === ruleType &&
        (ruleConfig.path === jurisdictionPath || ruleConfig.path === 'United_States_of_America')
    ),
    ruleType
  );
  return defaultRuleConfigs;
};

export const splitPredPayChangesetRequest = (reqBody: CreateChangeSetRequest): CreateChangeSetRequest[] => {
  const changesetHash: { [x: string]: LaborRule[] } = {};

  reqBody.rules.forEach((rule) => {
    const currentType = changesetHash[rule.type];
    if (currentType) {
      currentType.push(rule);
    } else {
      changesetHash[rule.type] = [rule];
    }
  });
  const changeSets: CreateChangeSetRequest[] = [];
  Object.keys(changesetHash).forEach((key) => {
    changeSets.push({ ...reqBody, ruleType: key, rules: changesetHash[key] });
  });
  return changeSets;
};

export const splitSchoolDayDefChangesetRequest = (reqBody: CreateChangeSetRequest): CreateChangeSetRequest[] => {
  const changesetHash: { [x: string]: LaborRule[] } = {};

  reqBody.rules.forEach((rule) => {
    const ruleType = rule.type || 'MinorSchoolDaysRule';
    const currentType = changesetHash[ruleType];
    if (currentType) {
      currentType.push(rule);
    } else {
      changesetHash[ruleType] = [{ ...(rule as LaborRule), group: LaborRuleGroup.MINORS, type: ruleType }];
    }
  });
  const changeSets: CreateChangeSetRequest[] = [];
  Object.keys(changesetHash).forEach((key) => {
    changeSets.push({ ...reqBody, ruleType: key, rules: changesetHash[key] });
  });
  return changeSets;
};

export const getLocalityByLocationStateAndSites = (
  locationState: Record<string, string>,
  sites: JurisdictionSitesRow[] | undefined
) => {
  const localityPath = locationState?.localityPath;
  if (localityPath) {
    return localityPath.split('.').at(-1);
  }

  const siteId = locationState?.siteId;
  if (siteId) {
    return sites?.find((site) => site.id === siteId)?.hierarchy[0];
  }

  return undefined;
};

export const getValueByRegExpMatchArray = (match: RegExpMatchArray, rule: LaborRule | ChangeType) => {
  if (['lowerLimit', 'effectivePayRate'].includes(match[1])) {
    return (
      (rule as AdjustedRateRemainingTimePredictabilityPayRule).compensationType[match[1] as keyof CompensationType] ||
      false
    );
  }
  if (isRightToRestRule(rule as LaborRule) && match[1] !== 'threshold') {
    const { paymentType } = rule as RightToRestRule;
    return paymentType[match[1].split('.').at(1) as keyof typeof paymentType] ?? '';
  }
  return rule[match[1] as keyof typeof rule];
};

export const getFullDate = (
  date: string | null,
  withYear: boolean,
  t: TFunction<'translation', undefined>
): string | null => {
  if (!date) return null;
  const formattedDayMonth = valueFormatter(date, 'start', '', t);

  const year = new Date().getFullYear();
  return withYear ? `${formattedDayMonth}/${year}` : `${formattedDayMonth}`;
};

export const transformData = (original: RuleFormValues, state: string) => {
  const transformRule = (ruleObj: CustomLaborRuleConfigForm) => {
    const { optThreshold, optDuration, optIsPaid, ...rest } = ruleObj.rule as BreakRule;
    const commonRule = {
      id: ruleObj.id,
      rule: { ...rest },
    };

    if (state === TransStates.New_York) {
      commonRule.rule.startTime = dayjs(rest.startTime, 'HH:mm').format('HH:mm:ss');
      commonRule.rule.endTime = dayjs(rest.endTime, 'HH:mm').format('HH:mm:ss');
    } else if (state === TransStates.Rhode_Island) {
      commonRule.rule.optionalRule = [
        {
          isPaid: optIsPaid,
          threshold: optThreshold,
          duration: optDuration,
          condition: 'OR',
        },
      ];
    } else if (state === TransStates.Colorado) {
      const { rule } = commonRule;
      rule.makeupPayInHours = convertMinutesToHours(rule.makeupPayInHours);
      delete rule.isPayInHoursTransformed;
    }

    return commonRule;
  };

  return {
    ...original,
    rules: original.rules.map(transformRule),
  };
};
