import Close from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrgContext } from '../CommonComponentWrapper';
import OrganizationAllOrgsList from './OrganizationAllOrgsList';
import { OrganizationDialogProvider } from './OrganizationDialogProvider';

const MobileTransition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction='up' ref={ref} {...props} />
);

const WebTransition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Fade ref={ref} {...props} />
);

function OrganizationSwitcherDialogInner() {
  const theme = useTheme<Theme>();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const { isOrgDialogOpen, setIsOrgDialogOpen } = useOrgContext();

  const handleDialogClose = () => {
    setIsOrgDialogOpen(false);
  };

  return (
    <Dialog
      open={isOrgDialogOpen}
      onClose={handleDialogClose}
      TransitionComponent={isMobileView ? MobileTransition : WebTransition}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '8px',
          [theme.breakpoints.up('sm')]: {
            maxHeight: '80%',
          },
          [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
          },
          width: '450px',
        },
        '.MuiList-root': {
          padding: '0px 12px 12px 12px',
        },
      }}
    >
      <Box padding='24px'>
        <DialogTitle sx={{ padding: 0 }} variant='h6'>
          {t('mockOrgSwitcher.menu.title')}
        </DialogTitle>
      </Box>
      <IconButton
        title={t('mockOrgSwitcher.accessibility.close')}
        onClick={handleDialogClose}
        sx={{
          position: 'absolute',
          top: '12px',
          right: '16px',
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ padding: '0px 12px 12px 12px' }}>
        <OrganizationAllOrgsList />
      </DialogContent>
    </Dialog>
  );
}

function OrganizationSwitcherDialog() {
  const { setIsOrgDialogOpen, updateOrganization } = useOrgContext();

  return (
    <OrganizationDialogProvider setIsOrgDialogOpen={setIsOrgDialogOpen} updateOrganization={updateOrganization}>
      <OrganizationSwitcherDialogInner />
    </OrganizationDialogProvider>
  );
}

export default OrganizationSwitcherDialog;
