import { Theme, SxProps } from '@mui/material/styles';
import { APPBAR_HEIGHT } from './AppBar';

export const outerBox = (theme: Theme): SxProps<Theme> => ({
  display: 'flex',
  padding: 2,
  position: 'sticky',
  top: 0,
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 2,
  width: '100%',
  height: APPBAR_HEIGHT,
  zIndex: '1000',
  backgroundColor: theme.palette.background.default,
});

export const menuIcon: SxProps<Theme> = {
  height: '20px',
  width: '20px',
};

export const authenticatedActions = (isMobile: boolean): SxProps<Theme> => ({
  display: 'flex',
  alignItems: 'center',
  gap: isMobile ? 1 : 3,
});

export const notificationsIcon: SxProps<Theme> = {
  '&:hover': {
    background: '#F2F3F4',
  },
  '&:focus-visible': {
    background: '#D1D1D1',
  },
};

export const loginButton: SxProps<Theme> = {
  ml: 1,
  mr: 1,
};
