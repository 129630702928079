import { CboRole } from '@cbo/shared-library';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  DataGridPremiumProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_CHECKBOX_SELECTION_FIELD,
  GRID_TREE_DATA_GROUPING_FIELD,
  GetApplyQuickFilterFn,
  GridCellParams,
  GridColDef,
  GridComparatorFn,
  GridFilterItem,
  GridFilterOperator,
  GridRenderCellParams,
  GridRowId,
  GridRowSelectionModel,
  GridValidRowModel,
  getGridStringOperators,
  gridFilteredSortedRowIdsSelector,
  gridStringOrNumberComparator,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { difference, isNil } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useRef, useState, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import BulkActionDataGrid from '../../components/BulkActionDataGrid/BulkActionDataGrid';
import CustomActionsMenuCell, {
  DataGridActionsMenuItems,
} from '../../components/DataGrid/CustomCells/CustomActionsMenuCell';
import CustomActiveCell from '../../components/DataGrid/CustomCells/CustomActiveCell';
import CustomTreeGroupCell from '../../components/DataGrid/CustomCells/CustomTreeGroupCell';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { useUsers } from '../../contexts/userContext';
import isPermitted from '../../lib/permissions';
import { DynamicButtonInfo } from '../../models/DynamicButtonInfo';
import { useUpdateIsActiveGlAccountsMutation } from '../requests/mutations';
import { GlAccount, GlAccountsResponse } from '../requests/requests';
import { BodyOverflowError } from '../types';
import { GlAccountRows } from './GeneralLedgerAccountsManageReport';
import GeneralLedgerAccountsSidebar, { GlAccountsSidebarState } from './GeneralLedgerAccountsSidebar';

interface GlAccountCellValue {
  accountNumber: number;
  accountName: string;
}

type GeneralLedgerAccountsDataGridProps = {
  isGlAccountRowOpen: boolean;
  setIsGlAccountRowOpen: Dispatch<SetStateAction<boolean>>;
  sidebarState: GlAccountsSidebarState;
  setSidebarState: Dispatch<SetStateAction<GlAccountsSidebarState>>;
  glAccounts: GlAccountsResponse | undefined;
  isError: boolean;
  isLoading: boolean;
  refetchGlAccounts: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GlAccountsResponse | undefined, BodyOverflowError>>;
};

function GeneralLedgerAccountsDataGrid(props: GeneralLedgerAccountsDataGridProps) {
  const {
    isGlAccountRowOpen,
    setIsGlAccountRowOpen,
    sidebarState,
    setSidebarState,
    glAccounts,
    isError,
    isLoading,
    refetchGlAccounts,
  } = props;
  const theme = useTheme();
  const user = useUsers();
  const { t } = useTranslation();
  const { setSnackbarState } = useSnackbar();

  // api requests
  const { mutateAsync: updateIsActiveGlAccounts } = useUpdateIsActiveGlAccountsMutation();
  const handleRefetch = async () => {
    await refetchGlAccounts();
  };

  // data grid
  const apiRef = useGridApiRef();
  const [selectedRow, setSelectedRow] = useState<GlAccountRows | undefined>(undefined);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [showDeletionDialog, setShowDeletionDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [isDeactivate, setIsDeactivate] = useState<boolean | undefined>(undefined);
  const [isHideInInvoicing, setIsHideInInvoicing] = useState<boolean | undefined>(undefined);
  const [bulkActionAccountTree, setBulkActionAccountTree] = useState<GlAccount[] | undefined>(undefined);
  const [fromActionsMenu, setFromActionsMenu] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [nextRow, setNextRow] = useState<GlAccountRows | undefined>(undefined);
  const [attemptingEditWhileDirty, setAttemptingEditWhileDirty] = useState(false);

  const handleClose = () => {
    setIsGlAccountRowOpen(false);
    setSelectedRow(undefined);
    setSidebarState(GlAccountsSidebarState.VIEW);
    handleRefetch();
  };

  const createHierarchy = useCallback((account: GlAccountRows, hierarchy: string[]): GlAccountRows[] => {
    const subAccounts: GlAccountRows[] = [];
    account.subAccounts.forEach((subAccount) => {
      const rowAccount = {
        ...subAccount,
        hierarchy: [...hierarchy, subAccount.accountId],
        id: subAccount.accountId,
      };
      subAccounts.push(rowAccount, ...createHierarchy(rowAccount, [...hierarchy, subAccount.accountId]));
    });
    return subAccounts;
  }, []);

  const glAccountsDataToRows = useCallback((): GlAccountRows[] => {
    const accounts: GlAccountRows[] = [];

    if (!isNil(glAccounts) && !isError && !isLoading) {
      glAccounts.accounts.forEach((account) => {
        const rowAccount = { ...account, hierarchy: [account.accountId], id: account.accountId };
        accounts.push(rowAccount, ...createHierarchy(rowAccount, [account.accountId]));
      });
    }

    return accounts;
  }, [glAccounts, isError, isLoading, createHierarchy]);

  const handleCellClick = (params: GridCellParams) => {
    if (params.field === GRID_CHECKBOX_SELECTION_FIELD) {
      setSelectedRow(undefined);
      setIsGlAccountRowOpen(false);
    }
    if (params.field !== 'actions' && params.field !== GRID_CHECKBOX_SELECTION_FIELD) {
      if (isDirty) {
        setAttemptingEditWhileDirty(true);
        setNextRow(params.row);
      } else {
        setSidebarState(GlAccountsSidebarState.VIEW);
        if (selectedRow?.id === params.row.id && isGlAccountRowOpen) {
          setIsGlAccountRowOpen(false);
          setSelectedRow(undefined);
        } else {
          setSelectedRow(params.row);
          setIsGlAccountRowOpen(true);
        }
      }
    }
  };

  const onSelectionModelChange = (model: GridRowSelectionModel) => {
    const selectedIds = difference(model, selectionModel);
    const unselectedIds = difference(selectionModel, model);
    let newSelectionModel: GridRowId[] = [];
    if (selectedIds.length > 0) {
      let selectedRowsForModel: GridRowId[] = [];
      if (selectedIds.length === 1) {
        const rowChildren = apiRef.current.getRowGroupChildren({ groupId: selectedIds[0] });
        if (rowChildren.length > 0) {
          const expandedFilteredRowIds = new Set(gridFilteredSortedRowIdsSelector(apiRef));
          selectedRowsForModel = [selectedIds[0], ...rowChildren.filter((id) => expandedFilteredRowIds.has(id))];
        } else {
          selectedRowsForModel = selectedIds;
        }
        newSelectionModel = [...selectionModel, ...selectedRowsForModel];
      } else {
        newSelectionModel = gridFilteredSortedRowIdsSelector(apiRef);
      }
    } else if (unselectedIds.length > 0) {
      let unselectedRowsForModel: GridRowId[] = [];
      if (unselectedIds.length === 1) {
        const unselectedRow = apiRef.current.getRow(unselectedIds[0]);
        if (unselectedRow) {
          const { hierarchy } = unselectedRow;
          const rowChildren = apiRef.current.getRowGroupChildren({ groupId: unselectedIds[0] });
          if (hierarchy.length > 1 || rowChildren.length > 0) {
            hierarchy.pop();
            unselectedRowsForModel = [...unselectedRowsForModel, unselectedIds[0], ...hierarchy, ...rowChildren];
          } else {
            unselectedRowsForModel = unselectedIds;
          }
          newSelectionModel = selectionModel.filter((el) => !unselectedRowsForModel.includes(el));
        } else {
          newSelectionModel = selectionModel.filter((el) => !unselectedIds.includes(el));
        }
      } else {
        newSelectionModel = [];
      }
    } else {
      newSelectionModel = selectionModel as GridRowId[];
    }
    const uniqueSelectionModel = newSelectionModel.filter((value, index, array) => array.indexOf(value) === index);
    setSelectionModel(uniqueSelectionModel);
    return uniqueSelectionModel;
  };

  // actions column
  const actionsTooltipTitles: Partial<Record<DataGridActionsMenuItems, string>> = {
    [DataGridActionsMenuItems.Edit]: t('admin.glAccountConfiguration.generalLedgerAccounts.actionTooltip', {
      action: t('admin.glAccountConfiguration.actionContent.editTitle'),
    }),
    [DataGridActionsMenuItems.Delete]: t('admin.glAccountConfiguration.generalLedgerAccounts.actionTooltip', {
      action: t('admin.glAccountConfiguration.actionContent.deleteTitle'),
    }),
    [DataGridActionsMenuItems.Deactivate]: t('admin.glAccountConfiguration.generalLedgerAccounts.activationTooltip', {
      action: t('admin.glAccountConfiguration.generalLedgerAccounts.activated'),
    }),
    [DataGridActionsMenuItems.Activate]: t('admin.glAccountConfiguration.generalLedgerAccounts.activationTooltip', {
      action: t('admin.glAccountConfiguration.generalLedgerAccounts.deactivated'),
    }),
  };

  const handleUpdateIsActiveSuccess = (account: GlAccount) => {
    handleClose();
    setSnackbarState({
      open: true,
      message: (
        <Trans
          i18nKey='admin.glAccountConfiguration.actionContent.successMessageNoChildren'
          values={{
            glAccountNumber: account.accountNumber,
            glAccountName: account.accountName,
            action: t('admin.glAccountConfiguration.actionContent.updated'),
          }}
          components={{ primary: <Typography sx={{ fontWeight: '500' }} display='inline' /> }}
        />
      ),
      color: 'success',
    });
  };

  const handleActivate = async (params: GridRenderCellParams) => {
    const result = await updateIsActiveGlAccounts({
      isActive: true,
      isActiveAffectedAccountIds: [params.row.accountId],
    });
    if (result === true) {
      handleUpdateIsActiveSuccess(params.row as GlAccount);
    }
  };

  const prevIsDirty = useRef(false);
  prevIsDirty.current = isDirty;

  const handleEditClicked = (params: GridRenderCellParams) => {
    if (prevIsDirty.current) {
      setAttemptingEditWhileDirty(true);
      setNextRow(params.row);
    } else {
      setSelectedRow(params.row);
      setSidebarState(GlAccountsSidebarState.EDIT);
      setIsGlAccountRowOpen(true);
    }
  };

  const handleUnsavedDialogClosed = () => {
    if (attemptingEditWhileDirty) {
      setSelectedRow(nextRow);
      setSidebarState(GlAccountsSidebarState.EDIT);
      setIsGlAccountRowOpen(true);
    }
    setAttemptingEditWhileDirty(false);
  };

  const actionsOnClickFunctions = (
    params: GridRenderCellParams
  ): Partial<Record<DataGridActionsMenuItems, () => void>> => ({
    [DataGridActionsMenuItems.Edit]: () => {
      handleEditClicked(params);
    },
    [DataGridActionsMenuItems.Activate]: () => {
      if (params.row.subAccounts.length === 0) {
        handleActivate(params);
      } else {
        setBulkActionAccountTree([params.row as GlAccount]);
        setFromActionsMenu(true);
        setIsDeactivate(false);
        setIsHideInInvoicing(undefined);
        setShowEditDialog(true);
      }
    },
    [DataGridActionsMenuItems.Deactivate]: () => {
      setFromActionsMenu(true);
      setBulkActionAccountTree([params.row as GlAccount]);
      setIsDeactivate(true);
      setIsHideInInvoicing(undefined);
      setShowEditDialog(true);
    },
    [DataGridActionsMenuItems.Delete]: () => {
      setFromActionsMenu(true);
      setBulkActionAccountTree([params.row as GlAccount]);
      setShowDeletionDialog(true);
    },
  });

  const columns: GridColDef[] = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
      type: 'string',
    },
    {
      field: 'categoryName',
      headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.glCategory'),
      pinnable: false,
      minWidth: 200,
    },
    {
      field: 'accountType',
      headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.glType'),
      pinnable: false,
      minWidth: 100,
    },
    {
      field: 'accountTypeDetail',
      headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.glDetailType'),
      pinnable: false,
      minWidth: 200,
    },
    {
      field: 'description',
      headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.description'),
      pinnable: false,
      minWidth: 150,
    },
    {
      field: 'isActive',
      headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.active'),
      pinnable: false,
      renderCell: (params) => <CustomActiveCell isActive={params.row.isActive} />,
      headerAlign: 'center',
      minWidth: 100,
    },
    {
      field: 'isDisplayedInInvoicing',
      headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.showForInvoicing'),
      pinnable: false,
      minWidth: 175,
      renderCell: (params) => <CustomActiveCell isActive={params.row.isDisplayedInInvoicing} />,
      headerAlign: 'center',
    },
    {
      field: 'actions',
      sortable: false,
      type: 'string',
      filterable: false,
      headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.actions'),
      pinnable: false,
      disableReorder: true,
      disableExport: true,
      headerAlign: 'center',
      align: 'center',
      resizable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <CustomActionsMenuCell
          isActive={params.row.isActive}
          actions={[
            DataGridActionsMenuItems.Edit,
            DataGridActionsMenuItems.Activate,
            DataGridActionsMenuItems.Deactivate,
            DataGridActionsMenuItems.Delete,
          ]}
          onClickFunctions={actionsOnClickFunctions(params)}
          tooltipTitles={actionsTooltipTitles}
        />
      ),
    },
  ];

  // grouped column definition
  const glAccountSortComparator: GridComparatorFn = (v1, v2, param1, param2) => {
    const accountComparatorResult = gridStringOrNumberComparator(
      (v1 as GlAccountCellValue).accountNumber,
      (v2 as GlAccountCellValue).accountNumber,
      param1,
      param2
    );

    if (accountComparatorResult !== 0) {
      return accountComparatorResult;
    }

    return gridStringOrNumberComparator(
      (v1 as GlAccountCellValue).accountName,
      (v2 as GlAccountCellValue).accountName,
      param1,
      param2
    );
  };

  const getTextForRowGroupingColumn = (params: GridRenderCellParams) => {
    const { accountName, accountNumber } = params.row;
    const text = t('admin.glAccountConfiguration.generalLedgerAccounts.glAccountTitle', {
      glAccountNumber: accountNumber,
      glAccountName: accountName,
    });
    return {
      cellText: text,
    };
  };

  const getApplyQuickFilter: GetApplyQuickFilterFn<GridValidRowModel, GlAccountCellValue> =
    (value: string) => (cellValue: GlAccountCellValue) => {
      const fullAccount = `${cellValue.accountNumber} - ${cellValue.accountName}`;
      return fullAccount.includes(value);
    };

  const groupedColumnFilterOperator: GridFilterOperator[] = [
    {
      value: getGridStringOperators()[0].value,
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }

        return (value: GlAccountCellValue): boolean => {
          const fullAccount = `${value.accountNumber} - ${value.accountName}`;
          return fullAccount.includes(filterItem.value);
        };
      },
      InputComponent: getGridStringOperators()[0].InputComponent,
    },
  ];

  const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
    headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.glAccount'),
    valueGetter: (value: string, row: GlAccountRows) => ({
      accountNumber: row.accountNumber,
      accountName: row.accountName,
    }),
    valueFormatter: (value: string, row: GlAccountRows) => `${row.accountNumber} - ${row.accountName}`,
    hideDescendantCount: true,
    minWidth: 400,
    filterable: true,
    sortable: true,
    disableExport: false,
    filterOperators: groupedColumnFilterOperator,
    hideable: false,
    sortComparator: glAccountSortComparator,
    sortingOrder: ['asc', 'desc'],
    renderCell: (params: GridRenderCellParams) => (
      <CustomTreeGroupCell
        {...{
          ...params,
          ...getTextForRowGroupingColumn(params),
        }}
      />
    ),
    getApplyQuickFilterFn: getApplyQuickFilter,
  };

  // bulk actions
  const getSubaccountsForAction = (removedIds: GridRowId[], rowData: GlAccount, selectionIds: GridRowId[]) => {
    const tempSubaccounts: GlAccount[] = [];
    rowData.subAccounts.forEach((subAccount) => {
      if (selectionIds.includes(subAccount.accountId)) {
        removedIds.push(subAccount.accountId);
        tempSubaccounts.push({
          ...subAccount,
          subAccounts: getSubaccountsForAction(removedIds, subAccount, selectionIds),
        });
      }
    });
    return tempSubaccounts;
  };

  const getTopParentAccount = (row: GlAccount | null): GlAccount | undefined => {
    if (row) {
      if (!row.parentGLAccount) {
        return row;
      }
      const parentRow = getTopParentAccount(apiRef.current.getRow(row.parentGLAccount.accountId));
      return parentRow;
    }
    return undefined;
  };

  const getAccountTree = (row: GlAccount, ids: GridRowId[], removedIds: GridRowId[]) => {
    const accounts: GlAccount[] = [];
    if (ids.includes(row.accountId)) {
      accounts.push({ ...row, subAccounts: getSubaccountsForAction(removedIds, row, ids) } as GlAccount);
      removedIds.push(row.accountId);
    } else {
      row.subAccounts.forEach((child) => accounts.push(...getAccountTree(child, ids, removedIds)));
    }
    return accounts;
  };

  const getAccountsForActions = (ids: GridRowId[]) => {
    const rowElements: GlAccountRows[] = ids
      .map((s) => apiRef.current.getRow(s))
      .sort((a, b) => a.accountNumber - b.accountNumber);
    const accountsForDialog: GlAccount[] = [];
    const removedIds: GridRowId[] = [];
    rowElements.forEach((row) => {
      if (!removedIds.includes(row.accountId)) {
        const rowChildren = apiRef.current.getRowGroupChildren({ groupId: row.accountId });
        if (rowChildren.length === 0 && !row.parentGLAccount) {
          accountsForDialog.push(row as GlAccount);
        } else {
          const topParentAccount = getTopParentAccount(row as GlAccount);
          if (topParentAccount) {
            accountsForDialog.push(...getAccountTree(topParentAccount, ids, removedIds));
          } else {
            accountsForDialog.push(...getAccountTree(row as GlAccount, ids, removedIds));
          }
        }
      }
    });
    return accountsForDialog;
  };

  const handleBulkUpdate = async (accountTree: GlAccount[]) => {
    const result = await updateIsActiveGlAccounts({
      isActive: true,
      isActiveAffectedAccountIds: [accountTree[0].accountId],
    });
    if (result === true) {
      handleUpdateIsActiveSuccess(accountTree[0]);
    }
  };

  const bulkActionButtons: DynamicButtonInfo[] = [
    {
      dataTestId: 'gl-account-bulk-activate-button',
      text: t('buttonText.activate'),
      icon: <CheckCircleIcon />,
      functionality: (selection: GridRowId[]) => {
        const accountTree = getAccountsForActions(selection);
        if (accountTree.length === 1 && accountTree[0].subAccounts.length === 0) {
          handleBulkUpdate(accountTree);
        } else {
          setBulkActionAccountTree(accountTree);
          setIsDeactivate(false);
          setIsHideInInvoicing(undefined);
          setShowEditDialog(true);
        }
      },
    },
    {
      dataTestId: 'gl-account-bulk-deactivate-button',
      text: t('buttonText.deactivate'),
      icon: <CancelIcon />,
      functionality: (selection: GridRowId[]) => {
        const accountTree = getAccountsForActions(selection);
        setBulkActionAccountTree(accountTree);
        setIsDeactivate(true);
        setIsHideInInvoicing(undefined);
        setShowEditDialog(true);
      },
    },
    {
      dataTestId: 'gl-account-bulk-delete-button',
      text: t('buttonText.delete'),
      icon: <DeleteIcon />,
      functionality: (selection: GridRowId[]) => {
        const accountTree = getAccountsForActions(selection);
        setBulkActionAccountTree(accountTree);
        setShowDeletionDialog(true);
      },
    },
  ];

  const getPath: DataGridPremiumProps['getTreeDataPath'] = useMemo(() => (row) => row.hierarchy, []);

  return (
    <>
      <BulkActionDataGrid
        apiRef={apiRef}
        buttons={bulkActionButtons}
        loading={isLoading}
        columns={columns}
        rows={glAccountsDataToRows()}
        getTreeDataPath={getPath}
        treeData
        disableColumnSelector
        disableVirtualization
        groupingColDef={groupingColDef}
        pinnedColumns={{ right: ['actions'] }}
        disableExport={false}
        data-testid='gl-accounts-datagrid'
        sortingOrder={['desc', 'asc']}
        initialState={{
          sorting: {
            sortModel: [{ field: GRID_TREE_DATA_GROUPING_FIELD, sort: 'asc' }],
          },
        }}
        treeCustomSelectionFunction={onSelectionModelChange}
        customResetActions={() => {
          setSelectionModel([]);
          setBulkActionAccountTree(undefined);
        }}
        rowSelectionModel={selectionModel}
        onCellClick={(params: GridCellParams) => {
          handleCellClick(params);
        }}
        checkboxSelection={isPermitted(user, [CboRole.GL_ACCOUNTS_WRITE])}
        disableSelectionOnClick
        getRowClassName={({ id }) => (selectedRow?.id === id ? 'focused-row' : '')}
        sx={{
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .focused-row': {
            backgroundColor: alpha(theme.palette.primary.main, theme.palette.mode === 'light' ? 0.12 : 0.16),
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, theme.palette.mode === 'light' ? 0.14 : 0.24),
            },
          },
        }}
        columnVisibilityModel={{ actions: isPermitted(user, [CboRole.GL_ACCOUNTS_WRITE]) }}
      />
      <GeneralLedgerAccountsSidebar
        isOpen={isGlAccountRowOpen}
        onClose={handleClose}
        glAccount={selectedRow}
        sidebarState={sidebarState}
        setSidebarState={setSidebarState}
        handleRefetch={handleRefetch}
        bulkActionAccountTree={bulkActionAccountTree}
        setBulkActionAccountTree={setBulkActionAccountTree}
        showDeletionDialog={showDeletionDialog}
        setShowDeletionDialog={setShowDeletionDialog}
        showEditDialog={showEditDialog}
        setShowEditDialog={setShowEditDialog}
        isHideInInvoicing={isHideInInvoicing}
        setIsHideInInvoicing={setIsHideInInvoicing}
        isDeactivate={isDeactivate}
        setIsDeactivate={setIsDeactivate}
        fromActionsMenu={fromActionsMenu}
        setFromActionsMenu={setFromActionsMenu}
        setDirty={setIsDirty}
        attemptingEditWhileDirty={attemptingEditWhileDirty}
        handleUnsavedDialogClosed={handleUnsavedDialogClosed}
        setAttemptingEditWhileDirty={setAttemptingEditWhileDirty}
      />
    </>
  );
}

export default GeneralLedgerAccountsDataGrid;
