import { UserProfileSettings as RealUserProfile } from '@ncr-voyix-commerce/react-common-components';
import MockUserProfile from './MockUserProfile/MockUserProfile';

/**
 * In order to accommodate the new user profile component within preview URLs,
 * we need to conditionally use a 'fake' user profile component that doesn't attempt
 * to make provisioning API requests, based on an environment variable
 * which should only be set to true during the CI pipeline.
 */
// eslint-disable-next-line import/no-mutable-exports
let UserProfileSettings = RealUserProfile;

if (process.env.REACT_APP_USE_PREVIEW_ORG_SWITCHER === 'true') {
  UserProfileSettings = MockUserProfile;
}

export default UserProfileSettings;
