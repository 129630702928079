import i18next from 'i18next';
import { CboRole, PersonaRole } from '@cbo/shared-library';
import isPermitted from '../../../lib/permissions';
import { useUsers } from '../../../contexts/userContext';
import routes from '../../../constants/routes';
import { NavItem } from '.';
import { loggedInAsPersona } from '../../../lib/persona';

export default function useAccountRoutes(): NavItem[] {
  const user = useUsers();

  const accountRoutes: NavItem[] = [];

  // commented for the time being to confirm user preference migration.
  // accountRoutes.push({
  //   title: i18next.t('navItems.accountPreference'),
  //   href: routes.ACCOUNT_PREFERENCES,
  //   testid: 'account-preference-menu-item',
  // });

  // if (isPermitted(user, [CboRole.NOTIFICATION_GROUP_EDIT])) {
  //   accountRoutes.push({
  //     title: i18next.t('navItems.notificationSettings'),
  //     href: routes.NOTIFICATION_SETTINGS,
  //     testid: 'notification-settings-menu-item',
  //   });
  // }

  if (
    isPermitted(user, [CboRole.EMPLOYEE_PERSONAL_EDIT]) &&
    loggedInAsPersona(user, [PersonaRole.EMPLOYEE, PersonaRole.MANAGER, PersonaRole.ASSISTANT_MANAGER])
  ) {
    accountRoutes.push({
      title: i18next.t('navItems.myInformation'),
      href: routes.EMPLOYEE_REGISTRATION_HOME,
      testid: 'employee-registration-module',
    });
  }

  return accountRoutes;
}
