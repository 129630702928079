import { Route, Switch, useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { CboRole } from '@cbo/shared-library';
import LaborRulesMainView, { LaborRulesMainViewProps } from '../LaborRulesMainView/LaborRulesMainView';
import LaborRuleEditView, { LaborRuleEditViewProps } from '../LaborRuleEditView/LaborRuleEditView';
import routes from '../../constants/routes';
import {
  useDefaultLaborRulesConfigurationsQuery,
  useGetJurisdictionsQuery,
  useGetLruaQuery,
  useLaborRulesForEnterpriseQuery,
  useSitesByEnterpriseUnitGroup,
} from '../requests/queries';
import { getLocalityIdByState, organizeRuleConfigsByScope } from '../utils/utils';
import UserAgreementModal from '../UserAgreementModal/UserAgreementModal';
import { LaborRuleConfigData } from '../models/LaborRuleConfiguration';
import { useUsers } from '../../contexts/userContext';
import isPermitted from '../../lib/permissions';
import { useWorkWeekConfigQuery } from '../../labor/Scheduling/requests/queries';

interface LaborRuleRouteConfig {
  key: string;
  path: string;
  children: JSX.Element;
}

export default function LaborRulesPage() {
  const [lruaModalOpen, setLruaModalOpen] = useState(false);
  const [hasSignedLrua, setHasSignedLrua] = useState(false);
  // TODO: Future enhancement work to get path ids from rules service should fix this.
  const defaultJurisdiction = 'United_States.Alabama';
  const defaultPath = 'United_States_of_America.Alabama';
  const [jurisdictionPath, setJurisdictionPath] = useState(defaultPath); // Represents string for query
  const [selectedJurisdiction, setSelectedJurisdiction] = useState(defaultJurisdiction); // String in shared countryAndRegions list
  const [scopeOrganizedRules, setRuleConfigMap] = useState<Map<string, LaborRuleConfigData[]>>(new Map());
  const pathSegments = jurisdictionPath.split('.');
  const state = pathSegments.at(-1) || '';
  const history = useHistory();
  const user = useUsers();
  const isUserLREWriter = isPermitted(user, [CboRole.LRE_RULE_WRITER]);
  const effectiveHasSignedLrua = isUserLREWriter ? hasSignedLrua : true;

  const bslId = user.org?.bslId;
  // Queries
  const { data: defaultRuleConfigs, isLoading: defaultRuleConfigsLoading } = useDefaultLaborRulesConfigurationsQuery(
    jurisdictionPath,
    effectiveHasSignedLrua,
    bslId
  );
  const {
    data: lruaRecord,
    isLoading: lruaRecordLoading,
    isError: lruaFetchError,
    refetch: refetchLrua,
  } = useGetLruaQuery();

  const {
    data: orgRuleConfigs,
    refetch: refetchConfigs,
    isFetching: configsFetching,
    isLoading: orgRuleConfigsLoading,
  } = useLaborRulesForEnterpriseQuery(jurisdictionPath, effectiveHasSignedLrua, bslId);

  const [configsBeingFetched, setConfigsBeingFetched] = useState(configsFetching || orgRuleConfigsLoading);
  const { data: sitesByUnitId } = useSitesByEnterpriseUnitGroup(
    [state, ...getLocalityIdByState(state)],
    effectiveHasSignedLrua,
    bslId
  );
  const sites = sitesByUnitId?.flat().filter((site) => site?.id);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { data: jurisdictionData, refetch: refetchJurisdictionData } = useGetJurisdictionsQuery();
  const { data: workWeekConfigData } = useWorkWeekConfigQuery(user.fullyAuthenticated === 'authenticated');

  const laborRulesMainViewProps: LaborRulesMainViewProps = {
    setLruaModalOpen,
    selectedJurisdiction,
    setSelectedJurisdiction,
    jurisdictionPath,
    setJurisdictionPath,
    defaultRuleConfigs,
    defaultRuleConfigsLoading,
    orgRuleConfigsLoading: configsBeingFetched,
    lruaRecordLoading,
    orgRuleConfigs,
    sites,
    refetchConfigs,
    jurisdictionData,
    refetchJurisdictionData,
  };

  const laborRuleEditViewProps: LaborRuleEditViewProps = {
    jurisdictionPath,
    orgRuleConfigsLoading,
    sites,
    scopeOrganizedRules,
    orgRuleConfigs: orgRuleConfigs || [],
    refetchConfigs,
    allDefaultRuleConfigs: defaultRuleConfigs ?? [],
    workWeekConfig: workWeekConfigData,
  };

  useEffect(() => {
    setConfigsBeingFetched(configsFetching || orgRuleConfigsLoading);
  }, [configsFetching, orgRuleConfigsLoading]);

  useEffect(() => {
    if (!lruaRecordLoading) {
      setHasSignedLrua(!!lruaRecord?.acceptanceDate);
      setLruaModalOpen(!lruaRecord?.acceptanceDate || lruaFetchError);
    }
  }, [lruaRecord, lruaFetchError, lruaRecordLoading]);

  useEffect(() => {
    if (orgRuleConfigs && !orgRuleConfigsLoading) {
      setRuleConfigMap(organizeRuleConfigsByScope(orgRuleConfigs));
    }
  }, [orgRuleConfigs, orgRuleConfigsLoading]);

  const LABOR_RULE_FOCUS_VIEWS: LaborRuleRouteConfig[] = [
    {
      key: 'labor-rules-edit-view',
      path: routes.LABOR_RULES_EDIT,
      children: <LaborRuleEditView {...laborRuleEditViewProps} />,
    },
    {
      key: 'labor-rules-landing-view',
      path: routes.LABOR_RULES,
      children: <LaborRulesMainView {...laborRulesMainViewProps} />,
    },
  ];

  const createLaborRulesRoute = (laborRulesRoute: LaborRuleRouteConfig): JSX.Element => (
    <Route key={laborRulesRoute.key} path={laborRulesRoute.path}>
      {laborRulesRoute.children}
    </Route>
  );

  return (
    <Grid container data-testid='labor-rules-home'>
      {lruaModalOpen && isUserLREWriter && (
        <UserAgreementModal
          lruaRecord={lruaRecord}
          onClose={() => {
            setLruaModalOpen(false);
            if (!hasSignedLrua) {
              history.push('/');
            }
          }}
          onSave={() => {
            setLruaModalOpen(false);
            refetchLrua();
          }}
        />
      )}
      <Grid item sm={12} px={isMobile ? 2 : 4} justifyContent='flex-end' width='100%'>
        <Switch>{LABOR_RULE_FOCUS_VIEWS.map((route) => createLaborRulesRoute(route))}</Switch>
      </Grid>
    </Grid>
  );
}
