import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import { MultiselectOption } from '@cbo/shared-library';

export type TabBarProps = {
  tabs: string[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  alphabetize?: boolean;
};

function TabBar({ tabs, activeTab, setActiveTab, alphabetize }: Readonly<TabBarProps>) {
  const theme = useTheme();
  const sortedTabs = alphabetize ? [...tabs].sort((a, b) => a.localeCompare(b)) : tabs;
  return (
    <Tabs
      sx={{
        marginBottom: '12px',
        marginRight: '16px',
        marginLeft: '16px',
      }}
      onChange={(e, newTab) => setActiveTab(newTab)}
      value={activeTab}
    >
      {sortedTabs.map((tab) => (
        <Tab
          sx={{
            '&.Mui-focusVisible': {
              backgroundColor: theme.palette.grey[300],
            },
          }}
          data-testid={tab}
          label={tab}
          key={tab}
          value={tab}
        />
      ))}
    </Tabs>
  );
}

export const getUniqueTabs = (options: MultiselectOption[]): string[] =>
  options.map((group) => (group.tab ? group.tab : '')).filter((value, index, self) => self.indexOf(value) === index);

TabBar.defaultProps = {
  alphabetize: false,
};

export default TabBar;
