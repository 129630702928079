const routes = {
  // APP ROUTES
  LANDING_PAGE: '/welcome',
  HOME_PAGE: '/',
  NOT_AUTHORIZED: '/not-authorized',

  // ADMIN ROUTES
  ACCOUNT_PREFERENCES: '/account/account-preferences',

  SITES: '/settings/sites',
  SITE_SETTINGS: '/settings/site-settings',

  SITE_GROUPS: '/settings/site-groups',
  SITE_GROUP_CREATE: '/settings/site-groups/create',
  SITE_GROUP_EDIT: '/settings/site-groups/:id/edit',

  SITE_TAGS: '/settings/site-tags',
  SITE_TAGS_ASSIGN: '/settings/site-tags/assign',
  SITE_TAGS_REMOVE: '/settings/site-tags/remove',
  SITE_TAGS_CREATE: '/settings/site-tags/create',
  SITE_TAGS_EDIT: '/settings/site-tags/:id/edit',

  FISCAL_CALENDAR: '/settings/fiscal-calendar',
  PAYROLL_CALENDAR: '/settings/payroll-calendar',

  DAY_PARTS: '/settings/day-parts',

  NOTIFICATION_SETTINGS: '/account/notification-settings',
  NOTIFICATION_SETTINGS_CREATE: '/account/notification-settings/create',
  NOTIFICATION_SETTINGS_EDIT: '/account/notification-settings/:id/edit',

  EDIT_COMPANY_LINKS: '/edit-company-links',

  ACTIVITY_LOG: '/activity-log',
  // --

  EMPLOYEE: '/employee',
  MANAGER: '/manager',
  LOGIN: '/login/callback',
  USER: '/labor/user',
  TEST_FORM: '/test-form',
  LABOR_MODULE: '/labor/:laborPage',
  LABOR_RULES: '/settings/labor/labor-rules',
  LABOR_SETTINGS_SCHEDULE: '/settings/labor/labor-settings-schedule',
  LABOR_RULES_EDIT: '/settings/labor/labor-rules/:ruleType/edit',
  MY_SCHEDULE: '/my-schedule',
  EMPLOYEE_REGISTRATION_HOME: '/account/registration',
  PERSONAL_INFORMATION_FORM: '/account/registration/personal-information',
  EMERGENCY_CONTACT_FORM: '/account/registration/emergency-contact',
  EMPLOYEE_INFORMATION_FORM: '/account/registration/employee-information',
  EMPLOYEE_CERTIFICATION_FORM: '/account/registration/employee-certification',
  EMPLOYEE_PAGE: '/labor/employees',
  SCHEDULING_MANAGER: '/labor/scheduling/scheduling-manager',
  EMPLOYEE_OVERVIEW_BASE: '/labor/employees/overview',
  EMPLOYEE_OVERVIEW_FOCUSMODE_BASE: '/labor/employees/overview', // will be cleaned up in ASM-5029
  EMPLOYEE_OVERVIEW_PAGE: '/labor/employees/overview/:employeeId/',
  EMPLOYEE_OVERVIEW_ALL_ROUTES: '/labor/employees/overview/:employeeId/:tab',
  EMPLOYEE_GENERAL_INFORMATION_PAGE: '/labor/employees/overview/:employeeId/profile',
  EMPLOYEE_CERTIFICATION_PAGE: '/labor/employees/overview/:employeeId/certification',
  EMPLOYEE_EMPLOYMENT_INFORMATION_PAGE: '/labor/employees/overview/:employeeId/employmentInformation',
  EMPLOYEE_JOBCODES_PAGE: '/labor/employees/overview/:employeeId/jobcodes',
  EMPLOYEE_PERMISSIONS_PAGE: '/labor/employees/overview/:employeeId/permissionsAndAccess',
  JOBCODE_CONFIGURATION_PAGE: '/settings/labor/jobconfiguration',
  JOBCODE_CONFIGURATION_FOCUSMODE_BASE: '/settings/labor/jobconfiguration/job',
  JOBCODE_CONFIGURATION_OVERVIEW_PAGE: '/settings/labor/jobconfiguration/job/:jobcodeId',
  JOBCODE_CONFIGURATION_DETAILS: '/settings/labor/jobconfiguration/job/:jobcodeId/details',
  JOBCODE_EMPLOYEES_FOCUSMODE_BASE: '/settings/labor/jobconfiguration/job',
  JOBCODE_EMPLOYEES_OVERVIEW_PAGE: '/settings/labor/jobconfiguration/job/:jobcodeId/employees',
  APPROACHING_OVERTIME_THRESHOLD: '/labor/reports/approaching-overtime-threshold',
  EMPLOYEE_ACA_THRESHOLD_REPORT: '/labor/reports/aca-threshold-report',
  EMPLOYEE_BREAK: '/labor/reports/employee-break',
  GENERIC_PAYROLL_EXPORT: '/labor/reports/generic-payroll-export',
  EMPLOYEE_SALES_PERFORMANCE: '/labor/reports/employee-sales-performance',
  EMPLOYEE_TIP_REPORT: '/labor/reports/employee-tip-report',
  EMPLOYEE_PAYROLL_SUMMARY_REPORT: '/labor/reports/employee-payroll-summary-report',
  GENERIC_PAYROLL_EXPORT_REPORT: '/labor/reports/generic-payroll-export',
  INTERVAL_SALES_AND_LABOR: '/labor/reports/interval-sales-labor-report',
  SHIFT_TRACKER: '/labor/shift-tracker',
  PUNCH_SUMMARY: '/labor/payroll/punch-summary',
  INVENTORY_MODULE: '/inventory/:inventoryPage',
  INVOICES: '/inventory/invoices',
  INVOICE_HISTORY_REPORT: '/inventory/invoice-history-report',
  UNFINALIZED_VENDORS_REPORT: '/inventory/invoice-history-report/unfinalized-vendors',
  UNFINALIZED_GL_ACCOUNTS_REPORT: '/inventory/invoice-history-report/unfinalized-gl-accounts',
  ADD_INVOICE: '/inventory/invoices/add',
  UPLOAD_INVOICE: '/inventory/invoices/upload/:invoiceUploadJobId',
  UPLOAD_INVOICE_INFO_MAPPING: '/inventory/invoices/upload/:invoiceUploadJobId/info-mapping',
  UPLOAD_INVOICE_COLUMN_MAPPING: '/inventory/invoices/upload/:invoiceUploadJobId/column-mapping',
  UPLOAD_INVOICE_ROW_MAPPING: '/inventory/invoices/upload/:invoiceUploadJobId/row-mapping',
  UPLOAD_INVOICE_TOTALS: '/inventory/invoices/upload/:invoiceUploadJobId/totals',
  EDIT_INVOICE_BASE: '/inventory/invoices/edit/',
  EDIT_INVOICE: '/inventory/invoices/edit/:invoiceId',
  VIEW_INVOICE_BASE: '/inventory/invoices/view/',
  VIEW_INVOICE: '/inventory/invoices/view/:invoiceId',
  SALES: '/sales/:salesPage',
  SALES_REFRESH: '/sales/refresh',
  DEMO_ITEMS: '/demo',
  VENDORS: '/inventory/vendors',
  ADD_VENDOR: '/inventory/vendors/add',
  EDIT_VENDOR_BASE: '/inventory/vendors/edit/',
  EDIT_VENDOR: '/inventory/vendors/edit/:vendorId',
  VIEW_VENDOR_BASE: '/inventory/vendors/view/',
  VIEW_VENDOR: '/inventory/vendors/view/:vendorId',
  RAW_ITEMS: '/inventory/raw-items',
  ADD_RAW_ITEM: '/inventory/raw-items/add',
  EDIT_RAW_ITEM: '/inventory/raw-items/:rawItemId/general-information',
  EDIT_RAW_ITEM_FOCUS_MODE: '/inventory/raw-items/:rawItemId/general-information-focus-mode',
  VIEW_RAW_ITEM_FOCUS_MODE: '/inventory/raw-items/:rawItemId/general-information-focus-mode-view',
  VIEW_INVENTORY_UNITS: '/inventory/raw-items/:rawItemId/receive-and-inventory-units',
  EDIT_INVENTORY_UNITS_FOCUS_MODE: '/inventory/raw-items/:rawItemId/receive-and-inventory-units-focus-mode',
  VIEW_INVENTORY_UNITS_FOCUS_MODE: '/inventory/raw-items/:rawItemId/receive-and-inventory-units-focus-mode-view',
  REPORTING_RECIPE_UNITS: '/inventory/raw-items/:rawItemId/reporting-and-recipes',
  REPORTING_RECIPE_UNITS_FOCUS_MODE: '/inventory/raw-items/:rawItemId/reporting-and-recipes-focus-mode',
  VIEW_REPORTING_RECIPE_UNITS_FOCUS_MODE: '/inventory/raw-items/:rawItemId/reporting-and-recipes-focus-mode-view',
  UNITS_OF_MEASURE: '/inventory/units-of-measure',
  HOUSE_ACCOUNTS: '/sales/house-accounts',
  HOUSE_ACCOUNT_CREATE: '/sales/house-accounts/create',
  HOUSE_ACCOUNT_FOCUSMODE_BASE: '/sales/house-accounts/account',
  HOUSE_ACCOUNT_DETAILS: '/sales/house-accounts/account/:houseAccountId/details',
  HOUSE_ACCOUNT_ACTIVITIES: '/sales/house-accounts/account/:houseAccountId/',
  HOUSE_ACCOUNT_ALL_ROUTES: '/sales/house-accounts/account/:houseAccountId/*',
  HOUSE_ACCOUNT_STATEMENT: '/sales/house-accounts/:houseAccountId/statement/:statementId',
  HOUSE_ACCOUNT_TRANSACTION: '/sales/house-accounts/:houseAccountId/transaction/:transactionId',
  SALES_DISCOUNT_REPORT: '/sales/reports/discounts',
  SALES_REFUNDS_REPORT: '/sales/reports/refunds',
  SALES_TAX_REPORT: '/sales/reports/taxes',
  SALES_VOID_REPORT: '/sales/reports/voids',
  SALES_PAYMENT_REPORT: '/sales/reports/payments',
  SALES_SUMMARY_REPORT: '/sales/reports/sales-summary',
  SALES_PRODUCT_MIX_REPORT: '/sales/reports/product-mix',
  SALES_PROFIT_LOSS_REPORT: '/sales/reports/profit-loss',
  SALES_REVENUE_CENTER_REPORT: '/sales/reports/revenue-centers',
  SALES_JOURNAL_ENTRY: '/sales/reports/journal-entries',
  SALES_POS_EVENT_LOG: '/sales/pos-event-log',
  SALES_TRANSACTION_DETAILS_REPORT: '/sales/transactions',
  FINANCIAL_SHIFT_MANAGER: '/sales/cash-office/financial-shift',
  FINANCIAL_SHIFT_DETAILS: '/sales/cash-office/financial-shift/:shiftId/shift-details',
  FINANCIAL_SHIFT_DECLARATION: '/sales/cash-office/financial-shift/:shiftId/shift-details/shift-declaration',
  SALES_SETTINGS: '/settings/sales/:salesPage',
  SALES_DEFINITIONS_SETTINGS: '/settings/sales/sales-definitions',
  MANAGE_GL_ACCOUNTS: '/settings/gl/manage-gl-accounts',
  GL_ACCOUNT_MAPPING: '/settings/gl/gl-account-mapping',
} as const;

export const baseRoutes = [
  routes.JOBCODE_CONFIGURATION_FOCUSMODE_BASE,
  routes.HOUSE_ACCOUNT_FOCUSMODE_BASE,
  routes.SITE_TAGS,
  routes.SITE_GROUPS,
  routes.NOTIFICATION_SETTINGS,
  routes.EMPLOYEE_OVERVIEW_BASE,
  routes.INVOICES,
  routes.RAW_ITEMS,
  routes.VENDORS,
];

export type ValidRoute = (typeof routes)[keyof typeof routes];

export default routes;
