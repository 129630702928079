import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Ty from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUsers } from '../../../../contexts/userContext';
import { useSnackbar } from '../../../../contexts/SnackbarContext';
import { useNotificationsDataQuery } from '../../../../admin/requests/queries';
import NotificationCard from '../NotificationCard/NotificationCard';
import { NotificationDetails } from '../../../../admin/requests/index';
import { isValidNotificationRes } from '../../../../utils/notificationsUtils/notificationsHelper';

export interface NotificationRightPanelProps {
  isMobile: boolean;
  handleRightPanelClose: () => void;
  handleNotificationsOpen: () => void;
  notificationsToRender: number;
  handleNotificationsTotalUnread: (totalUnread: number) => void;
}

function NotificationRightPanel({
  isMobile,
  handleRightPanelClose,
  handleNotificationsOpen,
  notificationsToRender,
  handleNotificationsTotalUnread,
}: Readonly<NotificationRightPanelProps>) {
  const { t } = useTranslation();
  const { setSnackbarState } = useSnackbar();
  const user = useUsers();
  const [notificationRightPanelData, setNotificationRightPanelData] = useState<NotificationDetails | null>(null);
  const listItemHeight = isMobile ? 131 : 128;

  const {
    data: notifications,
    isError: isGetNotificationError,
    refetch: notificationsRefetch,
    isLoading,
  } = useNotificationsDataQuery(user.fullyAuthenticated === 'authenticated' && notificationsToRender !== 0, 0, 10, [
    'notificationRightPanelData',
  ]);

  const handleRefetchNotifications = () => {
    notificationsRefetch();
  };

  useEffect(() => {
    if (notifications && isValidNotificationRes(notifications)) {
      notifications.data = notifications.data.map((item) => ({ ...item, createdAt: new Date(item.createdAt) }));
      setNotificationRightPanelData({
        data: notifications.data,
        totalPages: Math.ceil(notifications.totalResults / notificationsToRender),
        totalResults: notifications.totalResults,
        totalUnread: notifications.totalUnread,
      });
      handleNotificationsTotalUnread(notifications.totalUnread);
    }
  }, [handleNotificationsTotalUnread, notifications, notificationsToRender]);

  useEffect(() => {
    if (isGetNotificationError) {
      setSnackbarState({
        open: true,
        color: 'error',
        message: t('admin.allNotificationsDialog.errorFetchingNotification'),
      });
    }
  }, [isGetNotificationError, setSnackbarState, t]);

  return (
    <Box
      data-testid='notifications-right-panel'
      width='100%'
      height='100%'
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 2.625,
          paddingX: 2,
        }}
      >
        <Box>
          <Ty
            data-testid='notifications-header'
            variant='h6'
            sx={{
              position: 'relative',
              top: '2px',
            }}
          >
            {t('rightPanel.notifications.header')}
          </Ty>
        </Box>
        <IconButton onClick={handleRightPanelClose}>
          <CloseIcon data-testid='close-sidebar-btn' />
        </IconButton>
      </Box>
      {/* New indicator */}
      <Box
        sx={{
          marginTop: 1,
          marginBottom: 0.375,
          paddingX: 2,
        }}
      >
        <Ty data-testid='new-notifications-indicator' variant='caption' color='text.secondary'>
          {t('rightPanel.notifications.newIndicator', {
            indicatorNumber: notifications?.totalUnread ? notifications.totalUnread : 0,
          })}
        </Ty>
      </Box>
      <Divider />
      {/* Notification list */}
      <Box
        sx={{
          paddingTop: 2,
        }}
      >
        {isLoading ? (
          <Grid container alignItems='center' justifyContent='center' data-testid='spinner-container'>
            <CircularProgress />
          </Grid>
        ) : (
          <List data-testid='notifications-list' disablePadding>
            {notificationRightPanelData?.data.slice(0, notificationsToRender).map((notification, index) => (
              <Box key={`notification-card-${notification.id}`}>
                <ListItem data-testid={`notification-card-${index}`} disablePadding>
                  <NotificationCard
                    notification={notification}
                    minHeightInPx={`${listItemHeight}px`}
                    handleRefetchNotifications={handleRefetchNotifications}
                  />
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
        )}
      </Box>
      {/* See All Notifications Button */}
      <Box
        sx={{
          paddingTop: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box>
          <Button
            data-testid='see-all-notifications-btn'
            size='large'
            onClick={handleNotificationsOpen}
            disabled={
              notificationRightPanelData === null ||
              (notificationRightPanelData?.data && notificationRightPanelData.data.length < 1)
            }
          >
            {t('rightPanel.notifications.seeAllNotifications')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default NotificationRightPanel;
