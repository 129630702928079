import { useMutation, UseMutationResult } from '@tanstack/react-query';
import useSchedulingRequests from './index';
import {
  ActualShiftResponseDto,
  DeletePunchEditRequest,
  PlannedShiftResponseDto,
  PlannedShiftReq,
  PublishScheduleRequest,
  PunchEditRequest,
  ScheduleCreateRequest,
  ScheduleResponse,
  ScheduleUpdateRequest,
  WeeklySchedulePdfMutationResult,
  WeeklySchedulePdfMutationVariables,
  CopyScheduleRequest,
  WorkWeekConfigResponse,
  WorkWeekConfigBodySchema,
  WeeklySchedulePdfByDatesMutationVariables,
} from '../types';

export const useCreateShiftsMutation = (): UseMutationResult<
  PlannedShiftResponseDto[] | void,
  unknown,
  PlannedShiftReq[]
> => {
  const { createShifts } = useSchedulingRequests();
  return useMutation((shiftInfo: PlannedShiftReq[]) => createShifts(shiftInfo));
};

export const useUpdateShiftMutation = (): UseMutationResult<
  PlannedShiftResponseDto | void,
  unknown,
  { shiftId: string; updatedShiftInfo: PlannedShiftReq }
> => {
  const { updateShift } = useSchedulingRequests();
  return useMutation((updateShiftVariables: { shiftId: string; updatedShiftInfo: PlannedShiftReq }) =>
    updateShift(updateShiftVariables.shiftId, updateShiftVariables.updatedShiftInfo)
  );
};

export const useDeleteShiftMutation = (): UseMutationResult<void, unknown, { shiftId: string }> => {
  const { deleteShift } = useSchedulingRequests();
  return useMutation((variables: { shiftId: string }) => deleteShift(variables.shiftId), {
    useErrorBoundary: true,
  });
};

export const usePublishScheduleMutation = (): UseMutationResult<
  ScheduleResponse | void,
  unknown,
  { scheduleId: string; payload: PublishScheduleRequest }
> => {
  const { publishSchedule } = useSchedulingRequests();
  return useMutation(
    (variables: { scheduleId: string; payload: PublishScheduleRequest }) =>
      publishSchedule(variables.scheduleId, variables.payload),
    {
      useErrorBoundary: true,
    }
  );
};

export const useUpdateScheduleMutation = (): UseMutationResult<
  ScheduleResponse | void,
  unknown,
  { scheduleId: string; payload: ScheduleUpdateRequest }
> => {
  const { updateSchedule } = useSchedulingRequests();
  return useMutation(
    (updateScheduleVariables: { scheduleId: string; payload: ScheduleUpdateRequest }) =>
      updateSchedule(updateScheduleVariables.scheduleId, updateScheduleVariables.payload),
    { useErrorBoundary: true }
  );
};

export const useCreateScheduleMutation = (): UseMutationResult<
  ScheduleResponse | void,
  unknown,
  { payload: ScheduleCreateRequest }
> => {
  const { createSchedule } = useSchedulingRequests();
  return useMutation((variables: { payload: ScheduleCreateRequest }) => createSchedule(variables.payload), {
    useErrorBoundary: true,
  });
};

export const useCopyScheduleMutation = (): UseMutationResult<
  void,
  unknown,
  { payload: CopyScheduleRequest },
  unknown
> => {
  const { copySchedule } = useSchedulingRequests();
  return useMutation((variables: { payload: CopyScheduleRequest }) => copySchedule(variables.payload), {
    useErrorBoundary: false,
  });
};
export const useCreatePunchEditMutation = (): UseMutationResult<
  ActualShiftResponseDto | void,
  unknown,
  PunchEditRequest,
  unknown
> => {
  const { createPunchEdit } = useSchedulingRequests();
  return useMutation((punchInfo: PunchEditRequest) => createPunchEdit(punchInfo));
};

export const useUpdatePunchEditMutation = (): UseMutationResult<
  ActualShiftResponseDto | void,
  unknown,
  { shiftId: string; updatedPunchEditInfo: PunchEditRequest },
  unknown
> => {
  const { updatePunchEdit } = useSchedulingRequests();
  return useMutation((updatePunchVariables: { shiftId: string; updatedPunchEditInfo: PunchEditRequest }) =>
    updatePunchEdit(updatePunchVariables.shiftId, updatePunchVariables.updatedPunchEditInfo)
  );
};

export const useUpdateWorkWeekConfigMutation = (): UseMutationResult<
  WorkWeekConfigResponse | void,
  unknown,
  { payload: WorkWeekConfigBodySchema }
> => {
  const { updateWorkWeekConfig } = useSchedulingRequests();
  return useMutation((body: { payload: WorkWeekConfigBodySchema }) => updateWorkWeekConfig(body.payload));
};

export const useDeletePunchEditMutation = (): UseMutationResult<
  void,
  unknown,
  { shiftId: string; payload: DeletePunchEditRequest },
  unknown
> => {
  const { deletePunchEdit } = useSchedulingRequests();
  return useMutation(
    (variables: { shiftId: string; payload: DeletePunchEditRequest }) =>
      deletePunchEdit(variables.shiftId, variables.payload),
    {
      useErrorBoundary: true,
    }
  );
};

export const useGenerateSchedulePdfMutation = () => {
  const { generateSchedulePdf } = useSchedulingRequests();

  async function mutationFunc({ scheduleId, body }: WeeklySchedulePdfMutationVariables) {
    const result: WeeklySchedulePdfMutationResult = {
      pdfContent: await generateSchedulePdf(scheduleId, body),
    };
    return result;
  }

  return useMutation<WeeklySchedulePdfMutationResult, unknown, WeeklySchedulePdfMutationVariables, unknown>(
    mutationFunc,
    {}
  );
};

export const useGenerateSchedulePdfByDatesMutation = () => {
  const { generateSchedulePdfByDate } = useSchedulingRequests();

  async function mutationFunc({ startDate, endDate, body }: WeeklySchedulePdfByDatesMutationVariables) {
    const result: WeeklySchedulePdfMutationResult = {
      pdfContent: await generateSchedulePdfByDate(startDate, endDate, body),
    };
    return result;
  }

  return useMutation<WeeklySchedulePdfMutationResult, unknown, WeeklySchedulePdfByDatesMutationVariables, unknown>(
    mutationFunc,
    {}
  );
};
