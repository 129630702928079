import { Req } from '@cbo/shared-library';
import { useOktaAuth } from '@okta/okta-react';
import { useCallback } from 'react';
import { HttpsError, useFirebaseApi } from '../../contexts/firebaseApiContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasSiteIds(payload: any): payload is { siteIds: string[] } {
  return payload && typeof payload === 'object' && 'siteIds' in payload && Array.isArray(payload.siteIds);
}

export default () => {
  const { oktaAuth } = useOktaAuth();
  const { bslProxy } = useFirebaseApi();
  return useCallback(
    async <T>(request: Req.Firebase.BslProxyRequest): Promise<T> => {
      const { accessToken } = await oktaAuth.tokenManager.getTokens();

      if (!bslProxy) {
        throw new Error('bslProxy firebase function is not assigned');
      }
      if (!accessToken) {
        throw new Error('No okta token found');
      }
      if (hasSiteIds(request.payload)) {
        request.validateSiteIds = true;
      }

      // Renew Okta token if it expires in less than 15 minutes
      // and a request is made to keep the user session alive
      if (accessToken.expiresAt && accessToken.expiresAt - Date.now() / 1000 < 60 * 15) {
        await oktaAuth.tokenManager.renew('accessToken');
      }

      const requestNcrid: Req.Firebase.BslProxyRequestNcrId = { ...request, oktaJwt: accessToken.accessToken };
      try {
        const result = await bslProxy(requestNcrid);
        return result.data as T;
      } catch (error) {
        if ((error as HttpsError).code === 'functions/unauthenticated') {
          await oktaAuth.signOut();
        }
        throw error;
      }
    },
    [bslProxy, oktaAuth]
  );
};
