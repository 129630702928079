import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { ReactNode } from 'react';

function SwipeableDrawerHeader({ children }: { children: ReactNode }) {
  return (
    <Box height='auto' paddingTop='6px' width='100%'>
      <Box
        sx={(theme: Theme) => ({
          margin: '0px auto',
          width: '48px',
          height: '5px',
          borderRadius: '33px',
        })}
      />
      {children}
    </Box>
  );
}

export default SwipeableDrawerHeader;
